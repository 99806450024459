import React from "react";
import LoadIMG from '../../imgs/load_gif.gif'

export const LoadDiv = () => {
    return (
        <div className="popup">
            <div className="popup-inner-load" style={{
                textAlign: 'center'
            }}>
                <img src={LoadIMG}/>
                <p style={{
                    color: 'white'
                }}>Carregando...</p>
            </div>
        </div>
    )
}