import React, { useEffect, useState } from "react"
import { Connection } from "../../../conn/connection";
import { Save } from "../../../component/save/save";
import { TelaADM } from "../../../component/TelaADM";
import { EntryInput } from "../../../component/input/input";
import { LoadDiv } from "../../../component/Load";
import { useSearchParams } from "react-router-dom";
import InputMask from 'react-input-mask';

export const InserirPagamento = () => {
    const [ParametrosURL, setParametrosURL] = useSearchParams()
    const [InserirAlterar, setInserirAlterar] = useState('Inserir')
    const [Pago, setPago] = useState(false)
    const [TituloPagina, setTituloPagina] = useState('PAGAMENTOS > INSERIR PAGAMENTO')
    const [Load, setLoad] = useState(false)

    const [ListFornecedores, setListFornecedores] = useState([])
    const [ListPlanoDeContas, setListPlanoDeContas] = useState([])
    const [ListContas, setListContas] = useState([])

    const [Titulo, setTitulo] = useState('')
    const [Descricao, setDescricao] = useState('')
    const [Fornecedor, setFornecedor] = useState('')
    const [CodigoBarras, setCodigoBarras] = useState('')
    const [Valor, setValor] = useState()
    const [DtEmissao, setDtEmissao] = useState()
    const [DtVencimento, setDtVencimento] = useState()
    const [Juros, setJuros] = useState(0)
    const [Desconto, setDesconto] = useState(0)
    const [VlPago, setVlPago] = useState()
    const [DtPagamento, setDtPagamento] = useState('')
    const [ContaPagamento, setContaPagamento] = useState('')
    const [PlanoConta, setPlanoConta] = useState('')
    const [Id, setId] = useState()

    const [Mov, setMov] = useState()
    

    const save = new Save()
    const Conn = new Connection()

    const Pagar = async() => {
        const token = save.getTokenAdmin()

        if (Juros == '' & Juros < 0){
            
            alert('Verique o campo Juros')
        }
        else if (Desconto == '' & Desconto < 0){
            
            alert('Verique o campo Desconto')
        }
        else if (DtPagamento == ''){
            alert('Verique o campo Data Pagamento')
        }
        else if (ContaPagamento == ''){
            alert('Verique o campo Conta de Pagamento')
        }
        else if (PlanoConta == ''){
            alert('Verique o campo Plano de Contas')
        }
        else {
            setLoad(true)
            let resp = await Conn.Ecommerc('intranet/financeiro/pagamentos/pagar', {
                'token': token,
                'pagamento': String(Id),
                'data_pagamento': DtPagamento,
                'juros': String(Juros),
                'desconto': String(Desconto),
                'conta': ContaPagamento,
                'plano': PlanoConta,
            })
            if (resp){
                
                if (resp.status == '0'){
                    alert('Sucesso!')
                    window.location.href = '/intranet/admin/pagamentos'
                }
            }
            setLoad(false)
        }
    }

    const estornar = async() => {
        const token = save.getTokenAdmin()
        setLoad(true)
        let resp = await Conn.Ecommerc('intranet/financeiro/pagamentos/cancelar_pagamento', {
            'token': token,
            'movimentacao': String(Mov),
        })
        if (resp){
          
            if (resp.status == '0'){
                alert('Sucesso!')
                window.location.reload()
            }
        }
        setLoad(false)
    }

    const excluir = async() => {
        const token = save.getTokenAdmin()
        setLoad(true)
        let resp = await Conn.Ecommerc('intranet/financeiro/pagamentos/excluir', {
            'token': token,
            'pagamento': String(Id),
            'exc_mov': true
        })
        if (resp){
          
            if (resp.status == '0'){
                alert('Sucesso!')
                window.location.href = '/intranet/admin/pagamentos'
            }
        }
        setLoad(false)
    }

    const Inserir = async() => {
        const token = save.getTokenAdmin()
        if (Titulo == ''){
            alert('Verifique o campo Titulo')
        }
        else if (Descricao == ''){
            alert('Verifique o campo Descricao')
        }
        else if (Fornecedor == ''){
            alert('Verifique o campo Fornecedor')
        }
        else if (CodigoBarras == ''){
            alert('Verifique o campo CodigoBarras')
        }
        else if (Valor == ''){
            alert('Verifique o campo Valor')
        }
        else if (DtEmissao == ''){
            alert('Verifique o campo DtEmissao')
        }
        else if (DtVencimento == ''){
            alert('Verifique o campo DtVencimento')
            
        }
        
        else {
            setLoad(true)
            let resp = await Conn.Ecommerc('intranet/financeiro/pagamentos/inserir', {
                'token': token,
                'titulo':Titulo,
                'descricao':Descricao,
                'fornecedor':Fornecedor,
                'codigo_barras':CodigoBarras,
                'valor_original': String(Valor),
                'data_emissao': DtEmissao,
                'data_vencimento': DtVencimento,
            })
            if (resp){
                
                if (resp.status == '0'){
                    alert('Sucesso!')
                    window.location.href = '/intranet/admin/pagamentos'
                }
            }
            setLoad(false)
        }
        
    }

    const LoadInicial = async() => {
        let pgt = ParametrosURL.get('cod')

        const token = save.getTokenAdmin()


        let resp = await Conn.Ecommerc('intranet/financeiro/fornecedor/pesquisar', {
            'token': token,
        })
        if (resp){
            if (resp.status == '0'){
                setListFornecedores(resp.dados.lista_fornecedores)
            }
        }

        resp = await Conn.Ecommerc('intranet/financeiro/plano_contas/pesquisar', {
            'token': token,
            'pag': true
        })
        if (resp){
            if (resp.status == '0'){
                setListPlanoDeContas(resp.dados.lista_planos)
            }
        }

        resp = await Conn.Ecommerc('intranet/financeiro/conta/pesquisar', {
            'token': token,
        })
        if (resp){
            if (resp.status == '0'){
                setListContas(resp.dados.lista_bancos)
            }
        }

        if (pgt){
            resp = await Conn.Ecommerc('intranet/financeiro/pagamentos/pesquisar', {
                'token': token,
                'id': String(pgt)
            })
            if (resp){
                
                if (resp.status == '0'){
                    setInserirAlterar('Alterar')
                    
                    let dados = resp.dados.list[0]
                    setTituloPagina(`PAGAMENTOS > PAGAR #${dados.id}`)
                    setId(dados.id)
                    setTitulo(dados.titulo)
                    setDescricao(dados.descricao)
                    setFornecedor(dados.fornecedor)
                    setCodigoBarras(dados.codigo_barras)
                    setValor(dados.valor_original)
                    setDtEmissao(dados.data_emissao)
                    setDtVencimento(dados.data_vencimento)
                    
                    setVlPago(dados.valor_original - dados.juros + dados.desconto)
                    setDtPagamento(dados.data_pagamento)
                    
                    setPago(dados.pago)
                    setMov(dados.mov)
                    if (dados.pago){
                        setJuros(dados.juros)
                        setDesconto(dados.desconto)
                        setContaPagamento(dados.conta_pagamento)
                        setPlanoConta(dados.plano)
                    }
                }
            }
        }

        
        
    }

    useEffect(() => {LoadInicial()}, [])

    return (
        <>
        <TelaADM title={TituloPagina}>
            <div>
                <div className="entry-div-default" style={{
                    width: '30%',
                    
                  
                }}>
                    <label>Título:</label>
                    <input value={Titulo} onChange={(event) => setTitulo(event.target.value)} placeholder="Digite o título do pagamento" disabled={InserirAlterar=='Alterar'}/>
                </div>
                <div className="entry-div-default" style={{
                    width: '68%',
                    
                  
                }}>
                    <label>Descrição:</label>
                    <input value={Descricao} onChange={(event) => setDescricao(event.target.value)} placeholder="Digite uma descrição para o pagamento" disabled={InserirAlterar=='Alterar'}/>
                </div>
                <br/>
                <div className="entry-div-default" style={{
                    width: '40%',
                    
                  
                }}>
                    <label>Fornecedor:</label>
                    <select value={Fornecedor} onChange={(event) => setFornecedor(event.target.value)} disabled={InserirAlterar=='Alterar'}>
                        <option value=''>Selecione</option>
                        {
                            ListFornecedores.map((item) => (
                                <option value={item.id}>{item.razao_social}</option>
                            ))
                        }
                    </select>
                </div>
                <div className="entry-div-default" style={{
                    width: '58%',
                    
                  
                }}>
                    <label>Código de Barras:</label>
                    <input value={CodigoBarras} onChange={(event) => setCodigoBarras(event.target.value)} placeholder="Digite o código de barras do boleto" disabled={InserirAlterar=='Alterar'}/>
                </div>
                <br/>
                <div className="entry-div-default" style={{
                    width: '15%',
                    
                  
                }}>
                    <label>Valor:</label>
                    <input value={Valor} onChange={(event) => setValor(event.target.value)} placeholder="Valor a pagar" type="number" disabled={InserirAlterar=='Alterar'}/>
                </div>
                <div className="entry-div-default" style={{
                    width: '15%',
                    
                  
                }}>
                    <label>Data Emissão:</label>
                    <InputMask value={DtEmissao} onChange={(event) => setDtEmissao(event.target.value)} mask='99/99/9999' placeholder="Data de emissão" disabled={InserirAlterar=='Alterar'}/>
                </div>
                <div className="entry-div-default" style={{
                    width: '15%',
                    
                  
                }}>
                    <label>Data Vencimento:</label>
                    <InputMask value={DtVencimento} onChange={(event) => setDtVencimento(event.target.value)} placeholder="Data de vencimento" mask='99/99/9999' disabled={InserirAlterar=='Alterar'}/>
                </div>
                <br/>


                <div style={{
                    padding: '10px',
                    boxSizing: 'border-box',
                    border: 'rgb(216, 216, 216) 1px solid',
                    borderRadius: '7px',
                    width: '100%',
                    display: 'inline-block',
                    marginTop: '10px',
                    textAlign: 'center'
                }}>
                    <p style={{textAlign: 'center'}}>PAGAMENTO</p>
                    {
                        InserirAlterar == 'Alterar'?
                        <>
                            <div className="entry-div-default" style={{
                            width: '15%',
                            }}>
                                <label>Valor Original:</label>
                                <input placeholder="Valor" value={Valor} onChange={(event) => setTitulo(event.target.value)} disabled/>
                            </div>
                            <div className="entry-div-default" style={{
                            width: '15%',
                            
                        
                            }}>
                                <label>Juros:</label>
                                <input type="number" value={Juros} onBlur={(event) => {if(!event.target.value){setJuros(0)}}}placeholder="Juros" onChange={(event) => setJuros(event.target.value)} disabled={Pago}/>
                            </div>
                            <div className="entry-div-default" style={{
                            width: '15%',
                            
                        
                            }}>
                                <label>Descontos:</label>
                                <input type="number" onBlur={(event) => {if(!event.target.value){setDesconto(0)}}} value={Desconto} onChange={(event) => setDesconto(event.target.value)} placeholder="Desconto" disabled={Pago}/>
                            </div>
                            <div  className="entry-div-default" style={{
                            width: '15%',
                            
                        
                            }}>
                                <label>Valor Pago:</label>
                                <input placeholder="Valor Pago" value={parseFloat(Valor)-parseFloat(Desconto)+ parseFloat(Juros)} disabled/>
                            </div>
                            <div className="entry-div-default" style={{
                            width: '15%',
                            
                        
                            }}>
                                <label>Data Pagamento:</label>
                                <InputMask placeholder="Data Pagamento" value={DtPagamento} onChange={(event) => setDtPagamento(event.target.value)} mask='99/99/9999'  disabled={Pago}/>
                            </div>
                            <br/>
                            <div className="entry-div-default" style={{
                            width: '40%',
                            
                        
                            }}>
                                <label>Conta de Pagamento:</label>
                                <select value={ContaPagamento} onChange={(event) => setContaPagamento(event.target.value)}  disabled={Pago}>
                                <option value=''>Selecione</option>
                                    {
                                        ListContas.map((item) => (
                                            <option value={item.id}>{item.banco} {`(${item.conta})`}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="entry-div-default" style={{
                            width: '40%',
                            
                        
                            }}>
                                <label>Plano de Contas:</label>
                                <select value={PlanoConta} onChange={(event) => setPlanoConta(event.target.value)}  disabled={Pago}>
                                <option value=''>Selecione</option>
                                    {
                                        ListPlanoDeContas.map((item) => (
                                            <option value={item.id}>{item.titulo}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </>
                        :
                        ''
                    }
                    
                </div>
            </div>
            <div style={{
                    display: 'inline-block',
                    textAlign: 'center',
                    width: '100%',
                    marginTop: '20px',
                    fontSize: '12pt'
                }}>
            {
                InserirAlterar == 'Alterar'?
                <>
              
                    {
                        Pago?
                        <button style={{
                            width: '300px',
                            marginLeft: '10px'
                        }} className="button-adm-default" onClick={estornar}>
                            estornar
                        </button>
                        :
                        <button style={{
                            width: '300px'
                        }} className="button-adm-default" onClick={Pagar}>
                            pagar
                        </button>
                    }
                <button style={{
                    width: '300px',
                    marginLeft: '10px'
                }} className="button-adm-default" onClick={excluir}>
                    excluir
                </button>
                </>
                :
                <button style={{
                    width: '300px'
                }} className="button-adm-default" onClick={Inserir}>
                    salvar
                </button>

            }
            </div>
        </TelaADM>
        {
            Load ?
            <LoadDiv/>
            :
            ''
        }
        </>
    )
}