import React, { useEffect, useState } from "react"
import { Connection } from "../../../conn/connection";
import { Save } from "../../../component/save/save";
import { TelaADM } from "../../../component/TelaADM";
import { EntryInput } from "../../../component/input/input";
import { LoadDiv } from "../../../component/Load";
import { useSearchParams } from "react-router-dom";
import InputMask from 'react-input-mask';

export const MovimentacoesContas = (props) => {
    const [ParametrosURL, setParametrosURL] = useSearchParams()
    const [ContaTitulo, setContaTitulo] = useState('MOVIMENTAÇÕES DE CONTAS')
    const [ListContas, setListContas] = useState([])
    const [MovsContas, setMovsContas] = useState([])
    const [Load, setLoad] = useState(false)
    const [SaldoAtual, setSaldoAtual] = useState(0)
    const [SaldoPeriodo, setSaldoPeriodo] = useState(0)
    const [SaldoAtePeriodo, setSaldoAtePeriodo] = useState(0)
    const [Futuro, setFuturo] = useState(0)

    const [Conta, setConta] = useState('TD')
    const [DtInicial, setDtInicial] = useState()
    const [DtFinal, setDtFinal] = useState()
    const [Tipo, setTipo] = useState('TD')

    const [Transferir, setTransferir] = useState(false)
    const [ContaOrigem, setContaOrigem] = useState('')
    const [ContaDestino, setContaDestino] = useState('')
    const [DataTransferencia, setDataTransferencia] = useState('')
    const [ValorTransferencia, setValorTransferencia] = useState(0)
    const [Motivo, setMotivo] = useState('')

    //alert(ParametrosURL.get('conta'))
    const save = new Save()
    const Conn = new Connection()

    const transferir_fundos = async() => {
        if (ContaOrigem == ''){
            alert('Verifique a conta origem')
        } else if (ContaDestino == ''){
            alert('Verifique a conta de destino')
        } else if (DataTransferencia == ''){
            alert('Verifique a data')
        } else if (ValorTransferencia == '' | ValorTransferencia < 0){
            alert('Verfique o valor')
        } else if (Motivo == ''){
            alert('Verifique o motivo')
        } else {
            setTransferir(false)
            setLoad(true)
            const token = save.getTokenAdmin()
            let resp = await Conn.Ecommerc('intranet/financeiro/contas/transferencia', {
                'token': token,
                'origem': ContaOrigem,
                'destino': ContaDestino,
                'valor': ValorTransferencia,
                'motivo': Motivo,
                'data': DataTransferencia,
            })
            if (resp){
                if (resp.status == '0'){
                    alert('Sucesso!')
                    window.location.reload()
                }
            }
            setLoad(false)
        }
    }


    const PesquisarContas = async(conta) => {
        setLoad(true)
        let conta_pesquisa
        if (conta){
            conta_pesquisa = conta
        } else {
            conta_pesquisa = Conta
        }
        setConta(conta_pesquisa)
        const token = save.getTokenAdmin()
        let resp = await Conn.Ecommerc('intranet/financeiro/mov/pesquisar_movimentacao', {
            'token': token,
            'tipo': Tipo,
            'dt_incial': DtInicial,
            'dt_final': DtFinal,
            'conta': conta_pesquisa
        })
        if (resp){
            if (resp.status == '0'){
                setDtInicial(resp.dados.dt_inicial)
                setDtFinal(resp.dados.dt_final)
                setMovsContas(resp.dados.mov)
                setSaldoAtual(resp.dados.saldo_total)
                setSaldoAtePeriodo(resp.dados.saldo_ate_periodo)
                setSaldoPeriodo(resp.dados.saldo_periodo)
                setFuturo(resp.dados.futuro)
            }
        }
        setLoad(false)
    }
    
    const LoadInicial = async() => {
        
        let conta = ParametrosURL.get('conta')
        
        const token = save.getTokenAdmin()
        let resp
        
        if (token){
        
            resp = await Conn.Ecommerc('intranet/financeiro/conta/pesquisar', {
                'token': token
            })
            if (resp){
                if (resp.status == '0'){
                    setListContas(resp.dados.lista_bancos)
                }
            }

            if (conta){
                PesquisarContas(conta)
            }
        }

        
    }

    let tipos = {
        "PG": "Pagamentos",
        "RC": "Recebimentos",
        "TR": "Transferência",
        "OT": "Outros",
        "CT": "Cartão",
        "VD": "Venda",
        "VP": "Venda PIX",
        "VC": "Venda Cartão"
        }

    useEffect(() => {LoadInicial()}, [])
    return (
        <>
        <TelaADM title={ContaTitulo}>
            <div style={{
                border: '1px solid rgb(216, 216, 216)',
                borderRadius: '7px',
                boxSizing: 'border-box',
                padding: '5px',
                textAlign: "center"
            }}>
                <div className="entry-div-default" style={{
                    width: '30%'
                }}>
                    <label>Conta:</label>
                    <select value={Conta} onChange={(event) => setConta(event.target.value)}>
                        <option value='TD'>Todos</option>
                        {ListContas.map((item) => (
                            <option value={item.id}>{item.banco} {`(${item.conta})`}</option>
                        ))}
                    </select>
                </div>
                <div className="entry-div-default" style={{
                    width: '15%'
                }}>
                    <label>Tipo:</label>
                    <select value={Tipo} onChange={(event) => setTipo(event.target.value)}>
                        <option value='TD'>Todos</option>
                        <option value='PG'>Pagamentos</option>
                        <option value='RC'>Recebimentos</option>
                        <option value='TR'>Transferências</option>
                        <option value='CT'>Cartão</option>
                        <option value='VD'>Venda em Dinheiro</option>
                        <option value='VP'>Venda PIX</option>
                        <option value='VC'>Venda Cartão</option>
                        <option value='OT'>Outros</option>
                    </select>
                </div>
                <div className="entry-div-default" style={{
                    width: '20%',
                    maxWidth: '150px',
                  
                }}>
                    <label>Data Inicial:</label>
                    <InputMask value={DtInicial} onChange={(event) => setDtInicial(event.target.value)} mask={'99/99/9999'} maskChar=" " />
                </div>
                <div className="entry-div-default" style={{
                    width: '20%',
                    maxWidth: '150px',
                  
                }}>
                    <label>Data Final:</label>
                    <InputMask value={DtFinal} onChange={(event) => setDtFinal(event.target.value)} mask={'99/99/9999'} maskChar=" " />
                </div>
                <br/>
                <button style={{
                    width: '50%',
                    marginTop: '10px',
                    marginBottom: '10px'
                }} className="button-adm-default" onClick={() => PesquisarContas()}>
                    Pesquisar
                </button>
            </div>
            <div style={{
               
                borderRadius: '7px',
                boxSizing: 'border-box',
                padding: '5px',
                textAlign: "center",
                marginTop: '10px',
               
               
                overflowY: "auto"
            }}>
                <div style={{
                   width: '150px',
                    verticalAlign: 'middle',
                    display: 'inline-block',
                    fontFamily: 'Montserrat',
                    textAlign: 'left'
                }}>
                    <label style={{
                        display: 'block',
                        fontSize: '9pt',
                        marginBottom: '3px'
                        
                    }}>Saldo Atual:</label>
                    
                    <label style={{
                        fontWeight: '600'
                    }}>{SaldoAtual.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</label>
                </div>
                <div style={{
                   width: '150px',
                    verticalAlign: 'middle',
                    display: 'inline-block',
                    fontFamily: 'Montserrat',
                    textAlign: 'left'
                }}>
                    <label style={{
                        display: 'block',
                        fontSize: '9pt',
                        marginBottom: '3px'
                        
                    }}>Saldo do Período:</label>
                    
                    <label style={{
                        fontWeight: '600'
                    }}>{SaldoPeriodo.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</label>
                </div>
                <div style={{
                   width: '150px',
                    verticalAlign: 'middle',
                    display: 'inline-block',
                    fontFamily: 'Montserrat',
                    textAlign: 'left'
                }}>
                    <label style={{
                        display: 'block',
                        fontSize: '9pt',
                        marginBottom: '3px'
                        
                    }}>Saldo até o Período:</label>
                    
                    <label style={{
                        fontWeight: '600'
                    }}>{SaldoAtePeriodo.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</label>
                </div>
                <div style={{
                   width: '150px',
                    verticalAlign: 'middle',
                    display: 'inline-block',
                    fontFamily: 'Montserrat',
                    textAlign: 'left'
                }}>
                    <label style={{
                        display: 'block',
                        fontSize: '9pt',
                        marginBottom: '3px'
                        
                    }}>Lançamentos Futuros:</label>
                    
                    <label style={{
                        fontWeight: '600'
                    }}>{Futuro.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</label>
                </div>
                
            </div>
            <div style={{
                border: '1px solid rgb(216, 216, 216)',
                borderRadius: '7px',
                boxSizing: 'border-box',
                padding: '5px',
                textAlign: "center",
                marginTop: '10px',
                height: '400px',
                maxHeight: '360px',
                overflowY: "auto"
            }}>
                {
                    MovsContas.map((item, index) => {
                        
                        return (
                            <div style={{
                                border: '1px solid rgb(216, 216, 216)',
                                borderRadius: '7px',
                                boxSizing: 'border-box',
                                padding: '8px',
                                textAlign: "center",
                                background: item.tipo == 'TR' ? 'rgba(185, 185, 185, 0.603)' : item.entrada_saida == 'E' ? 'rgba(70, 247, 70, 0.603)' : 'rgba(255, 0, 0, 0.685)',
                                marginBottom: '10px'
                            }} className="item-mov-adm">
                                <div>
                                    <div style={{
                                        width: '10%',
                                        verticalAlign: 'middle',
                                        display: 'inline-block'
                                    }}>
                                        <div style={{
                                        borderRadius: '15px',
                                        boxSizing: 'border-box',
                                        padding: '8px',
                                        backgroundColor: 'blue',
                                        width: '70px', 
                                        color: 'white',
                                        fontWeight: '600',
                                        fontFamily: 'Montserrat',
                                        fontSize: '10pt'
                                        }}>
                                            <label>id: {item.id}</label>
                                        </div>
                                    </div>
                                    <div style={{
                                        width: '12%',
                                        verticalAlign: 'middle',
                                        display: 'inline-block',
                                        fontFamily: 'Montserrat',
                                        textAlign: 'left'
                                    }}>
                                        <label style={{
                                            display: 'block',
                                            fontSize: '9pt',
                                            marginBottom: '3px'
                                            
                                        }}>Data Lançamento:</label>
                                        <label>{item.data}</label>
                                    </div>
                                    <div style={{
                                        width: '15%',
                                        verticalAlign: 'middle',
                                        display: 'inline-block',
                                        fontFamily: 'Montserrat',
                                        textAlign: 'left'
                                    }}>
                                        <label style={{
                                            display: 'block',
                                            fontSize: '9pt',
                                            marginBottom: '3px'
                                            
                                        }}>Conta:</label>
                                        <label>{item.conta.banco}</label>
                                    </div>
                                    <div style={{
                                        width: '30%',
                                        verticalAlign: 'middle',
                                        display: 'inline-block',
                                        fontFamily: 'Montserrat',
                                        textAlign: 'left'
                                    }}>
                                        <label style={{
                                            display: 'block',
                                            fontSize: '9pt',
                                            marginBottom: '3px'
                                            
                                        }}>Título:</label>
                                        
                                        <label style={{
                                            fontWeight: '600'
                                        }}>{item.ref.titulo}</label>
                                    </div>
                                    <div style={{
                                        width: '15%',
                                        verticalAlign: 'middle',
                                        display: 'inline-block',
                                        fontFamily: 'Montserrat',
                                        textAlign: 'left'
                                    }}>
                                        <label style={{
                                            display: 'block',
                                            fontSize: '9pt',
                                            marginBottom: '3px'
                                            
                                        }}>Tipo:</label>
                                        
                                        <label>{tipos[item.tipo]}</label>
                                    </div>
                                    <div style={{
                                        width: '15%',
                                        verticalAlign: 'middle',
                                        display: 'inline-block',
                                        fontFamily: 'Montserrat',
                                        textAlign: 'left'
                                    }}>
                                        <label style={{
                                            display: 'block',
                                            fontSize: '9pt',
                                            marginBottom: '3px'
                                            
                                        }}>Valor da Operação:</label>
                                        
                                        <label style={{
                                            fontWeight: '600'
                                        }}>{item.vl_pago.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</label>
                                    </div>
                                </div>
                                <hr/>
                                <div style={{
                                    fontSize: '10pt',
                                    fontFamily: '"Montserrat"',
                                    cursor: 'pointer',
                                    marginBottom: '10px'
                                }} onClick={() => {
                                    let mov = MovsContas
                                    mov[index]['show'] = !mov[index]['show']
                                    setMovsContas([...mov])
                                }}><label style={{
                                    cursor: 'pointer'
                                }}>Mostrar {item.show ? 'Menos' : 'Mais'}</label></div>
                                 {
                                item.show ? 
                                <div>
                                    <div style={{
                                        width: '10%',
                                        verticalAlign: 'middle',
                                        display: 'inline-block',
                                        fontFamily: 'Montserrat',
                                        textAlign: 'left',
                                        cursor: 'pointer'
                                    }} onClick={() => {
                                        if (item.tipo == 'PG'){
                                            window.location.href = `/intranet/admin/pagamentos/into/pagto?cod=${item.id_ref}`
                                        } else if (item.tipo == 'RC'){
                                            window.location.href = `/intranet/admin/recebimentos/into/recb?cod=${item.id_ref}`
                                        }
                                    }}>
                                        <label style={{
                                            display: 'block',
                                            fontSize: '9pt',
                                            marginBottom: '3px'
                                            
                                        }}>ID REF:</label>
                                        
                                        <label style={{
                                            fontWeight: '600'
                                        }}>{item.id_ref}</label>
                                    </div>
                                    <div style={{
                                        width: '10%',
                                        verticalAlign: 'middle',
                                        display: 'inline-block',
                                        fontFamily: 'Montserrat',
                                        textAlign: 'left'
                                    }}>
                                        <label style={{
                                            display: 'block',
                                            fontSize: '9pt',
                                            marginBottom: '3px'
                                            
                                        }}>Valor Original:</label>
                                        
                                        <label style={{
                                           
                                        }}>{item.vl_original.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</label>
                                    </div>
                                    <div style={{
                                        width: '10%',
                                        verticalAlign: 'middle',
                                        display: 'inline-block',
                                        fontFamily: 'Montserrat',
                                        textAlign: 'left'
                                    }}>
                                        <label style={{
                                            display: 'block',
                                            fontSize: '9pt',
                                            marginBottom: '3px'
                                            
                                        }}>Juros:</label>
                                        
                                        <label style={{
                                           
                                        }}>{item.juros.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</label>
                                    </div>
                                    <div style={{
                                        width: '10%',
                                        verticalAlign: 'middle',
                                        display: 'inline-block',
                                        fontFamily: 'Montserrat',
                                        textAlign: 'left'
                                    }}>
                                        <label style={{
                                            display: 'block',
                                            fontSize: '9pt',
                                            marginBottom: '3px'
                                            
                                        }}>Descontos:</label>
                                        
                                        <label style={{
                                           
                                        }}>{item.desconto.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</label>
                                    </div>
                                    <div style={{
                                        width: '25%',
                                        verticalAlign: 'middle',
                                        display: 'inline-block',
                                        fontFamily: 'Montserrat',
                                        textAlign: 'left'
                                    }}>
                                        <label style={{
                                            display: 'block',
                                            fontSize: '9pt',
                                            marginBottom: '3px'
                                            
                                        }}>Descrição Movimento:</label>
                                        
                                        <label style={{
                                           
                                        }}>{item.desc}</label>
                                    </div>
                                    <div style={{
                                        width: '25%',
                                        verticalAlign: 'middle',
                                        display: 'inline-block',
                                        fontFamily: 'Montserrat',
                                        textAlign: 'left'
                                    }}>
                                        <label style={{
                                            display: 'block',
                                            fontSize: '9pt',
                                            marginBottom: '3px'
                                            
                                        }}>Descrição Operação:</label>
                                        
                                        <label style={{
                                           
                                        }}>{item.ref.desc}</label>
                                    </div>
                                </div>
                                :
                                ''
                                }
                            </div>
                           
                        )
                    })
                }
            </div>
            <div style={{
                width: '100%',
                marginBottom: '10px',
                textAlign: 'center'
            }}>

                <button style={{
                    width: '30%',
                    marginTop: '10px',
                    marginBottom: '10px'
                }} className="button-adm-default" onClick={() => setTransferir(true)}>
                    transferir
                </button>
                

            </div>
        </TelaADM>
        {
            Load?
            <LoadDiv/>
            :
            ''
        }
        {
            Transferir?
            <div className="popup">
                <div className="popup-inner-load" style={{
                    textAlign: 'center'
                }}>
                    <div style={{
                        backgroundColor: 'white',
                        borderRadius: '7px',
                        boxSizing: 'border-box',
                        padding: '8px',
                        textAlign: "center",
                        width: '700px'
                    }}>
                        <div className="entry-div-default" style={{
                            width: '30%'
                        }}>
                            <label>Conta Origem:</label>
                            <select value={ContaOrigem} onChange={(event) => setContaOrigem(event.target.value)}>
                                <option value=''>Selecione</option>
                                {ListContas.map((item) => (
                                    <option value={item.id}>{item.banco} {`(${item.conta})`}</option>
                                ))}
                            </select>
                        </div>

                        <div className="entry-div-default" style={{
                            width: '30%'
                        }}>
                            <label>Conta Destino:</label>
                            <select value={ContaDestino} onChange={(event) => setContaDestino(event.target.value)}>
                                <option value=''>Selecione</option>
                                {ListContas.map((item) => (
                                    <option value={item.id}>{item.banco} {`(${item.conta})`}</option>
                                ))}
                            </select>
                        </div>

                        <br/>
                        <div className="entry-div-default" style={{
                            width: '20%',
                            maxWidth: '150px',
                        
                        }}>
                            <label>Data:</label>
                            <InputMask value={DataTransferencia} onChange={(event) => setDataTransferencia(event.target.value)} mask={'99/99/9999'} maskChar=" " />
                        </div>
                        <div className="entry-div-default" style={{
                            width: '20%',
                            maxWidth: '150px',
                        
                        }}>
                            <label>Valor:</label>
                            <input value={ValorTransferencia} onChange={(event) => setValorTransferencia(event.target.value)} placeholder="Digite o valor" type="number"/>
                        </div>

                        <br/>
                        <div className="entry-div-default" style={{
                            width: '100%',
                            maxWidth: '150px',
                        
                        }}>
                            <label>Motivo:</label>
                            <input value={Motivo} onChange={(event) => setMotivo(event.target.value)} placeholder="Digite o motivo"/>
                        </div>
                        <br/>
                        <button style={{
                            width: '30%',
                            marginTop: '10px',
                            marginBottom: '10px'
                        }} className="button-adm-default" onClick={() => transferir_fundos()}>
                            salvar
                        </button>
                        <button style={{
                            width: '30%',
                            marginTop: '10px',
                            marginBottom: '10px',
                            marginLeft: '10px'
                        }} className="button-adm-default" onClick={() => setTransferir(false)}>
                            cancelar
                        </button>
                    </div>
                </div>
            </div>
            :
            ''
        }
        </>
    )
}