import React, { useEffect, useState } from "react";
import './style.css'
import { useParams } from "react-router-dom";
import { Connection } from "../../conn/connection";
import { Context } from "../home";

import { DivProdutos } from "../../component/DivProdutos";
import { Produto } from "../../component/produto/produto";
import  LoadGif from '../../imgs/load_gif2.gif'
import { Save } from "../../component/save/save";

export const Setor = () => {
    const props = React.useContext(Context)
    const [ NumeroDePaginas, setNumeroDePaginas ] = useState(0)
    const [ PaginaAtual, setPaginaAtual ] = useState(0)
    const [ LoadPagina, setLoadPagina ] = useState(false)
    let { setor } = useParams()
    let { tipo } = useParams()
    const [ Setores2, setSetores2] = useState(
        [
            {
                'id': 1,
                'titulo': 'Bebidas',
                'select': false,
                'categorias': [
                    {
                        'id': 1,
                        'titulo': 'Cervejas2',
                        'select': false,
                        'subcategorias': [
                            {
                                'id': 1,
                                'titulo': 'Puro Malte',
                                'select': false
                            }
                        ]
                    },
                    {
                        'id': 2,
                        'titulo': 'Cervejas3',
                        'select': false,
                        'subcategorias': [
                            {
                                'id': 2,
                                'select': false,
                                'titulo': 'Puro Malte Preta'
                            }
                        ]
                    },
                    {
                        'id': 3,
                        'titulo': 'Vinhos',
                        'select': false,
                        'subcategorias': [
                            {
                                'id': 3,
                                'select': false,
                                'titulo': 'Branco'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 2,
                'titulo': 'Grãos',
                'select': false,
                'categorias': [
                    {
                        'id': 4,
                        'titulo': 'Arroz',
                        'select': false,
                        'subcategorias': [
                            {
                                'id': 4,
                                'select': false,
                                'titulo': 'Tipo A'
                            }
                        ]
                    }
                ]
            },
        ]
    )
    const [ Setores, setSetores] = useState([])
    const [ Valor, setValor] = useState()
    const [ ListaProdutos, setListaProdutos ] = useState([])

    let [ Carregando, setCarregando ] = useState(false)
    
    const LoadProdutos = async (_tipo, _dados, page, add) => {
        
        if (_tipo != undefined){
            window.location.reload()
            
        }
        
        let _tipo_
        let _setor_
        if (_tipo != undefined){
            _tipo_ = _tipo
            _setor_ = _dados
            tipo = _tipo_
            setor = _setor_
        } else {
            _tipo_ = tipo
            _setor_ = setor
        }
        if (!Carregando){
           
            if (add==undefined){
                setCarregando(true)
            }
            
          
            const conn = new Connection()
            const save = new Save()
            let token = save.getToken() ? save.getToken() : '0'
            if (_tipo_ == 'setor' | _tipo_ == 'categoria' | _tipo_ == 'subcategoria'){
                
                let resposta = await conn.Ecommerc('produto/pesquisa/subcategoria', {
                    'token': token,
                    'tipo': _tipo_,
                    'dado': decodeURIComponent(_setor_),
                    'pagina': page
                })
                if (resposta){
                    
                    if (resposta.status == '0'){
                        if (add){
                            setListaProdutos([...ListaProdutos, ...resposta.produtos])
                            setSetores([...Setores, ...resposta.setores])
                        }else {
                            
                            setListaProdutos([...resposta.produtos], () => {console.log('Fruit', ListaProdutos);})
                            setSetores([...resposta.setores])
                        }
                       
                        setNumeroDePaginas(resposta.paginas)
                    }
                }
            } else if (_tipo_ == 'descricao'){
                let resposta = await conn.Ecommerc('produto/pesquisa/descricao', {
                    'token': token,
                    'descricao': decodeURIComponent(_setor_),
                    'pagina': page
                })
                if (resposta){
                    if (resposta.status == '0'){
                        if (add){
                            setListaProdutos([...ListaProdutos, ...resposta.produtos])
                            setSetores([...Setores, ...resposta.setores])
                        }else {
                            setListaProdutos([...resposta.produtos])
                            setSetores([...resposta.setores])
                        }
                        
                        setNumeroDePaginas(resposta.paginas)
                    }
                }
            } else if (_tipo_ == 'codigo'){
                
                let resposta = await conn.Ecommerc('produto/pesquisa/codigo', {
                    'token': token,
                    'codigo': decodeURIComponent(_setor_),
                    'id': true
                })
                if (resposta){
                    if (resposta.status == '0'){
                        
                        setListaProdutos([...resposta.produtos])
                        setSetores([...resposta.setores])
                        
                        setNumeroDePaginas(resposta.paginas)
                    }
                }
            } else if (_tipo_ == 'barr'){
                let resposta = await conn.Ecommerc('produto/pesquisa/codigo', {
                    'token': token,
                    'codigo': decodeURIComponent(_setor_),
                    'id': false
                })
                if (resposta){
                    if (resposta.status == '0'){
                        setListaProdutos([...resposta.produtos])
                        setSetores([...resposta.setores])
                        
                        setNumeroDePaginas(resposta.paginas)
                    }
                }
            }
            
            
            
        }
        setCarregando(false)
    }
    const __inicial = () => {
        props.setReload([...[LoadProdutos]])
        LoadProdutos()
    }
    const [ InicialN, setInicialN] = useState(false)
    useEffect(() => {
        if (!InicialN){
            __inicial()
            setInicialN(true)
        }
       
        
    }, [ListaProdutos])

    
    const [ LoadPage, setLoadPage ] = useState([false])

    const PageFim = async() => {
        if (!LoadPage[0]){
            setLoadPage([...[true]])
            if (PaginaAtual < NumeroDePaginas){
                await LoadProdutos(undefined, undefined, PaginaAtual+1, true)
                setPaginaAtual(PaginaAtual+1)
            }
            

            setLoadPage([...[false]])
        }
    }

    return (
        <>
       
       {
        Carregando?
        <div className="div-img-carrengo-produtos">
            <img src={LoadGif} className="img-carrengo-produtos"/>
        </div>
        
       :
        <DivProdutos setores={Setores} setSetores={setSetores} onChange={() => {}} fimPage={PageFim}>
            {
                ListaProdutos.map((item, index)=> {
                    return (
                            <Produto 
                                dados={
                                    {
                                        'setor': item.setor.id, 
                                        'categoria': item.categoria.id, 
                                        'subcategoria': item.subcategoria.id,
                                        'nada': '1'
                                    }
                                } 
                                descricao={item.descricao}
                                codigo={item.id}
                                width='150px' 
                                preco_un={item.preco} 
                                qtd={item.pedido} 
                                set={(valor) => {
                                    let produtos = ListaProdutos
                                    let ant = item
                                    ant['pedido'] = valor
                                    produtos[index] = ant
                                    setListaProdutos([...produtos])
                                }} 
                                addCarrinho={(dados) => {props.addCarrinho(dados)}}
                                Carrinho={props.Carrinho}
                            />                
                    )
                })
            }
            {/*
        <Produto dados={{setor: '1', categoria: '1', subcategoria: '1'}} descricao="Arroz Brilhante 1KG" img={null} width='150px' preco_un={1} qtd={Valor} set={setValor} addCarrinho={(dados) => {}}/>
           
            <Produto dados={{setor: '1', categoria: '2', subcategoria: '2'}} descricao="Arroz Brilhante 1KG" img={null} width='150px' preco_un={1} qtd={Valor} set={setValor} addCarrinho={(dados) => {}}/>
            <Produto dados={{setor: '1', categoria: '3', subcategoria: '3'}} descricao="Arroz Brilhante 1KG" img={null} width='150px' preco_un={1} qtd={Valor} set={setValor} addCarrinho={(dados) => {}}/>
            <Produto dados={{setor: '1', categoria: '3', subcategoria: '3'}} descricao="Arroz Brilhante 1KG" img={null} width='150px' preco_un={1} qtd={Valor} set={setValor} addCarrinho={(dados) => {}}/>
            <Produto dados={{setor: '2', categoria: '4', subcategoria: '4'}} descricao="Arroz Brilhante 1KG" img={null} width='150px' preco_un={1} qtd={Valor} set={setValor} addCarrinho={(dados) => {}}/>
            <Produto dados={{setor: '2', categoria: '4', subcategoria: '4'}} descricao="Arroz Brilhante 1KG" img={null} width='150px' preco_un={1} qtd={Valor} set={setValor} addCarrinho={(dados) => {}}/>
            <Produto dados={{setor: '1', categoria: '1', subcategoria: '1'}} descricao="Arroz Brilhante 1KG" img={null} width='150px' preco_un={1} qtd={Valor} set={setValor} addCarrinho={(dados) => {}}/>
            <Produto dados={{setor: '1', categoria: '1', subcategoria: '1'}} descricao="Arroz Brilhante 1KG" img={null} width='150px' preco_un={1} qtd={Valor} set={setValor} addCarrinho={(dados) => {}}/>
            <Produto dados={{setor: '1', categoria: '1', subcategoria: '1'}} descricao="Arroz Brilhante 1KG" img={null} width='150px' preco_un={1} qtd={Valor} set={setValor} addCarrinho={(dados) => {}}/>
            <Produto dados={{setor: '1', categoria: '1', subcategoria: '1'}} descricao="Arroz Brilhante 1KG" img={null} width='150px' preco_un={1} qtd={Valor} set={setValor} addCarrinho={(dados) => {}}/>
            <Produto dados={{setor: '1', categoria: '1', subcategoria: '1'}} descricao="Arroz Brilhante 1KG" img={null} width='150px' preco_un={1} qtd={Valor} set={setValor} addCarrinho={(dados) => {}}/>
            <Produto dados={{setor: '1', categoria: '1', subcategoria: '1'}} descricao="Arroz Brilhante 1KG" img={null} width='150px' preco_un={1} qtd={Valor} set={setValor} addCarrinho={(dados) => {}}/>
            <Produto dados={{setor: '1', categoria: '1', subcategoria: '1'}} descricao="Arroz Brilhante 1KG2" img={null} width='150px' preco_un={1} qtd={Valor} set={setValor} addCarrinho={(dados) => {}}/>
*/}
        </DivProdutos>
    }
        </>
    )
}