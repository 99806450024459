import React, { useState }from "react";
import './style.css'
import axios from "axios";
import { Link, useNavigate  } from "react-router-dom";
import { EntryInput } from "../../../component/input/input";
import { Connection } from "../../../conn/connection";
import { Save } from "../../../component/save/save";
import LogoMercadoUnicao from '../../../imgs/logo.png'
import LoadGif from '../../../imgs/load_gif.gif'



export const Cadastro = () => {
    const [ Nome, setNome ] = useState('')
    const [ Sobrenome, setSobrenome ] = useState('')
    const [ CPF, setCPF ] = useState('')
    const [ Telefone, setTelefone ] = useState('')
    const [ Rua, setRua ] = useState('')
    const [ Numero, setNumero ] = useState('')
    const [ Bairro, setBairro ] = useState('')
    const [ CEP, setCEP ] = useState('')
    const [ Cidade, setCidade ] = useState('')
    const [ Estado, setEstado ] = useState('')
    const [ Email, setEmail ] = useState('')
    const [ Senha, setSenha ] = useState('')
    const [ ConfirmarSenha, setConfirmarSenha ] = useState('')

    const [ Mensagem, setMensagem ] = useState('O nome está vazio!')
    const [ ShowMensagem, setShowMensagem] = useState(false)

    const [ Load, setLoad ] = useState(false)

    const Navegador = useNavigate()

    const estados = [
        [ 'AC', 'Acre' ],
        [ 'AL', 'Alagoas' ],
        [ 'AP', 'Amapá' ],
        [ 'AM', 'Amazonas' ],
        [ 'BA', 'Bahia' ],
        [ 'CE', 'Ceará' ],
        [ 'DF', 'Distrito Federal' ],
        [ 'ES', 'Espírito Santo' ],
        [ 'GO', 'Goías' ],
        [ 'MA', 'Maranhão' ],
        [ 'MT', 'Mato Grosso' ],
        [ 'MS', 'Mato Grosso do Sul' ],
        [ 'MG', 'Minas Gerais' ],
        [ 'PA', 'Pará' ],
        [ 'PB', 'Paraíba' ],
        [ 'PR', 'Paraná' ],
        [ 'PE', 'Pernambuco' ],
        [ 'PI', 'Piauí' ],
        [ 'RJ', 'Rio de Janeiro' ],
        [ 'RN', 'Rio Grande do Norte' ],
        [ 'RS', 'Rio Grande do Sul' ],
        [ 'RO', 'Rondônia' ],
        [ 'RR', 'Roraíma' ],
        [ 'SC', 'Santa Catarina' ],
        [ 'SP', 'São Paulo' ],
        [ 'SE', 'Sergipe' ],
        [ 'TO', 'Tocantins' ],
      ]

    const PesquisaCEP = async () => {
        let cep = CEP
        cep = cep.replace('.', '')
        cep = cep.replace('-', '')
        console.log(cep)
        try {
            let resposta = await axios.get(`https://brasilapi.com.br/api/cep/v1/${CEP}`)
            console.log(resposta.status)
        

            if (resposta.status == 200){
                setEstado(resposta.data.state)
                setCidade(resposta.data.city)
            } 
        }
        finally {

        }
        
    }

    const CadastrarButton = async() => {
        if (!Load){
            setLoad(true)
            let dict = [
                ["Nome", Nome],
                ["Sobrenome", Sobrenome],
                ["CPF", CPF],
                ["Telefone", Telefone],
                ["Rua", Rua],
                ["Numero", Numero],
                ["Bairro", Bairro],
                ["CEP", CEP],
                ["Cidade", Cidade],
                ["Estado", Estado],
                ["Email", Email],
                ["Senha", Senha],
                ["Confirmar Senha", ConfirmarSenha]
            ]
            var i
            var prosseguir = true
            for (i=0; i< dict.length; i++){
                let item = dict[i]
                
                if (item[1] == '' | item[1] == undefined){
                    setMensagem(`O campo ${item[0]} não pode ser vazio!`)
                    setShowMensagem(true)
                    prosseguir = false
                }
            }
            if (prosseguir){
                if (Senha == ConfirmarSenha){
                    let conn = new Connection()
    
           
                    let resp = await conn.Ecommerc( 
                        'usuario/cadastro', 
                        {
                            'nome': Nome,
                            'sobrenome': Sobrenome,
                            'cpf': CPF,
                            'senha': Senha,
                            'rua': Rua,
                            'numero': Numero,
                            'bairro': Bairro,
                            'cep': CEP,
                            'cidade': Cidade,
                            'estado': Estado,
                            'email': Email,
                            'telefone': Telefone
                        }
                        )
                    
                    if (resp){
    
                    
                        if (resp.status == 0){
                            
                            let save_module = new Save() 
                        
                            save_module.setToken(resp.token) //salva o token
                            Navegador('/')
                            
                        }
                        else if (resp.status == 2){
                            
                            setMensagem(`Email já cadastrado!`)
                            setShowMensagem(true)
                            
                        }
                        else if (resp.status == 3){
                            
                            setMensagem(`CPF inválido!`)
                            setShowMensagem(true)
                            
                        }
                    }
                }
                else {
                    setMensagem(`As senhas não são iguais!`)
                    setShowMensagem(true)
                }
            }
            setLoad(false)
        }
        
    }

    return (
        <>
        <div id='div-barra-login'>
            <img src={LogoMercadoUnicao} />
        </div>
        <div id='div-cont-login-cadastro'>
        <div id='div-login-cadastro'>
           
            <div id='div-texto-login'>
                <h1 style={{
                    fontSize: '18pt'
                }}>Vamos ao cadastro!</h1>
                <p style={{
                    fontSize: '11pt'
                }}>Informe seus dados pessoais</p>
                <p></p>
            </div>


            <EntryInput type="text" id="nome" valor={Nome} setValor={setNome} placeholder='Nome' className='input_cadastro'/>
            <EntryInput type="text" id="sobrenome" valor={Sobrenome} setValor={setSobrenome} placeholder='Sobrenome' className='input_cadastro'/>
           
            <EntryInput type="mask" mask='999.999.999-99' id="cpf" valor={CPF} setValor={setCPF} placeholder='CPF' className='input_cadastro'/>
            <EntryInput type="mask" mask='(99)999999999'id="telefone" valor={Telefone} setValor={setTelefone} placeholder='Telefone' className='input_cadastro'/>
            
            <hr style={{
                marginTop: '30px',
                color: 'black'
            }}/>
            <div id='div-texto-login'>
                
                <p style={{
                    fontSize: '11pt'
                }}>Informe seu endereço</p>
                <p></p>
            </div>
            <EntryInput type="text" id="rua" valor={Rua} setValor={setRua} placeholder='Rua' className='input_cadastro'/>
            <EntryInput type="text" id="numero" valor={Numero} setValor={setNumero} placeholder='Número' className='input_cadastro'/>
            <EntryInput type="text" id="bairro" valor={Bairro} setValor={setBairro} placeholder='Bairro' className='input_cadastro'/>
            <EntryInput type="mask" mask='99.999-999' id="cep" valor={CEP} setValor={setCEP}  placeholder='CEP' className='input_cadastro' onBlur={PesquisaCEP}/>
            <EntryInput type="text" id="cidade" valor={Cidade} setValor={setCidade}  placeholder='Cidade' className='input_cadastro'/>
            <select className={"input-padrao-entry input_cadastro"} value={Estado} onChange={(event) => {setEstado(event.target.value)}}>
                {
                    estados.map((item) => (
                        <option value={item[0]}>{item[1]}</option>
                    ))
                }
            </select>
            
            <hr style={{
                marginTop: '30px',
                color: 'black'
            }}/>
            <div id='div-texto-login'>
                
                <p style={{
                    fontSize: '11pt'
                }}>Informe seu email e crie uma senha</p>
                <p></p>
            </div>
            <EntryInput type="email" id="email_C" valor={Email} setValor={setEmail}  placeholder='Email' className='input_cadastro'/>
            <EntryInput type="password" id="senha_c" valor={Senha} setValor={setSenha}  placeholder='Senha' className='input_cadastro'/>
            <EntryInput type="password" id="senha_v" valor={ConfirmarSenha} setValor={setConfirmarSenha}  placeholder='Confirme a Senha' className='input_cadastro'/>
            {
                ShowMensagem ? 
                <div id='div-senha-incorreta' style={{
                    marginBottom: '0px'
                }}>
                    <p style={{
                    marginBottom: '0px'
                }}>{Mensagem}</p>
                </div>
                : 
                ''
            }
            <button className='button-padrao' onClick={CadastrarButton}>
                {
                    Load ?
                        <img src={LoadGif}
                        style={{
                            width: '15px'
                        }}/>
                    :
                    'Cadastrar'
                }
            </button>
            <p style={{
                fontSize: '8pt',
                marginTop: '0'
            }}>*Ao cadastrar você concorda com nossos <Link to='/login'>termos de uso</Link>.</p>

            <p id='p-sem-cadastro'>Já tem cadastro? <Link to='/login'>Clique aqui</Link></p>
        </div>
        </div>
        </>
    )
}
