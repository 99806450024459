import React, { useEffect, useState } from "react"
import { Connection } from "../../../conn/connection";
import { Save } from "../../../component/save/save";
import { TelaADM } from "../../../component/TelaADM";
import { EntryInput } from "../../../component/input/input";
import { LoadDiv } from "../../../component/Load";
import './style.css'


export const AddConta = (props) => {
    const [Banco, setBanco] = useState('')
    const [Conta, setConta] = useState('')
    const [Link, setLink] = useState('')
    const [Load, setLoad] = useState(false)

    const AddContaConn = async() => {
        setLoad(true)
        const save = new Save()
        const token = save.getTokenAdmin()
        let resp
        const Conn = new Connection()
        if (token){
           
            resp = await Conn.Ecommerc('intranet/financeiro/conta/criar_conta', {
                'token': token,
                'banco': Banco,
                'conta': Conta,
                'img': Link
            })
            if (resp){
                if (resp.status == '0'){
                    window.location.href = "/intranet/admin/inicial_contas"
                }
            }
        }
        setLoad(false)
    }

    return (
        <>
        <TelaADM title='ADICIONAR NOVA CONTA'>
            <div style={{
                width: '100%',
                textAlign: 'center',
                fontFamily: '"Montserrat"',
            }}>
                <div style={{
                    border: '1px solid rgb(216, 216, 216)',
                    boxSizing: 'border-box',
                    padding: '15px',
                    borderRadius: '7px',
                    display: 'inline-block'
                }}>
                    <p>DADOS</p>
                    <EntryInput Valor={Banco} setValor={setBanco} className="entry-add-conta" id='entry-add-conta-banco' placeholder='Nome do Banco' width='300px'/>
                    <EntryInput Valor={Conta} setValor={setConta} className="entry-add-conta" id='entry-add-conta-conta' placeholder='Número da Conta' width='150px'/>
                    <EntryInput Valor={Link} setValor={setLink} className="entry-add-conta" id='entry-add-conta-link' placeholder='Link Logo' width='300px'/>
                    <button style={{
                    width: '200px',
                    padding: '10px',
                    marginTop: '10px',
                    color:'white',
                    backgroundColor: 'green',
                    border: 'none',
                    outline: 'none',
                    borderRadius: '7px'
                    }} onClick={AddContaConn}>
                    SALVAR
                    </button>
                </div>
                
            </div>
        </TelaADM>
        {
            Load?
            <LoadDiv/>
            :
            ''
        }
        
        </>
    )
}