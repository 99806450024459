import React, { useState } from "react";
import './style.css'
import { Link, useNavigate  } from "react-router-dom";

import { EntryInput } from "../../../component/input/input";

import { Connection } from "../../../conn/connection";

import { Save } from "../../../component/save/save";

import LogoMercadoUnicao from '../../../imgs/logo.png'


export const LoginInterno = () => {

   
    const [user, setUser] = useState()

    
    const [senha, setSenha] = useState()

    const Navegador = useNavigate()

    const [SenhaIncorreta, setSenhaIncorreta] = useState(false)

    async function Logar() {

       
        let conn = new Connection()

       
        let resp = await conn.Ecommerc( 
            'usuario/login', 
            {"senha": senha, "email": user} 
            )

        
       


        
        if (resp){

           
            if (resp.status == 0){
                
                let save_module = new Save() 
               
                save_module.setToken(resp.token) //salva o token
                Navegador('/')
                
            }
            else if (resp.status == 2){
               
                setSenhaIncorreta(true)
            }
        }
    }

    return ( //retorno de HTML para a pagina /intranet/login, isto é, o retorno dessa funcao de login
    <>  
        <div id='div-barra-login'>
            <img src={LogoMercadoUnicao} />
        </div>
        <div id='div-login'>
           
            <div id='div-texto-login'>
                <h1 style={{
                    fontSize: '18pt'
                }}>Olá, {new Date().getHours() < 12 ? 'bom dia' : new Date().getHours() < 18 ? 'boa tarde' : 'boa noite'}!</h1>
                <p style={{
                    fontSize: '11pt'
                }}>Digite seu email e senha para entrar.</p>
                <p></p>
            </div>


            <EntryInput type="email" id="usuario" valor={user} setValor={setUser} placeholder='Email'/>

           
            <EntryInput type="password" id="senha" valor={senha} setValor={setSenha} placeholder='Senha'/>

            {
                SenhaIncorreta?
                <div id='div-senha-incorreta'>
                    <p>Usuário e/ou Senha incorretos!</p>
                </div>
                :
                ''
            }
           
            <button className='button-padrao' onClick={Logar}>Entrar</button>

            <p id='p-sem-cadastro'>Ainda não tem cadastro? <Link to='/cadastro'>Clique aqui</Link></p>
        </div>
        <div>

        </div>
        
        
    </>
        
    )
}