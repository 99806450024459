export class Save{
    constructor(){

    }
    setToken(token){
        sessionStorage.setItem('@token', token)
    }

    getToken(){
        return sessionStorage.getItem('@token')
    }
    setObjetc(name, value){
        let objetct = JSON.stringify(value)
        localStorage.setItem(`@${name}`, objetct)
    }
    getObject(name){
        let object = localStorage.getItem(`@${name}`)
        return JSON.parse(object)
    }

    setTokenAdmin(token){
        sessionStorage.setItem('@token-admin', token)
    }
    
    getTokenAdmin(){
        return sessionStorage.getItem('@token-admin')
    }
    setObjetcAdmin(name, value){
        let objetct = JSON.stringify(value)
        localStorage.setItem(`@${name}-admin`, objetct)
    }
    getObjectAdmin(name){
        let object = localStorage.getItem(`@${name}-admin`)
        return JSON.parse(object)
    }
}