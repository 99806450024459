import React, { useEffect, useState } from "react";
import './style.css'
import { Connection } from "../../../conn/connection";
import SemFoto from '../../../imgs/sem-foto.png'
import { Save } from "../../../component/save/save";

export const ProdutosADM = (props) => {
    const [ ListProdutos, setListProdutos ] = useState([{
        cod: '',
        barras: '',
        desc: '',
        preco: '',
        estoque: '',
    }])
    const conn = new Connection()
    const [ ProdutoFocus, setProdutoFocus ] = useState()
    const [ Car, setCar] = useState(false)
    const LoadInicial = async () => {
        if (!Car){
            setCar(true)
        let save = new Save()
        let token = save.getTokenAdmin()
        const conn = new Connection()
        if (token){
            let resp = await conn.Ecommerc('intranet/produtos/pesquisa', {
                'token': token,
            })
            if (resp){
                if (resp.status == '0'){
                    setListProdutos(resp.produtos)
                    
                }
            }
        }
    }
    }
    useEffect(() => {LoadInicial()}, [])
    
    const ChangeImg = (event) => {
        let file = event.target.files[0]
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async() => {
            console.log(reader.result);
            let save = new Save()
            let token = save.getTokenAdmin()
            const conn = new Connection()
            if (token){
                let resp = await conn.Ecommerc('intranet/produtos/save', {
                    'token': token,
                    'tipo': String(ProdutoFocus.cod),
                    'data': reader.result
                })
                if (resp){
                    if (resp.status == '0'){
                        window.location.reload()
                        
                    }
                }
            }
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }
    
    return (
        <>
        <div style={{
            width: '100%'
        }}>
            <div style={{
                width: '100%',
                boxSizing: 'border-box',
                padding: '10px',
                fontFamily: '"Montserrat"',
                fontWeight: '600',
                
            }}>
                <p>PRODUTOS</p>
            </div>
            {
                ProdutoFocus != null ?
            <div style={{
                width: '100%',
                textAlign: 'center'
            }}>
                <div style={{
                        padding: '10px',
                        boxSizing: 'border-box',
                        border: 'rgb(216, 216, 216) 1px solid',
                        borderRadius: '7px',
                        width: '800px',
                        display: 'inline-block'
                    }}>
                    <div style={{
                        verticalAlign: 'top',
                        display: 'inline-block'
                    }}>
                        <img  style={
                            {
                                backgroundColor: 'white',
                                width: '130px'
                            }
                        }src={conn.URLImagemProdutoTemp(ProdutoFocus.cod)}/>
                    </div>
                    <div style={{
                        verticalAlign: 'middle',
                        display: 'inline-block'
                    }}>
                        <div style={{
                            textAlign: "right",
                            display: 'inline-block'
                        }}>
                            <label><strong>Código Interno:</strong></label> <br/>
                            <label><strong>Código de Barras:</strong></label> <br/>
                            <label><strong>Descrição:</strong></label> <br/>
                            <label><strong>Preço:</strong></label> <br/>
                            <label><strong>Estoque:</strong></label> <br/>
                        </div>
                        <div style={{
                            display: 'inline-block',
                            marginLeft: '10px',
                            textAlign: 'left'
                        }}>
                            <label>{ProdutoFocus.cod}</label> <br/>
                            <label>{ProdutoFocus.barras}</label> <br/>
                            <label>{ProdutoFocus.desc}</label> <br/>
                            <label>{ProdutoFocus.preco}</label> <br/>
                            <label>{ProdutoFocus.estoque}</label> <br/>
                        </div>

                    </div>
                    <div style={{
                        verticalAlign: 'middle',
                        display: 'inline-block',
                        marginLeft: '20px'
                    }}>
                        <button style={{width: '200px'}}>CONFIRMAR</button>
                        <br/>
                        <input type="file" title="teste" accept="image/*" onChange={ChangeImg}/>
                        
                    </div>
                </div>
            </div>
            :
            ''
            }
            <div style={{
                boxSizing: 'border-box',
                padding: '10px',
                width: '100%',
            }}>
                <table style={{width: '100%', fontFamily: 'Montserrat', textAlign: 'left'}} border="0" cellspacing="0" cellpadding="5">
                    <tr>
                        <th style={{width: '10%'}}>Cód.</th>
                        <th style={{width: '15%'}}>Barras</th>
                        <th style={{width: '35%'}}>Descrição</th>
                        <th style={{width: '10%'}}>Preço</th>
                        <th style={{width: '10%'}}>Estoque</th>
                    </tr>
                    <tr>
                        <th colSpan='5'>
                        <hr/>
                        </th>
                    </tr>
                    
                        {
                            ListProdutos.map((item) => (
                                <tr style={{
                                    cursor: 'pointer'
                                }} onClick={() => {
                                    setProdutoFocus(item)
                                }}>
                                    <td style={{width: '10%'}}>{item.cod}</td>
                                    <td style={{width: '15%'}}>{item.barras}</td>
                                    <td style={{width: '35%'}}>{item.desc}</td>
                                    <td style={{width: '10%'}}>{item.preco}</td>
                                    <td style={{width: '10%'}}>{item.estoque}</td>
                                </tr>
                            ))
                        }
                    
                </table>
            </div>
        </div>
        </>
    )
}