import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

//rotas
/*import { LoginInterno } from './routers/login/login_interno';*/
import { Teste } from './routers/teste/index.js'
import { LoginInterno } from './routers/login/login_interno';
import { Cadastro } from './routers/login/cadastro/index.js';
import { Home } from './routers/home/index.js';
import { Inicial } from './routers/inicial/inicial.js';
import { Setor } from './routers/setor/index.js';
import { CadastroAlterar } from './routers/dados/index.js';
import { FinalizarPedido } from './routers/finalizar_pedido/index.js';
import { MeusPedidos } from './routers/meus_pedidos/index.js';
import { HomeADM } from './routers/adm/home/index.js';
import { PedidosAdm } from './routers/adm/pedidos/index.js';
import { ProdutosADM } from './routers/adm/produtos/index.js';
import { InicialContas } from './routers/financeiro/contas/inicial.js';
import { AddConta } from './routers/financeiro/contas/add_conta.js';
import { MovimentacoesContas } from './routers/financeiro/contas/movimentacoes.js';
import { Pagamentos } from './routers/financeiro/pagamentos/inicial.js';
import { InserirPagamento } from './routers/financeiro/pagamentos/inserir.js';
import { InicialRecebimento } from './routers/financeiro/recebimentos/inicial.js';
import { InserirAlterarRecebimento } from './routers/financeiro/recebimentos/inseriralterar.js';
import { VendasList } from './routers/financeiro/vendas/inicial.js';
import { InserirAlterarVendas } from './routers/financeiro/vendas/inserir.js';
import { CartaoInicial } from './routers/financeiro/cartoes/inicial.js';

//configurnado router
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { LoginAdmin } from './routers/adm/login/index.js';

const router = createBrowserRouter([ /*elemento base das rotas, CONTEM TODAS AS ROTAS E DEFINICOES DE ROTA*/
  {
    path: '/intranet/admin',
    element: <HomeADM/>,
    children: [
      {
        path: 'pedidos',
        element: <PedidosAdm/>
      },
      {
        path: 'produtos',
        element: <ProdutosADM/>
      },
      {
        path: 'inicial_contas',
        element: <InicialContas/>,
      },
      {
        path: 'inicial_contas/into/add',
        element: <AddConta/>
      },
      {
        path: 'movimentacoes',
        element: <MovimentacoesContas/>
      },
      {
        path: 'pagamentos',
        element: <Pagamentos/>
      },
      {
        path: 'pagamentos/into/pagto',
        element: <InserirPagamento/>
      },
      {
        path: 'recebimentos',
        element: <InicialRecebimento/>
      },
      {
        path: 'recebimentos/into/recb',
        element: <InserirAlterarRecebimento/>
      },
      {
        path: 'vendas',
        element: <VendasList/>
      },
      {
        path: 'vendas/into/vnd',
        element: <InserirAlterarVendas/>
      },
      {
        path: 'cartao',
        element: <CartaoInicial/>
      }
    ]
  }, {
    path: '/intranet/v1/login',
    element: <LoginAdmin/>
  },
  {
    path: '/intranet/login',
    element: <HomeADM/>
  },
  {
    path: '/login',
    element: <LoginInterno/>,
  },
  {
    path: '/cadastro',
    element: <Cadastro/>
  },
  {
    path: '/perfil',
    element: <CadastroAlterar/>
  },
  {
    path: '/finalizar',
    element: <FinalizarPedido/>
  },
  {
    path: '/meuspedidos',
    element: <MeusPedidos/>
  },
  {
    path: '/',
    element: <Home/>,
    children: [
      {
        path: '',
        element: <Inicial/>
      },
      {
        path: 'pesquisa/:tipo/:setor',
        element: <Setor/>
      }
      ,
      {
        path: 'pesquisa/descricao/',
        element: <Setor/>
      }
    ]
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
