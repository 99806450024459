import React, { useEffect, useState } from "react";
import './style.css'

import LogoMercadoUnicao from '../../imgs/logo.png'
import { Connection } from "../../conn/connection";
import { Save } from "../../component/save/save";
import { Link, useOutlet, useNavigate} from "react-router-dom";

export const FinalizarPedido = () => {
    const [ RetirarLoja, setRetirarLoja ] = useState('1')
    const LimiteFrete = 100
    const Frete = 20
    const [ Carrinho, setCarrinho ] = useState([])
    const [ Usuario, setUsuario ] = useState({})

    const con = new Connection()
    const Navegador = useNavigate()
    let carregando = false
    const LoadInicial = async () => {
        if (!carregando){
            carregando = true
            const conn = new Connection()
            
            let save = new Save()
            let token = save.getToken()
            if (token){
                let resp = await conn.Ecommerc('carrinho/pesquisa', {
                    'token': token,
                 
                })
                if (resp){
                    if (resp.status == '0'){
                        if (resp.produtos.length > 0){
                            setCarrinho(resp.produtos)
                            
                            setRetirarLoja('1')
                        }else {
                            Navegador('/')
                        }
                        

                    }
                }
            }

            if (token){
                let resp = await conn.Ecommerc('usuario/dados', {
                    'token': token
                })
                if (resp){
                    if (resp.status == '0'){
                        setUsuario(resp.usuario)
                    }
                }
            }
        }
        
    }
    

    useEffect(() => {LoadInicial()}, [])

    const Somar = (list) => {
        console.log(list)
        let soma = 0 
        
        for (var i = 0; i < list.length; i++) {
            soma += parseFloat(list[i])
        }
        return soma
    }

    const [EnviandoPedido, setEnviandoPedido] = useState(false)
    const Confirmar = async () => {
        if (!EnviandoPedido){
            setEnviandoPedido(true)
            const conn = new Connection()
            
            let save = new Save()
            let token = save.getToken()
            let resp = await conn.Ecommerc('pedido/novo', {
                'token': token,
                'produtos': Carrinho,
                'cupom': '',
                'entregar': RetirarLoja == '0'
            })
            if (resp){
                if (resp.status == '0'){
                    Navegador('/meuspedidos')
                }
            }
        setEnviandoPedido(false)
        }
    }

    return (
        <>
        <div id='div-barra-login'>
            <img src={LogoMercadoUnicao} onClick={()=> {Navegador('/')}}/>
        </div>
        <div id='div-dados-pedido-root'>
            <div>
                <div id='div-sup-page-pedido'>
                    <p>Confira os dados</p>
                </div>
                <div id='retirar-ou-entregar'>
                    <p>Você vai retirar os produtos em nossa loja?</p>
                    <select onChange={(event) => setRetirarLoja(event.target.value)} value={RetirarLoja}>
                        <option value='1'>Sim, vou retirar na loja</option>
                        {
                        Usuario ? 
                            Usuario.cep == '795400000' ?
                            <option value='0'>Não, quero que entregue</option>
                            :
                            ''
                        :
                        ''
                    }
                        
                    </select>
                    {
                        Usuario ? 
                            Usuario.cep != '79540000' ?
                                <label>Infelizmente não estamos fazendo entrega para sua região</label>
                            :
                            ''
                        :
                        ''
                    }
                    
                </div>
                <div>
                {
                    RetirarLoja == '0' ?
                <div id='endereco-entrega'>
                    <p id='titulo-div-endereco'>Seu Endereço</p>
                    <p className="titulo-endereco">Rua</p>
                    <p className="valor-endereco">{Usuario.rua}</p>
                    <p className="titulo-endereco">Número</p>
                    <p className="valor-endereco">{Usuario.numero}</p>
                    <p className="titulo-endereco">Bairro</p>
                    <p className="valor-endereco">{Usuario.bairro}</p>
                    <p className="titulo-endereco">Cidade</p>
                    <p className="valor-endereco">{Usuario.cidade}-{Usuario.estado}</p>
                    <p className="titulo-endereco">CEP</p>
                    <p className="valor-endereco">{Usuario.cep}</p>
                </div>
                : 
                ''
                }
                <div id='div-produtos-list-finalizar'>
                    <p id='titulo-div-endereco'>Produtos</p>
                    {
                        Carrinho.map((item) => (
                            <div>
                                <div>
                                    <img className="img-finalizar-produtos" src={con.URLImagemProduto(item.id)}/>
                                    <div className="div-finalizar-dados-produtos">
                                        <p className="p-finalizar-descricao">{item.descricao}</p>
                                        <p>{item.preco.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} x {item.qtd} un = {(item.preco * item.qtd).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
                                    </div>
                                    <hr style={{width: '30px'}}/>
                                </div>
                            </div>
                        ))
                    }
                </div>

                
                </div>
                <div id='div-totalizador-pedido'>
                    <hr/>
                    <div id='div-valores-totais-pedido'>
                        <div id='div-left-valores-totais'>
                            <p>Subtotal</p>
                            <p>Entrega</p>
                            <p className="p-total-div-pedido">Total</p>
                        </div>
                        <div id='div-right-valores-totais'>
                            <p>{Somar(
                                                    Carrinho.map((item) => {
                                                        return item.qtd * item.preco
                                                    })
                                                ).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
                            <p>{
                                                    Somar(
                                                        Carrinho.map((item) => {
                                                            return item.qtd * item.preco
                                                        })
                                                    ) > LimiteFrete | RetirarLoja == '1'?
                                                    'grátis'
                                                    :
                                                    Frete.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
                                                    }</p>
                            <p className="p-total-div-pedido">{(
                                                (Somar(
                                                    Carrinho.map((item) => {
                                                        return item.qtd * item.preco
                                                    })
                                                ) > LimiteFrete | RetirarLoja == '1'?
                                                0
                                                :
                                                Frete) 
                                                +
                                                Somar(
                                                    Carrinho.map((item) => {
                                                        return item.qtd * item.preco
                                                    }))
                                            ).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
                        </div>
                    </div>
                    <br/>
                    <button onClick={Confirmar}id='button-finalizar-pedido'>Confirmar Pedido</button>
                </div>
            </div>
            
        </div>
        </>
    )
}