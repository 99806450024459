import React, { useEffect, useState } from "react";
import './style.css'

import { Produto, ProdutoPromocao } from "../../component/produto/produto";
import { Connection } from "../../conn/connection";
import { Link, useOutlet, useNavigate} from "react-router-dom";
import { Save } from "../../component/save/save";

import Logo from '../../imgs/logo.png'

export const Context = React.createContext({})

export const Home = () => {
    const Navegador = useNavigate()
    const [ Valor, setValor ] = useState(0)
    const [ ClickCategoria, setClickCategoria ] = useState(false)
    const [ ShowCarrinho, setShowCarrinho ] = useState(false)
    const [ Descricao, setDescricao ] = useState('')
    const [ Frete, setFrete ] = useState(0)
    const [ LimiteFrete, setLimiteFrete ] = useState(100)
    const [ ListSetores2, setListSetores2 ] = useState([
        {
            'id': 1,
            'titulo': 'Bebidas',
            'categorias': [
                {
                    'id': 1,
                    'titulo': 'Cervejas2',
                    'subcategorias': [
                        {
                            'id': 1,
                            'titulo': 'Puro Malte'
                        }
                    ]
                },
                {
                    'id': 2,
                    'titulo': 'Cervejas2',
                    'subcategorias': [
                        {
                            'id': 1,
                            'titulo': 'Puro Malte'
                        }
                    ]
                },
                {
                    'id': 2,
                    'titulo': 'Cervejas2',
                    'subcategorias': [
                        {
                            'id': 1,
                            'titulo': 'Puro Malte'
                        }
                    ]
                }
            ]
        },
        {
            'id': 2,
            'titulo': 'Grãos',
            'categorias': [
                {
                    'id': 1,
                    'titulo': 'Cervejas',
                    'subcategorias': [
                        {
                            'id': 1,
                            'titulo': 'Puro Malte'
                        }
                    ]
                }
            ]
        },
        {
            'id': 1,
            'titulo': 'Bebidas',
            'categorias': []
        },
        {
            'id': 1,
            'titulo': 'Bebidas',
            'categorias': []
        },
        {
            'id': 1,
            'titulo': 'Bebidas',
            'categorias': []
        },
        {
            'id': 1,
            'titulo': 'Bebidas',
            'categorias': []
        }
    ])
    const [ ListSetores, setListSetores ] = useState([])
    const [ IdsetorFocus, setIdsetorFocus ] = useState(null)
    const [ RetirarLoja, setRetirarLoja ] = useState('0')
    const [ Usuario, setUsuario ] = useState(null)
    const outlet = useOutlet()

    let carregando = false
    const LoadInicial = async () => {
        if (!carregando){
            carregando = true
            const conn = new Connection()
            let resp = await conn.Ecommerc('setor/pesquisa', {
                'token': '0',
                'cascata': true
            })
            if (resp){
                if (resp.status == '0'){
                    setListSetores(resp.setores)
                }
            }
            let save = new Save()
            let token = save.getToken()
            if (token){
                let resp = await conn.Ecommerc('carrinho/pesquisa', {
                    'token': token,
                 
                })
                if (resp){
                    if (resp.status == '0'){
                        setCarrinho(resp.produtos)
                        localStorage.setItem('@retirar', '0')
                    }
                }
            }

            if (token){
                let resp = await conn.Ecommerc('usuario/dados', {
                    'token': token
                })
                if (resp){
                    if (resp.status == '0'){
                        setUsuario(resp.usuario)
                    }
                }
            }
        }
        
    }

    const PesquisaTermo = () => {
        let descricao = encodeURIComponent(Descricao)
        Navegador(`/pesquisa/descricao/${descricao}`)
        Reload[0]('descricao', descricao)
        window.location.reload()
    }

    useEffect( () => {LoadInicial()}, [])

    const [ Reload, setReload ] = useState()
    const [ Carrinho, setCarrinho ] = useState([])
    const Somar = (list) => {
        console.log(list)
        let soma = 0 
        
        for (var i = 0; i < list.length; i++) {
            soma += parseFloat(list[i])
        }
        return soma
    }
    const CarrinhoLocation = (dados) => {

    }
    const addCarrinho = async(dados) => {
       
        let save = new Save()
        let token = save.getToken()
        if (token){
            const conn = new Connection()
         
            let resp = await conn.Ecommerc('carrinho/add', {
                'token': token,
                'produto': String(dados.id),
                'qtd': dados.qtd
            })
            if (resp){
                if (resp.status == '0'){
                    setCarrinho(resp.produtos)
                }
            }
        } else {
            Navegador('/login')
        }
        localStorage.setItem('@retirar', '0')
    }
    const [ SetorMobileFocus, setSetorMobileFocus] = useState()
    const [ CategoriaMobileFocus, setCategoriaMobileFocus] = useState()
    const [ ShowMenuMibile, setShowMenuMibile] = useState(false)
    return (
        <Context.Provider value={{ foo: 'bar', 'setReload': setReload, 'addCarrinho': addCarrinho, 'Carrinho': Carrinho}} style={{height: '100%'}}>
            <div id='div-desktop'>
                <div id='cabecalho-desktop'>
                    <div id="div-cabecalho-cont">
                        <div id='div-desk-img' onClick={()=> {Navegador('/')}}>
                            <img src={Logo} id='img-cabecalho-desk'/>
                        </div>
                        
                        <div id='div-desk-barra-pesquisa'>
                            <input placeholder="Digite o produto que procura" value={Descricao} onChange={(event) => setDescricao(event.target.value)} 
                            onKeyUp={(event) => {
                                if (event.key === "Enter") {
                                    PesquisaTermo()
                                }
                            }}/>

                            <div id='div-desk-lupa' onClick={PesquisaTermo}>
                                <svg width="100%" height="100%" viewBox="0 0 64 64" version="1.1" xmlns="http://www.w3.org/2000/svg" style={{"fill-rule": "evenodd", "clip-rule": "evenodd", "stroke-linejoin": "round", "stroke-miterlimit": "2"}}><rect id="Icons" x="-640" y="-128" width="1280" height="800" style={{"fill":"none"}}/><g id="Icons1" ><g id="Strike"></g><g id="H1"></g><g id="H2"></g><g id="H3"></g><g id="list-ul"></g><g id="hamburger-1"></g><g id="hamburger-2"></g><g id="list-ol"></g><g id="list-task"></g><g id="trash"></g><g id="vertical-menu"></g><g id="horizontal-menu"></g><g id="sidebar-2"></g><g id="Pen"></g><g id="Pen1"></g><g id="clock"></g><g id="external-link"></g><g id="hr"></g><g id="info"></g><g id="warning"></g><g id="plus-circle"></g><g id="minus-circle"></g><g id="vue"></g><g id="cog"></g><g id="logo"></g><g id="eye-slash"></g><g id="eye"></g><g id="toggle-off"></g><g id="shredder"></g><path d="M39.94,44.142c-3.387,2.507 7.145,-8.263 4.148,-4.169c0.075,-0.006 -0.064,0.221 -0.53,0.79c0,0 8.004,7.95 11.933,11.996c1.364,1.475 -1.097,4.419 -2.769,2.882c-3.558,-3.452 -11.977,-12.031 -11.99,-12.045l-0.792,0.546Z"/><path d="M28.179,48.162c5.15,-0.05 10.248,-2.183 13.914,-5.806c4.354,-4.303 6.596,-10.669 5.814,-16.747c-1.34,-10.415 -9.902,-17.483 -19.856,-17.483c-7.563,0 -14.913,4.731 -18.137,11.591c-2.468,5.252 -2.473,11.593 0,16.854c3.201,6.812 10.431,11.518 18.008,11.591c0.086,0 0.172,0 0.257,0Zm-0.236,-3.337c-7.691,-0.074 -14.867,-6.022 -16.294,-13.648c-1.006,-5.376 0.893,-11.194 4.849,-15.012c4.618,-4.459 11.877,-5.952 17.913,-3.425c5.4,2.261 9.442,7.511 10.187,13.295c0.638,4.958 -1.141,10.154 -4.637,13.733c-3.067,3.14 -7.368,5.014 -11.803,5.057c-0.072,0 -0.143,0 -0.215,0Z" style={{"fill-rule" : "nonzero"}}/><g id="spinner--loading--dots-"></g><g id="react"></g></g></svg>
                            </div>
                        </div>
                        
                        <aside id='aside-lateral-desk'>
                            <div id='div-desk-perfil'>
                                <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><title/><g id="about"><path d="M16,16A7,7,0,1,0,9,9,7,7,0,0,0,16,16ZM16,4a5,5,0,1,1-5,5A5,5,0,0,1,16,4Z"/><path d="M17,18H15A11,11,0,0,0,4,29a1,1,0,0,0,1,1H27a1,1,0,0,0,1-1A11,11,0,0,0,17,18ZM6.06,28A9,9,0,0,1,15,20h2a9,9,0,0,1,8.94,8Z"/></g></svg>
                                <div id='div-desk-usuario'>
                                    <p>Olá, {
                                        Usuario?
                                        Usuario.nome
                                        :
                                        'bem-vindo'}!</p>
                                    {
                                        Usuario ?
                                        <p><Link to='/perfil'>Ver meu cadastro</Link></p>
                                        :
                                        <p><Link to='/login'>Entre</Link> ou <Link to='/cadastro'>Cadastre-se</Link></p>
                                    }
                                    
                                </div>
                                
                            </div>
                            <div id='div-meus-pedidos-desk' onClick={() =>  Navegador('/meuspedidos')}>
                                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><defs></defs><title>Wondicon - UI (Free)</title><path class="cls-1" d="M100,175.69a5,5,0,0,1-2.23-.53L27.08,139.89a5,5,0,0,1-2.77-4.48V64.86a5,5,0,0,1,2.77-4.47L97.77,25.11a5,5,0,0,1,4.46,0l70.69,35.28a5,5,0,0,1,2.77,4.47v70.55a5,5,0,0,1-2.77,4.48l-70.69,35.27A5,5,0,0,1,100,175.69ZM34.31,132.32,100,165.1l65.69-32.78V68L100,35.17,34.31,68Zm136.38,3.09h0Z"/><path class="cls-1" d="M100,105a5,5,0,0,1-2.23-.52L27.09,69.34a5,5,0,1,1,4.45-9l68.46,34,68.46-34a5,5,0,1,1,4.45,9l-70.68,35.14A5,5,0,0,1,100,105Z"/><path class="cls-1" d="M135.34,87.43a5,5,0,0,1-2.22-.52L62.43,51.77a5,5,0,1,1,4.45-9L137.57,78a5,5,0,0,1-2.23,9.48Z"/><path class="cls-1" d="M100,175.69a5,5,0,0,1-5-5V100a5,5,0,0,1,10,0v70.69A5,5,0,0,1,100,175.69Z"/></svg>
                                <div id='div-desk-pedidos'>
                                    <p>Meus Pedidos</p>
                                </div>
                            </div>
                            <div id='div-desk-carrinho' onClick={() => setShowCarrinho(true)}>
                                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  
                                        viewBox="0 0 299.915 299.915" style={{"enable-background": "new 0 0 299.915 299.915"}}>
                                    <g>
                                        <path d="M293.494,53.616H55.701c-0.463,0-0.88,0.174-1.318,0.27l-9.672-32.115c-0.109-0.347-0.341-0.604-0.495-0.925
                                            c-0.264-3.303-2.956-5.919-6.324-5.919c-0.006,0-0.019,0-0.026,0L6.401,15.061C2.847,15.081-0.013,17.973,0,21.52
                                            c0.013,3.541,2.886,6.401,6.427,6.401c0.006,0,0.019,0,0.026,0l26.652-0.116l61.524,204.187
                                            c-6.112,5.585-10.013,13.541-10.013,22.468c0,16.858,13.663,30.527,30.527,30.527c16.858,0,30.527-13.67,30.527-30.527
                                            c0-4.396-0.964-8.548-2.635-12.32h88.819c-1.671,3.773-2.635,7.924-2.635,12.32c0,16.858,13.663,30.527,30.527,30.527
                                            c16.858,0,30.527-13.67,30.527-30.527s-13.67-30.527-30.527-30.527c-6.401,0-12.333,1.986-17.243,5.354H132.38
                                            c-4.91-3.368-10.836-5.354-17.237-5.354c-3.181,0-6.189,0.623-9.075,1.523l-9.178-30.45l161.918-0.129
                                            c2.834,0,5.167-1.87,6.016-4.422c0.219-0.405,0.456-0.797,0.585-1.26l34.274-126.39c0.219-0.803,0.244-1.607,0.154-2.384
                                            c0.006-0.135,0.077-0.244,0.077-0.373C299.921,56.495,297.042,53.616,293.494,53.616z M259.753,236.781
                                            c9.749,0,17.674,7.924,17.674,17.674c0,9.749-7.924,17.674-17.674,17.674c-9.75,0-17.674-7.924-17.674-17.674
                                            C242.08,244.705,250.004,236.781,259.753,236.781z M115.15,236.781c9.75,0,17.674,7.924,17.674,17.674
                                            c0,9.749-7.924,17.674-17.674,17.674s-17.674-7.924-17.674-17.674C97.476,244.705,105.4,236.781,115.15,236.781z M254.04,182.03
                                            l-161.011,0.129L58.176,66.476h227.201L254.04,182.03z"/>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                </svg>
                                <div id='div-desk-couter-carrinho'>
                                    <label>{Somar(Carrinho.map((item) => item.qtd))}</label>
                                </div>
                                <div id="carrinho-desk" style={{
                                    'display': ShowCarrinho? 'flex' : 'none'
                                }} onMouseLeave={() => {setShowCarrinho(false); window.location.reload()}}>
                                    <div id='div-carrinho-desk-top'>
                                        <p>{`Carrinho (${Carrinho.length})`}</p>
                                    </div>
                                    <div id='div-carrinho-desk-middle'>
                                        {
                                            Carrinho.map((item) => {
                                                return (
                                                    <>
                                                    <div className="div-desk-carrinho-produtos-list">
                                                        <div className="div-desk-carrinho-produtos-list-img">
                                                            <img src={new Connection().URLImagemProduto(item.id)}/>
                                                        </div>
                                                        <div className="div-desk-carrinho-produtos-list-desc">
                                                            <p cla06ssName="div-desk-carrinho-produtos-list-desc-titulo">{item.descricao}</p>
                                                            <div className="div-desk-carrinho-preco-qtd">
                                                                <p>{item.preco.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
                                                                <p className="div-desk-carrinho-preco-qtd-right">{item.qtd} UN</p>
                                                            </div>
                                                            
                                                            <p className="div-desk-carrinho-preco-total">{(item.preco*item.qtd).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
                                                        </div>
                                                        <div className="div-desk-carrinho-produtos-list-excluir" onClick={() => {
                                                            
                                                            addCarrinho({
                                                            'id': item.id,
                                                            'qtd': 0
                                                        })
                                                        }
                                                        }>
                                                            <p>X</p>
                                                        </div>
                                                       
                                                    </div>
                                                    <div className="div-desk-carrinho-produtos-list-linha">
                                                        <hr/>
                                                    </div>
                                                    </>
                                                )
                                            })
                                        }
                                    </div>
                                    <div id='div-carrinho-desk-bottom'>
                                        <div id='div-input-entregar-retirar-desk'>
                                            <select onChange={(event) => {setRetirarLoja(event.target.value); localStorage.setItem('@retirar', event.target.value)}} value={RetirarLoja}>
                                                {
                                                    Usuario ?
                                                    Usuario.cep == '79540000*'?
                                                     <option value={'0'}>Entregar no meu endereço</option>
                                                     : ''
                                                    : <option value={'1'}> Vou retirar na loja</option>
                                                }
                                                <option value={'1'}> Vou retirar na loja</option>
                                            </select>
                                        </div>
                                        <div id='div-carrinho-desk-totais-list'>
                                            <div id='div-carrinho-desk-totais-list-left'>
                                                <p>Subtotal {`(${Carrinho.length})`}</p>
                                                <p>Entrega</p>
                                            </div>
                                            <div id='div-carrinho-desk-totais-list-right'>
                                                <p>{Somar(
                                                    Carrinho.map((item) => {
                                                        return item.qtd * item.preco
                                                    })
                                                ).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
                                                <p>{
                                                    Somar(
                                                        Carrinho.map((item) => {
                                                            return item.qtd * item.preco
                                                        })
                                                    ) > LimiteFrete | RetirarLoja == '1'?
                                                    'grátis'
                                                    :
                                                    Frete.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
                                                    }</p>
                                            </div>
                                            <hr/>
                                        </div>
                                        <div id='div-carrinho-desk-total'>
                                            <p id='p-div-carrinho-desk-total-left'>Total</p>
                                            <p id='p-div-carrinho-desk-total-right'>{(
                                                (Somar(
                                                    Carrinho.map((item) => {
                                                        return item.qtd * item.preco
                                                    })
                                                ) > LimiteFrete | RetirarLoja == '1'?
                                                0
                                                :
                                                Frete) 
                                                +
                                                Somar(
                                                    Carrinho.map((item) => {
                                                        return item.qtd * item.preco
                                                    }))
                                            ).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
                                        </div>
                                        <div id='div-button-carrinho-desk-finalizar'>
                                            <button onClick={() => {Navegador('/finalizar')}} >Finalizar</button>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            
                        </aside>
                        
                    </div>
                    
                </div>
                <div id='div-desk-bar-setores'>
                    <div id='div-desk-setores-cont'>
                        <div id='div-desk-setores-todos-click' onClick={() => setClickCategoria(true)}>
                            <p>
                                Todas os setores
                                {
                                    !ClickCategoria ? 
                                    <svg className="div-desk-setores-click" width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 17.898C9 18.972 10.2649 19.546 11.0731 18.8388L17.3838 13.3169C18.1806 12.6197 18.1806 11.3801 17.3838 10.6829L11.0731 5.16108C10.2649 4.45388 9 5.02785 9 6.1018V17.898Z"/>
                                    </svg>
                                    :
                                    <svg className="div-desk-setores-click" width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.1018 8C5.02785 8 4.45387 9.2649 5.16108 10.0731L10.6829 16.3838C11.3801 17.1806 12.6197 17.1806 13.3169 16.3838L18.8388 10.0731C19.5459 9.2649 18.972 8 17.898 8H6.1018Z"/>
                                    </svg>
                                }
                            </p>
                            {
                                ClickCategoria ?
                                    <div id='list-setores-corrida-desk' onMouseLeave={() => (setClickCategoria(false))}>
                                        <div id='div-desk-cont-setores-list'>
                                        {
                                            ListSetores.map((item) => {
                                                return (
                                                    <>
                                                    <div className={`div-desk-setores-item-list ${IdsetorFocus == item ? 'div-desk-setores-item-list-focus': ''}`} onMouseMove={() => setIdsetorFocus(item)}
                                                    onClick={() => {
                                                        let dados = encodeURIComponent(item.id)
                                                        Navegador(`/pesquisa/setor/${dados}`)
                                                        Reload[0]('setor', dados)
                                                    }}>
                                                        <p>{item.titulo}</p>
                                                    </div>
                                                    <div style={{
                                                        'display': IdsetorFocus == item ? 'block' : 'none', 
                                                        
                                                    }}>
                                                        {
                                                            IdsetorFocus ? 
                                                            <div id='div-desk-display-setores'>
                                                                {
                                                                    IdsetorFocus.categorias?

                                                                        IdsetorFocus.categorias.map((__item) => {
                                                                            return (
                                                                                <div className="div-itens-list-desk-sotores-options">
                                                                                    <h1 onClick={() => {
                                                                                        let dados = encodeURIComponent(__item.id)
                                                                                        Navegador(`/pesquisa/categoria/${dados}`)
                                                                                        Reload[0]('categoria', dados)
                                                                                    }}>{__item.titulo}</h1>
                                                                                    {
                                                                                        __item.subcategorias ?
                                                                                        __item.subcategorias.map((_item) => {
                                                                                            return (
                                                                                                <p onClick={() => {
                                                                                                    let dados = encodeURIComponent(_item.id)
                                                                                                    Navegador(`/pesquisa/subcategoria/${dados}`)
                                                                                                    Reload[0]('subcategoria', dados)
                                                                                                }}>{_item.titulo}</p>
                                                                                            )
                                                                                        })
                                                                                        :
                                                                                        ''
                                                                                    }
                                                                                </div>
                                                                            )
                                                                        })
                                                                    :
                                                                    ''
                                                                }
                                                            </div>
                                                            :
                                                            ''
                                                        }
                                                    </div>
                                                    </>
                                                )
                                            })
                                        }
                                        </div>
                                        
                                    </div>
                                : 
                                ''
                            }
                                
                        </div>

                        {
                            ListSetores.map((item) => {
                                return (
                                    <div onClick={() => {
                                        let dados = encodeURIComponent(item.id)
                                        Navegador(`/pesquisa/setor/${dados}`)
                                        Reload[0]('setor', dados)
                                    }}
                                    className="div-desk-setores-item-linha">
                                        <p>{item.titulo}</p>
                                    </div>
                                )
                            })
                        }

                    </div>
                </div>
                
                <div id='cabecalho-mobile'>
                    <div id='div-mobile-pesquisa'>
                        <div>
                            <div id='div-mobile-menu-ham' onClick={() => setShowMenuMibile(true)}>
                                <div id='div-mobile-menu-ham-div'>
                                    <hr/>
                                    <hr/>
                                    <hr/>
                                </div>
                            </div>
                            <div id='menu-mobile' style={{
                                display: ShowMenuMibile ? 'flex' : 'none'
                            }} onMouseLeave={() => setShowMenuMibile(false)}>
                                <div style={{width: '100%', textAlign: 'right'}} onClick={() => setShowMenuMibile(false)}>
                                    <p style={{margin: '0px'}} >x</p>
                                </div>
                                <div style={{width: '100%', textAlign: 'center', marginBottom: '10px'}}>
                                    <div id='div-desk-img' onClick={()=> {Navegador('/')}}>
                                        <img src={Logo} id='img-cabecalho-desk'/>
                                    </div>
                                </div>
                                <div >
                                    <div id='div-desk-perfil'>
                                    <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><title/><g id="about"><path d="M16,16A7,7,0,1,0,9,9,7,7,0,0,0,16,16ZM16,4a5,5,0,1,1-5,5A5,5,0,0,1,16,4Z"/><path d="M17,18H15A11,11,0,0,0,4,29a1,1,0,0,0,1,1H27a1,1,0,0,0,1-1A11,11,0,0,0,17,18ZM6.06,28A9,9,0,0,1,15,20h2a9,9,0,0,1,8.94,8Z"/></g></svg>
                                    <div id='div-desk-usuario'>
                                        <p>Olá, {
                                            Usuario?
                                            Usuario.nome
                                            :
                                            'bem-vindo'}!</p>
                                        {
                                            Usuario ?
                                            <p><Link to='/perfil'>Ver meu cadastro</Link></p>
                                            :
                                            <p><Link to='/login'>Entre</Link> ou <Link to='/cadatro'>Cadastre-se</Link></p>
                                        }
                                        
                                    </div>
                                
                                    </div>
                                    <hr/>
                                </div>
                                <div>
                                    {
                                        ListSetores.map((item) => {
                                            return (
                                                <div>
                                                    <div onClick={() => {item.id == SetorMobileFocus ? setSetorMobileFocus(''): setSetorMobileFocus(item.id)}}
                                                        style={{
                                                            fontSize: '14pt',
                                                            fontWeight: item.id == SetorMobileFocus ? '700' : '400'
                                                        }}>
                                                        <div style={{width: '90%', display: 'inline-block'}}>
                                                            <p style={{margin: '5px'}}>{item.titulo}</p>
                                                        </div>
                                                        <div style={{width: '10%', display: 'inline-block'}} 
                                                        onClick={() => {
                                                            let dados = encodeURIComponent(item.id)
                                                            Navegador(`/pesquisa/setor/${dados}`)
                                                            Reload[0]('setor', dados)
                                                            setShowMenuMibile(false)
                                                        }}>
                                                            <div style={{borderRadius: '15px', width: '20px', border: '1px solid black', textAlign: 'center'}}>
                                                                <p style={{margin: '0px'}}>{'>'}</p>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {
                                                            SetorMobileFocus == item.id ?
                                                            <>
                                                            {
                                                                item.categorias.map((categoria, index) => {
                                                                    return (
                                                                        <div>
                                                                            <div style={{
                                                                                    fontSize: '12pt',
                                                                                    fontWeight: categoria.id == CategoriaMobileFocus ? '700' : '400',
                                                                                    boxSizing: 'border-box',
                                                                                    marginLeft: '20px'
                                                                                }}
                                                                            onClick={() => {categoria.id == CategoriaMobileFocus ? setCategoriaMobileFocus(''): setCategoriaMobileFocus(categoria.id)}}>
                                                                                <div style={{width: '90%', display: 'inline-block'}}>
                                                                                    <p style={{margin: '5px'}}>{categoria.titulo}</p>
                                                                                </div>
                                                                                <div style={{width: '10%', display: 'inline-block'}} onClick={() => {
                                                                                        let dados = encodeURIComponent(categoria.id)
                                                                                        Navegador(`/pesquisa/categoria/${dados}`)
                                                                                        Reload[0]('categoria', dados)
                                                                                        setShowMenuMibile(false)
                                                                                    }}>
                                                                                    <div style={{borderRadius: '15px', width: '20px', border: '1px solid black', textAlign: 'center'}}>
                                                                                        <p style={{margin: '0px'}}>{'>'}</p>
                                                                                    </div>
                                                                                    
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                {
                                                                                    CategoriaMobileFocus == categoria.id ?
                                                                                    <>
                                                                                    {
                                                                                        categoria.subcategorias.map((sub, index) => {
                                                                                            return(
                                                                                                <div style={{
                                                                                                    fontSize: '12pt',
                                                                                                    
                                                                                                    boxSizing: 'border-box',
                                                                                                    marginLeft: '40px'
                                                                                                }}>
                                                                                                    <div style={{width: '90%', display: 'inline-block'}}>
                                                                                                        <p style={{margin: '5px'}}>{sub.titulo}</p>
                                                                                                    </div>
                                                                                                    <div style={{width: '10%', display: 'inline-block'}}>
                                                                                                        <div 
                                                                                                        onClick={() => {
                                                                                                            let dados = encodeURIComponent(sub.id)
                                                                                                            Navegador(`/pesquisa/subcategoria/${dados}`)
                                                                                                            Reload[0]('subcategoria', dados)
                                                                                                            setShowMenuMibile(false)
                                                                                                        }}
                                                                                                        style={{borderRadius: '15px', width: '20px', border: '1px solid black', textAlign: 'center'}}>
                                                                                                            <p style={{margin: '0px'}}>{'>'}</p>
                                                                                                        </div>
                                                                                                        
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                    </>
                                                                                    :
                                                                                    ''
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                            </>
                                                            :
                                                            ''
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        }
                                        )
                                    }
                                </div>
                            </div>
                            <div id='div-mobile-img' onClick={()=> {Navegador('/')}}>
                                <img src={Logo} id='img-cabecalho-desk'/>
                            </div>
                            
                            <aside id='aside-lateral-desk' style={{textAlign: 'right'}}>
                            
                            <div id='div-meus-pedidos-desk' onClick={() =>  Navegador('/meuspedidos')}>
                                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><defs></defs><title>Wondicon - UI (Free)</title><path class="cls-1" d="M100,175.69a5,5,0,0,1-2.23-.53L27.08,139.89a5,5,0,0,1-2.77-4.48V64.86a5,5,0,0,1,2.77-4.47L97.77,25.11a5,5,0,0,1,4.46,0l70.69,35.28a5,5,0,0,1,2.77,4.47v70.55a5,5,0,0,1-2.77,4.48l-70.69,35.27A5,5,0,0,1,100,175.69ZM34.31,132.32,100,165.1l65.69-32.78V68L100,35.17,34.31,68Zm136.38,3.09h0Z"/><path class="cls-1" d="M100,105a5,5,0,0,1-2.23-.52L27.09,69.34a5,5,0,1,1,4.45-9l68.46,34,68.46-34a5,5,0,1,1,4.45,9l-70.68,35.14A5,5,0,0,1,100,105Z"/><path class="cls-1" d="M135.34,87.43a5,5,0,0,1-2.22-.52L62.43,51.77a5,5,0,1,1,4.45-9L137.57,78a5,5,0,0,1-2.23,9.48Z"/><path class="cls-1" d="M100,175.69a5,5,0,0,1-5-5V100a5,5,0,0,1,10,0v70.69A5,5,0,0,1,100,175.69Z"/></svg>
                                
                            </div>
                            <div id='div-desk-carrinho' onClick={() => setShowCarrinho(true)}>
                                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  
                                        viewBox="0 0 299.915 299.915" style={{"enable-background": "new 0 0 299.915 299.915"}}>
                                    <g>
                                        <path d="M293.494,53.616H55.701c-0.463,0-0.88,0.174-1.318,0.27l-9.672-32.115c-0.109-0.347-0.341-0.604-0.495-0.925
                                            c-0.264-3.303-2.956-5.919-6.324-5.919c-0.006,0-0.019,0-0.026,0L6.401,15.061C2.847,15.081-0.013,17.973,0,21.52
                                            c0.013,3.541,2.886,6.401,6.427,6.401c0.006,0,0.019,0,0.026,0l26.652-0.116l61.524,204.187
                                            c-6.112,5.585-10.013,13.541-10.013,22.468c0,16.858,13.663,30.527,30.527,30.527c16.858,0,30.527-13.67,30.527-30.527
                                            c0-4.396-0.964-8.548-2.635-12.32h88.819c-1.671,3.773-2.635,7.924-2.635,12.32c0,16.858,13.663,30.527,30.527,30.527
                                            c16.858,0,30.527-13.67,30.527-30.527s-13.67-30.527-30.527-30.527c-6.401,0-12.333,1.986-17.243,5.354H132.38
                                            c-4.91-3.368-10.836-5.354-17.237-5.354c-3.181,0-6.189,0.623-9.075,1.523l-9.178-30.45l161.918-0.129
                                            c2.834,0,5.167-1.87,6.016-4.422c0.219-0.405,0.456-0.797,0.585-1.26l34.274-126.39c0.219-0.803,0.244-1.607,0.154-2.384
                                            c0.006-0.135,0.077-0.244,0.077-0.373C299.921,56.495,297.042,53.616,293.494,53.616z M259.753,236.781
                                            c9.749,0,17.674,7.924,17.674,17.674c0,9.749-7.924,17.674-17.674,17.674c-9.75,0-17.674-7.924-17.674-17.674
                                            C242.08,244.705,250.004,236.781,259.753,236.781z M115.15,236.781c9.75,0,17.674,7.924,17.674,17.674
                                            c0,9.749-7.924,17.674-17.674,17.674s-17.674-7.924-17.674-17.674C97.476,244.705,105.4,236.781,115.15,236.781z M254.04,182.03
                                            l-161.011,0.129L58.176,66.476h227.201L254.04,182.03z"/>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                </svg>
                                <div id='div-desk-couter-carrinho'>
                                    <label>{Somar(Carrinho.map((item) => item.qtd))}</label>
                                </div>
                                <div id="carrinho-desk" style={{
                                    'display': ShowCarrinho? 'flex' : 'none'
                                }} onMouseLeave={() => {setShowCarrinho(false); window.location.reload()}}>
                                    <div id='div-carrinho-desk-top'>
                                        <div style={{position: 'absolute', top: '0px', left: '5px', color: 'white'}} onClick={() => {setShowCarrinho(false); window.location.reload()}}>
                                            <p style={{margin: '0px'}} >x</p>
                                        </div>
                                        <p>{`Carrinho (${Carrinho.length})`}</p>
                                        
                                    </div>
                                    <div id='div-carrinho-desk-middle'>
                                        {
                                            Carrinho.map((item) => {
                                                return (
                                                    <>
                                                    <div className="div-desk-carrinho-produtos-list">
                                                        <div className="div-desk-carrinho-produtos-list-img">
                                                            <img src={new Connection().URLImagemProduto(item.id)}/>
                                                        </div>
                                                        <div className="div-desk-carrinho-produtos-list-desc">
                                                            <p cla06ssName="div-desk-carrinho-produtos-list-desc-titulo">{item.descricao}</p>
                                                            <div className="div-desk-carrinho-preco-qtd">
                                                                <p>{item.preco.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
                                                                <p className="div-desk-carrinho-preco-qtd-right">{item.qtd} UN</p>
                                                            </div>
                                                            
                                                            <p className="div-desk-carrinho-preco-total">{(item.preco*item.qtd).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
                                                        </div>
                                                        <div className="div-desk-carrinho-produtos-list-excluir" onClick={() => {
                                                            
                                                            addCarrinho({
                                                            'id': item.id,
                                                            'qtd': 0
                                                        })
                                                        }
                                                        }>
                                                            <p>X</p>
                                                        </div>
                                                       
                                                    </div>
                                                    <div className="div-desk-carrinho-produtos-list-linha">
                                                        <hr/>
                                                    </div>
                                                    </>
                                                )
                                            })
                                        }
                                    </div>
                                    <div id='div-carrinho-desk-bottom'>
                                        <div id='div-input-entregar-retirar-desk'>
                                            <select onChange={(event) => {setRetirarLoja(event.target.value); localStorage.setItem('@retirar', event.target.value)}} value={RetirarLoja}>
                                                {
                                                    Usuario ?
                                                    Usuario.cep == '79540000*'?
                                                     <option value={'0'}>Entregar no meu endereço</option>
                                                     : ''
                                                    : <option value={'1'}> Vou retirar na loja</option>
                                                }
                                                <option value={'1'}> Vou retirar na loja</option>
                                            </select>
                                        </div>
                                        <div id='div-carrinho-desk-totais-list'>
                                            <div id='div-carrinho-desk-totais-list-left'>
                                                <p>Subtotal {`(${Carrinho.length})`}</p>
                                                <p>Entrega</p>
                                            </div>
                                            <div id='div-carrinho-desk-totais-list-right'>
                                                <p>{Somar(
                                                    Carrinho.map((item) => {
                                                        return item.qtd * item.preco
                                                    })
                                                ).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
                                                <p>{
                                                    Somar(
                                                        Carrinho.map((item) => {
                                                            return item.qtd * item.preco
                                                        })
                                                    ) > LimiteFrete | RetirarLoja == '1'?
                                                    'grátis'
                                                    :
                                                    Frete.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
                                                    }</p>
                                            </div>
                                            <hr/>
                                        </div>
                                        <div id='div-carrinho-desk-total'>
                                            <p id='p-div-carrinho-desk-total-left'>Total</p>
                                            <p id='p-div-carrinho-desk-total-right'>{(
                                                (Somar(
                                                    Carrinho.map((item) => {
                                                        return item.qtd * item.preco
                                                    })
                                                ) > LimiteFrete | RetirarLoja == '1'?
                                                0
                                                :
                                                Frete) 
                                                +
                                                Somar(
                                                    Carrinho.map((item) => {
                                                        return item.qtd * item.preco
                                                    }))
                                            ).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
                                        </div>
                                        <div id='div-button-carrinho-desk-finalizar'>
                                            <button onClick={() => {Navegador('/finalizar')}} >Finalizar</button>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            
                        </aside>
                        </div>
                        <div id='div-mobile-barra-pesquisa'>
                            <input placeholder="Digite o produto que procura" value={Descricao} onChange={(event) => setDescricao(event.target.value)} 
                            onKeyUp={(event) => {
                                if (event.key === "Enter") {
                                    PesquisaTermo()
                                }
                            }}/>
                            <div id='div-desk-lupa' onClick={PesquisaTermo}>
                                <svg width="100%" height="100%" viewBox="0 0 64 64" version="1.1" xmlns="http://www.w3.org/2000/svg" style={{"fill-rule": "evenodd", "clip-rule": "evenodd", "stroke-linejoin": "round", "stroke-miterlimit": "2"}}><rect id="Icons" x="-640" y="-128" width="1280" height="800" style={{"fill":"none"}}/><g id="Icons1" ><g id="Strike"></g><g id="H1"></g><g id="H2"></g><g id="H3"></g><g id="list-ul"></g><g id="hamburger-1"></g><g id="hamburger-2"></g><g id="list-ol"></g><g id="list-task"></g><g id="trash"></g><g id="vertical-menu"></g><g id="horizontal-menu"></g><g id="sidebar-2"></g><g id="Pen"></g><g id="Pen1"></g><g id="clock"></g><g id="external-link"></g><g id="hr"></g><g id="info"></g><g id="warning"></g><g id="plus-circle"></g><g id="minus-circle"></g><g id="vue"></g><g id="cog"></g><g id="logo"></g><g id="eye-slash"></g><g id="eye"></g><g id="toggle-off"></g><g id="shredder"></g><path d="M39.94,44.142c-3.387,2.507 7.145,-8.263 4.148,-4.169c0.075,-0.006 -0.064,0.221 -0.53,0.79c0,0 8.004,7.95 11.933,11.996c1.364,1.475 -1.097,4.419 -2.769,2.882c-3.558,-3.452 -11.977,-12.031 -11.99,-12.045l-0.792,0.546Z"/><path d="M28.179,48.162c5.15,-0.05 10.248,-2.183 13.914,-5.806c4.354,-4.303 6.596,-10.669 5.814,-16.747c-1.34,-10.415 -9.902,-17.483 -19.856,-17.483c-7.563,0 -14.913,4.731 -18.137,11.591c-2.468,5.252 -2.473,11.593 0,16.854c3.201,6.812 10.431,11.518 18.008,11.591c0.086,0 0.172,0 0.257,0Zm-0.236,-3.337c-7.691,-0.074 -14.867,-6.022 -16.294,-13.648c-1.006,-5.376 0.893,-11.194 4.849,-15.012c4.618,-4.459 11.877,-5.952 17.913,-3.425c5.4,2.261 9.442,7.511 10.187,13.295c0.638,4.958 -1.141,10.154 -4.637,13.733c-3.067,3.14 -7.368,5.014 -11.803,5.057c-0.072,0 -0.143,0 -0.215,0Z" style={{"fill-rule" : "nonzero"}}/><g id="spinner--loading--dots-"></g><g id="react"></g></g></svg>
                            </div>
                            
                        </div>
                    </div>
                </div>

                <div id='div-desk-body'>
                    <div >
                        {outlet}
                    </div>
                </div>
                <div id='div-mobile-body'>
                    <div >
                        {outlet}
                    </div>
                </div>
            </div>
            
        </Context.Provider>
    )
}