import React, { useEffect, useState } from "react";
import './style.css'

import Logo from '../../../imgs/logo.png'
import { useNavigate } from "react-router-dom";
import { Link, useOutlet} from "react-router-dom";

export const Context = React.createContext({})

export const HomeADM = (props) => {
    const [ ListMenus, setListMenus ] = useState([{
        'titulo': 'Pedidos',
        'id': '/intranet/admin/pedidos'
    },
    {
        'titulo': 'Produtos',
        'id': '/intranet/admin/produtos'
    },
    {
        'titulo': 'Contas',
        'id': '/intranet/admin/inicial_contas'
    },
    {
        'titulo': 'Movimentações',
        'id': '/intranet/admin/movimentacoes'
    },
    {
        'titulo': 'Pagamentos',
        'id': '/intranet/admin/pagamentos'
    },
    {
        'titulo': 'Recebimentos',
        'id': '/intranet/admin/recebimentos'
    },
    {
        'titulo': 'Vendas',
        'id': '/intranet/admin/vendas'
    },
    {
        'titulo': 'Cartão',
        'id': '/intranet/admin/cartao'
    }
    ])
    
    const LoadInicial = () => {
        let url = window.location.href
        url = '/intranet' + url.split('intranet')[1].split('/into/')[0].split('?')[0]
 
        
        setMenuFocus(url)
        
    }

    useEffect(() => {LoadInicial()}, [])
    const [ MenuFocus, setMenuFocus ] = useState('0')
    const Navegador = useNavigate()
    const outlet = useOutlet()
    return (
        <Context.Provider value={{}}>
        <div id="div-all-adm">
            <div id='div-menu-adm'>
                <div id='logo-adm'>
                    <div id='div-desk-img-adm'>
                        <img src={Logo} id='img-cabecalho-desk'/>
                    </div>
                </div>
                <div>
                {
                    ListMenus.map((item) => {
                        return (
                            <div>
                                <div style={{
                                    boxSizing: 'border-box',
                                    padding: '5px'
                                }} onClick={() => {
                                    setMenuFocus(item.id)
                                    Navegador(item.id)
                                }}>
                                    <div style={{
                                        borderTopRightRadius: '7px',
                                        borderBottomRightRadius: '7px',
                                        width: '10px',
                                        height: '40px',
                                        backgroundColor: MenuFocus == item.id ?'green': 'white',
                                        display: 'inline-block',
                                        verticalAlign: 'middle',
                                        marginRight: '10px'
                                    }}></div>
                                    <p style={{
                                        display: 'inline-block',
                                        verticalAlign: 'middle',
                                        fontSize: '12pt',
                                        fontWeight: MenuFocus == item.id ? '600': '400',
                                        margin: '2px',
                                        cursor: 'pointer',
                                        fontFamily: 'Montserrat'
                                    }}>{item.titulo}</p>
                                </div>
                            </div>
                        )
                    })
                }
                </div>
            </div>
            <div id='div-body-adm'>
                {outlet}
            </div>
        </div>
    </Context.Provider>
    )
}