import React, { useEffect, useState } from "react";
import './style.css'
import { Context } from "../home";
import { Produto } from "../../component/produto/produto";
import { Carrossel } from "../../component/carrossel";
import { ProdutoPromocao } from "../../component/produto/produto";
import { Connection } from "../../conn/connection";
import { Save } from "../../component/save/save";

const TituloSetor = (props) => {
    return (
        <>
            <div id={props.id} className="div-inicial-titulo-setor">
                <div className="div-inicial-titulo-barr">

                </div>
                <div className="div-inicial-titulo-texto">
                    <h1>{props.titulo}</h1>
                </div>
            </div>
        </>
    )
}

export const Inicial = () => {
    const props = React.useContext(Context)
    const [ Valor, setValor] = useState()
    const [ Ofertas, setOfertas ] = useState([])
    const [ Setores, setSetores ] = useState([])

    const Conn = new Connection()

    const onLoad = async () => {
        const save = new Save()
        const token = save.getToken()
        let resp
        if (token){
            resp = await Conn.Ecommerc('produto/pesquisa/inicial', {
                'token': token
            })
        } else {
            resp = await Conn.Ecommerc('produto/pesquisa/inicial', {
                'token': '0'
            })
        }
        
        if (resp){
            if (resp.status == '0'){
                setOfertas([...resp.ofertas])
                setSetores([...resp.setores])
            }
        }

    }
    useEffect(() => {onLoad()}, [])
    return (
        <>
            <div>
                <TituloSetor titulo='Ofertas da Semana' id='id-titulo-setor-oferta-semana'/>
            </div>
            <div>
                <Carrossel marginItem={40}>
                    {
                        Ofertas.map((item) => (
                            <ProdutoPromocao width="150px" codigo={item.id} preco_ant={item.pr_ant} preco_atual={item.preco} link={`pesquisa/codigo/${item.id}`}/>        
                        ))
                    }
                   
                </Carrossel>

                {
                    Setores.map((item, _index) => {
                        return (
                            <>
                            <TituloSetor titulo={item.titulo} id='id-titulo-setor-oferta-semana'/>
                            <Carrossel marginItem={10}>
                                {
                                    item.produtos.map((produto, index) => (
                                        <Produto descricao={produto.descricao} width='150px' preco_un={produto.preco} qtd={produto.pedido} set={(valor) => {
                                            let setores = Setores
                                            let _item_ = produto
                                            _item_['pedido'] = valor
                                            setores[_index][index] =  _item_
                                            setSetores([...setores])

                                        }} addCarrinho={props.addCarrinho}
                                        Carrinho={props.Carrinho}
                                        codigo={produto.id}/>                    
                                    ))
                                }
                            </Carrossel>
                            </>
                        )
                    })
                }
                
            </div>
            
        </>
    )
}