import React, { useEffect, useState } from "react"
import { Connection } from "../../../conn/connection";
import { Save } from "../../../component/save/save";
import { TelaADM } from "../../../component/TelaADM";
import { EntryInput } from "../../../component/input/input";
import { LoadDiv } from "../../../component/Load";
import './style.css'
import InputMask from 'react-input-mask';
import moment from 'moment';
import { useSearchParams } from "react-router-dom";

export const InserirAlterarVendas = (props) => {
    const [ParametrosURL, setParametrosURL] = useSearchParams()
    const [TituloPagina, setTituloPagina] = useState('VENDAS > INSERIR')
    const [AVista, setAVista] = useState(0)
    const [Load, setLoad] = useState(false)

    const [InserirAlterar, setInserirAlterar] = useState('Inserir')
    const [ListPix, setListPix] = useState([
        {
            'valor': 0,
            'taxa': 0,
            'banco': 0,
            'lucro': 0
        }
    ])

    const [Data, setData] = useState('')
    const [Conta, setConta] = useState('')
    const [APrazo, setAPrazo] = useState(0)
    const [CrtDebito, setCrtDebito] = useState(0)
    const [CrtCredito, setCrtCredito] = useState(0)
    const [Lucro, setLucro] = useState(0)

    const [ListContas, setListContas] = useState([])
    const [Id, setId] = useState()

    const save = new Save()
    const Conn = new Connection()

    const inserir = async() => {
        const token = save.getTokenAdmin()

        if (AVista == '' & AVista < 0){
            
            alert('Verique o campo à vista')
        }
        else if (APrazo == '' & APrazo < 0){
            
            alert('Verique o campo à prazo')
        }
        else if (CrtDebito == '' & CrtDebito < 0){
            alert('Verique o campo cartão de débito')
        }
        else if (CrtCredito == '' & CrtCredito < 0){
            alert('Verique o campo cartão de crédito')
        }
        else if (Conta == ''){
            alert('Verique o campo a conta')
        }
        else if (Data == ''){
            alert('Verique o campo a data')
        }
        else {
            setLoad(true)
            let resp = await Conn.Ecommerc('intranet/financeiro/vendas/inserir', {
                'token': token,
                'data': Data,
                'a_vista': String(AVista),
                'a_vista_banco': String(Conta),
                'a_prazo': String(APrazo),
                'cartao_debito': String(CrtDebito),
                'cartao_credito': String(CrtCredito),
                'pix': ListPix,
                'lucro': String(Lucro)
            })
            if (resp){
                
                if (resp.status == '0'){
                    alert('Sucesso!')
                    window.location.href = '/intranet/admin/vendas'
                }
            }
            setLoad(false)
        }
    }

    const excluir = async() => {
        const token = save.getTokenAdmin()
        setLoad(true)
        let resp = await Conn.Ecommerc('intranet/financeiro/vendas/excluir', {
            'token': token,
            'venda': String(Id),
        })
        if (resp){
          
            if (resp.status == '0'){
                alert('Sucesso!')
                window.location.href = '/intranet/admin/vendas'
            }
        }
        setLoad(false)
    }


    const LoadInicial = async() => {
        let pgt = ParametrosURL.get('cod')

        const token = save.getTokenAdmin()
        let resp
        resp = await Conn.Ecommerc('intranet/financeiro/conta/pesquisar', {
            'token': token,
        })
        if (resp){
            if (resp.status == '0'){
                setListContas(resp.dados.lista_bancos)
            }
        }

        if (pgt){
            resp = await Conn.Ecommerc('intranet/financeiro/vendas/pesquisar', {
                'token': token,
                'id': String(pgt)
            })
            if (resp){
              
                if (resp.status == '0'){
                    if (resp.dados.vendas.length == 0){
                        alert('Venda não encontrada')
                    } else {
                    setInserirAlterar('Alterar')
                    
                    let dados = resp.dados.vendas[0]
                    setId(dados.id)
                    setAVista(dados.a_vista)
                    setListPix(dados.pix_list)
                    setData(dados.data)
                    setConta(dados.banco)
                    setAPrazo(dados.a_prazo)
                    setCrtDebito(dados.cartao_debito)
                    setCrtCredito(dados.cartao_credito)
                    }
                }
            }
        }

        
        
    }

    useEffect(() => {LoadInicial()}, [])

    return (
        <>
        <TelaADM title={TituloPagina}>
            <div className="entry-div-default" style={{
            width: '15%',
            
        
            }}>
                <label>Data:</label>
                <InputMask mask='99/99/9999' value={Data} onBlur={(event) => {if(!event.target.value){setData(0)}}} placeholder="Digite a data" onChange={(event) => setData(event.target.value)} disabled={InserirAlterar != 'Inserir'}/>
            </div>
            <div className="entry-div-default" style={{
            width: '15%',
            
        
            }}>
                <label>Valor à Vista:</label>
                <input type="number" value={AVista} onBlur={(event) => {if(!event.target.value){setAVista(0)}}} placeholder="Digite o valor" onChange={(event) => setAVista(event.target.value)} disabled={InserirAlterar != 'Inserir'}/>
            </div>
            <div className="entry-div-default" style={{
            width: '15%',
            
        
            }}>
                <label>Lucro à vista:</label>
                <input type="number" value={Lucro} onBlur={(event) => {if(!event.target.value){setLucro(0)}}} placeholder="Digite o valor" onChange={(event) => setLucro(event.target.value)} disabled={InserirAlterar != 'Inserir'}/>
            </div>
            <div className="entry-div-default" style={{
            width: '30%',
            
        
            }}>
                <label>Conta Recebimento:</label>
                <select value={Conta} placeholder="Digite o valor" onChange={(event) => setConta(event.target.value)} disabled={InserirAlterar != 'Inserir'}>
                    <option value=''>Selecione</option>
                    {
                        ListContas.map((item) => (
                            <option value={item.id}>{item.banco} {`(${item.conta})`}</option>
                        ))
                    }
                </select>
            </div>
            <br/>
            <div className="entry-div-default" style={{
            width: '15%',
            
        
            }}>
                <label>Valor à prazo:</label>
                <input type="number" value={APrazo} onBlur={(event) => {if(!event.target.value){setAPrazo(0)}}} placeholder="Digite o valor" onChange={(event) => setAPrazo(event.target.value)} disabled={InserirAlterar != 'Inserir'}/>
            </div>
            <div className="entry-div-default" style={{
            width: '15%',
            
        
            }}>
                <label>Valor no Cartão Débito:</label>
                <input type="number" value={CrtDebito} onBlur={(event) => {if(!event.target.value){setCrtDebito(0)}}} placeholder="Digite o valor" onChange={(event) => setCrtDebito(event.target.value)} disabled={InserirAlterar != 'Inserir'}/>
            </div>
            <div className="entry-div-default" style={{
            width: '15%',
            
        
            }}>
                <label>Valor no Cartão Crédito:</label>
                <input type="number" value={CrtCredito} onBlur={(event) => {if(!event.target.value){setCrtCredito(0)}}} placeholder="Digite o valor" onChange={(event) => setCrtCredito(event.target.value)} disabled={InserirAlterar != 'Inserir'}/>
            </div>
            <div style={{
                    padding: '10px',
                    boxSizing: 'border-box',
                    border: 'rgb(216, 216, 216) 1px solid',
                    borderRadius: '7px',
                    width: '100%',
                    display: 'inline-block',
                    marginTop: '10px',
                    textAlign: 'center',
                    
                }}>
                    <p style={{textAlign: 'center'}}>PIX</p>
                    {
                        ListPix.map((item, index) => (
                            <div>
                                 <div className="entry-div-default" style={{
                                    width: '15%',
                                    
                                
                                    }}>
                                    <label>Valor:</label>
                                    <input type="number" value={item.valor} onBlur={(event) => {if(!event.target.value){
                                            let item = ListPix
                                            item[index]['valor'] = 0
                                            setListPix([...item])
                                        }}} placeholder="Digite o valor" 
                                        onChange={(event) => {
                                            let item = ListPix
                                            item[index]['valor'] = event.target.value
                                            setListPix([...item])
                                        }} disabled={InserirAlterar != 'Inserir'}/>
                                </div>
                                <div className="entry-div-default" style={{
                                    width: '15%',
                                    
                                
                                    }}>
                                    <label>Taxa:</label>
                                    <input type="number" value={item.taxa} onBlur={(event) => {if(!event.target.value){
                                           let item = ListPix
                                           item[index]['taxa'] = 0
                                           setListPix([...item])
                                        }}} placeholder="Digite o valor" 
                                        onChange={(event) => {
                                            let item = ListPix
                                            item[index]['taxa'] = event.target.value
                                            setListPix([...item])
                                            }} disabled={InserirAlterar != 'Inserir'}/>
                                </div>
                                <div className="entry-div-default" style={{
                                    width: '15%',
                                    
                                
                                    }}>
                                    <label>Valor Recebido:</label>
                                    <input type="number" value={parseFloat(parseFloat(item.valor) - parseFloat(item.taxa))} disabled={true}/>
                                </div>
                                <div className="entry-div-default" style={{
                                    width: '15%',
                                    
                                
                                    }}>
                                    <label>Lucro:</label>
                                    <input type="number" value={item.lucro} onBlur={(event) => {if(!event.target.value){
                                        let item = ListPix
                                        item[index]['lucro'] = 0
                                        setListPix([...item])
                                        }}} placeholder="Digite o valor" onChange={(event) => {
                                            let item = ListPix
                                            item[index]['lucro'] = event.target.value
                                            setListPix([...item])
                                        }} disabled={InserirAlterar != 'Inserir'}/>
                                </div>
                                <div className="entry-div-default" style={{
                                width: '30%',
                                
                            
                                }}>
                                    <label>Conta Recebimento:</label>
                                    <select value={item.banco} placeholder="Digite o valor" onChange={(event) => {
                                        let item = ListPix
                                        item[index]['banco'] = event.target.value
                                        setListPix([...item])
                                    }} disabled={InserirAlterar != 'Inserir'}>
                                        <option value=''>Selecione</option>
                                        {
                                            ListContas.map((item) => (
                                                <option value={item.id}>{item.banco} {`(${item.conta})`}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className="entry-div-default" style={{
                                
                                backgroundColor: 'red',
                                color: 'white',
                                borderRadius: '15px',
                                width: '20px',
                                boxSizing: 'border-box',
                                padding: '2px',
                                height: '20px',
                                textAlign: 'center',
                                display: InserirAlterar != 'Inserir' ? 'none' : 'inline-block'
                                }} onClick={() => {
                                    let item = ListPix
                                    item.splice(index, 1)
                                    setListPix([...item])
                                }}>
                                    <label style={{
                                        textAlign: 'center',
                                        cursor: 'pointer'
                                    }}>X</label>
                                </div>
                            </div>
                        ))
                    }
                    <div>
                        <button className="button-adm-default" onClick={() => {
                            let item = ListPix
                            item.push({
                                'valor': 0,
                                'taxa': 0,
                                'banco': 0,
                                'lucro': 0
                            })
                            setListPix([...item])
                        }}>
                            +
                        </button>
                    </div>
                </div>
                <div>
                {
                InserirAlterar == 'Alterar'?
                <>
                <button style={{
                    width: '300px',
                    marginLeft: '10px'
                }} className="button-adm-default" onClick={excluir}>
                    excluir
                </button>
                </>
                :
                <button style={{
                    width: '300px'
                }} className="button-adm-default" onClick={inserir}>
                    salvar
                </button>

            }
                </div>
        </TelaADM>
        {
            Load ?
            <LoadDiv/>
            :
            ''
        }
        </>
    )
} 