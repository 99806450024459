import React, { useEffect, useState } from "react"
import { Connection } from "../../../conn/connection";
import { Save } from "../../../component/save/save";
import { TelaADM } from "../../../component/TelaADM";
import { EntryInput } from "../../../component/input/input";
import { LoadDiv } from "../../../component/Load";
import InputMask from 'react-input-mask';

import './style.css'
import { useSearchParams } from "react-router-dom";

export const InserirAlterarRecebimento = (props) => {
    const [ParametrosURL, setParametrosURL] = useSearchParams()
    
    const [TituloPagina, setTituloPagina] = useState('RECEBIMENTO > INSERIR')
    const [InserirAlterar, setInserirAlterar] = useState('Inserir')
    const [Load, setLoad] = useState(false)
    const [ShowLote, setShowLote] = useState(true)

    const [ListPlanoDeContas, setListPlanoDeContas] = useState([])
    const [ListContas, setListContas] = useState([])
    const [ListClientes, setListClientes] = useState([])

    
    const [Juros, setJuros] = useState(0)
    const [Desconto, setDesconto] = useState(0)
    const [VlPago, setVlPago] = useState()
    const [DtPagamento, setDtPagamento] = useState('')
    const [ContaPagamento, setContaPagamento] = useState('')
    const [PlanoConta, setPlanoConta] = useState('')
    const [Id, setId] = useState()
    const [Pago, setPago] = useState(false)
    const [Lucro, setLucro] = useState(0)

    const [Titulo, setTitulo] = useState('')
    const [Descricao, setDescricao] = useState('')
    const [Cliente, setCliente] = useState('')
    const [IDMega, setIDMega] = useState('')
    const [DtEmissao, setDtEmissao] = useState('')
    const [DtVencimento, setDtVencimento] = useState('')
    const [Valor, setValor] = useState(0)
    const [Tipo, setTipo] = useState('NM')

    const [EmLote, setEmLote] = useState(false)
    const [Lote, setLote] = useState([])
    const [Ignorar, setIgnorar] = useState(false)
    const [Mov, setMov] = useState()
    const [NSU, setNSU] = useState('')

    const save = new Save()
    const Conn = new Connection()

    const Pagar = async() => {
        const token = save.getTokenAdmin()

        if (Juros == '' & Juros < 0){
            
            alert('Verique o campo Juros')
        }
        else if (Desconto == '' & Desconto < 0){
            
            alert('Verique o campo Desconto')
        }
        else if (DtPagamento == ''){
            alert('Verique o campo Data Pagamento')
        }
        else if (ContaPagamento == ''){
            alert('Verique o campo Conta de Pagamento')
        }
        else if (PlanoConta == ''){
            alert('Verique o campo Plano de Contas')
        }
        else {
            setLoad(true)
           
            let resp = await Conn.Ecommerc('intranet/financeiro/recebimentos/noticiar', {
                'token': token,
                'recebimentos': [String(Id)],
                'data_pagamento': DtPagamento,
                'juros': String(Juros),
                'desconto': String(Desconto),
                'conta': ContaPagamento,
                'plano': PlanoConta,
                'id_sist': false,
                'lucro': String(Lucro),
                'nsu': NSU
            })
            
            if (resp){
                
                if (resp.status == '0'){
                    alert('Sucesso!')
                    window.location.href = '/intranet/admin/recebimentos'
                }
            }
            setLoad(false)
        }
    }

    const estornar = async() => {
        const token = save.getTokenAdmin()
        setLoad(true)
        let resp = await Conn.Ecommerc('intranet/financeiro/recebimentos/cancelar', {
            'token': token,
            'movimentacao': String(Mov),
        })
        if (resp){
          
            if (resp.status == '0'){
                alert('Sucesso!')
                window.location.href = '/intranet/admin/recebimentos'
            }
        }
        setLoad(false)
    }

    const excluir = async() => {
        const token = save.getTokenAdmin()
        setLoad(true)
        let resp = await Conn.Ecommerc('intranet/financeiro/recebimentos/excluir', {
            'token': token,
            'recebimento': String(Id),
            'exc_mov': true
        })
        if (resp){
          
            if (resp.status == '0'){
                alert('Sucesso!')
                window.location.href = '/intranet/admin/recebimentos'
            }
        }
        setLoad(false)
    }

    const Inserir = async() => {
        const token = save.getTokenAdmin()
        if (Titulo == ''){
            alert('Verifique o campo Titulo')
        }
        else if (Descricao == ''){
            alert('Verifique o campo Descricao')
        }
        else if (Cliente == ''){
            alert('Verifique o campo Cliente')
        }
        else if (Valor == ''){
            alert('Verifique o campo Valor')
        }
        else if (DtEmissao == ''){
            alert('Verifique o campo Data Emissão')
        }
        else if (DtVencimento == ''){
            alert('Verifique o campo Data Vencimento')
            
        }
        
        else {
            setLoad(true)
            let resp = await Conn.Ecommerc('intranet/financeiro/recebimentos/inserir', {
                'token': token,
                'id_sist': IDMega,
                'data_emissao': DtEmissao,
                'data_vencimento': DtVencimento,
                'titulo': Titulo,
                'cliente': Cliente,
                'descricao': Descricao,
                'valor_original': Valor,
                'auto': false,
                'tipo': Tipo,
                'nsu': NSU
            })
            if (resp){
                
                if (resp.status == '0'){
                    alert('Sucesso!')
                    window.location.href = '/intranet/admin/recebimentos'
                }
            }
            setLoad(false)
        }
    }


    const LoadInicial = async() => {
        let pgt = ParametrosURL.get('cod')

        const token = save.getTokenAdmin()


        let resp = await Conn.Ecommerc('intranet/financeiro/cliente/pesquisar', {
            'token': token,
        })
        if (resp){
            if (resp.status == '0'){
                setListClientes(resp.dados.lista_cliente)
            }
        }

        resp = await Conn.Ecommerc('intranet/financeiro/plano_contas/pesquisar', {
            'token': token,
            'pag': false
        })
        if (resp){
            if (resp.status == '0'){
                setListPlanoDeContas(resp.dados.lista_planos)
            }
        }

        resp = await Conn.Ecommerc('intranet/financeiro/conta/pesquisar', {
            'token': token,
        })
        if (resp){
            if (resp.status == '0'){
                setListContas(resp.dados.lista_bancos)
            }
        }

        if (pgt){
            resp = await Conn.Ecommerc('intranet/financeiro/recebimentos/pesquisar', {
                'token': token,
                'id': String(pgt)
            })
            if (resp){
                
                if (resp.status == '0'){
                    setInserirAlterar('Alterar')
                    if (resp.dados.list.length == 0){
                        alert('Recebimento não encontrado')
                        window.location.href = '/intranet/admin/recebimentos'
                    } else {
                    let dados = resp.dados.list[0]
                    setTituloPagina(`RECEBIMENTOS > RECEBER #${dados.id}`)
                    setId(dados.id)
                    setIDMega(dados.id_sist)
                    setTitulo(dados.titulo)
                    setDescricao(dados.descricao)
                    setCliente(dados.cliente)
                    
                    setValor(dados.valor_original)
                    setDtEmissao(dados.data_emissao)
                    setDtVencimento(dados.data_vencimento)
                    
                    setVlPago(dados.valor_original - dados.juros + dados.desconto)
                    setDtPagamento(dados.data_pagamento)
                    setTipo(dados.tipo)
                    setEmLote(dados.em_lote)
                    if (dados.em_lote){
                        setLote(dados.lote)
                    }
                    setIgnorar(dados.ignorar)
                    setLucro(dados.lucro)
                    
                    setPago(dados.pago)
                    setMov(dados.mov)
                    setNSU(dados.nsu)
                    if (dados.pago){
                        setJuros(dados.juros)
                        setDesconto(dados.desconto)
                        setContaPagamento(dados.conta_pagamento)
                        setPlanoConta(dados.plano)
                    }
                    }
                }
            }
        }

        
        
    }

    useEffect(() => {LoadInicial()}, [])

    return (
        <>
        <TelaADM title={TituloPagina}>
            <div>
                <div className="entry-div-default" style={{
                    width: '35%',
                    }}>
                    <label>Título:</label>
                    <input value={Titulo} onChange={(event) => setTitulo(event.target.value)} placeholder="Digite o título do recebimento" disabled={InserirAlterar == 'Alterar'}/>
                </div>
                <div className="entry-div-default" style={{
                    width: '63%',
                    }}>
                    <label>Descrição:</label>
                    <input value={Descricao} onChange={(event) => setDescricao(event.target.value)}placeholder="Digite uma descrição" disabled={InserirAlterar == 'Alterar'}/>
                </div>
                <br/>
                <div className="entry-div-default" style={{
                    width: '36%',
                    }}>
                    <label>Cliente:</label>
                    <select value={Cliente} onChange={(event) => setCliente(event.target.value)} disabled={InserirAlterar == 'Alterar'}>
                        <option value=''>Selecione</option>
                        {
                            ListClientes.map((item) => (
                                <option value={item.id}>{item.razao_social}</option>
                            ))
                        }
                    </select>
                </div>
                
                <div className="entry-div-default" style={{
                    width: '20%',
                    }}>
                    <label>ID Mega:</label>
                    <input value={IDMega} onChange={(event) => setIDMega(event.target.value)} placeholder="Digite o ID do sistema Mega" disabled={InserirAlterar == 'Alterar'}/>
                </div>
                <div className="entry-div-default" style={{
                    width: '20%',
                    }}>
                    <label>Data de Emissão:</label>
                    <InputMask value={DtEmissao} onChange={(event) => setDtEmissao(event.target.value)} placeholder="Digite a data" mask='99/99/9999' disabled={InserirAlterar == 'Alterar'}/>
                </div>
                <div className="entry-div-default" style={{
                    width: '20%',
                    }}>
                    <label>Data de Vencimento:</label>
                    <InputMask value={DtVencimento} onChange={(event) => setDtVencimento(event.target.value)}placeholder="Digite a data" mask='99/99/9999' disabled={InserirAlterar == 'Alterar'}/>
                </div>
                <br/>
                <div className="entry-div-default" style={{
                    width: '20%',
                    }}>
                    <label>Valor:</label>
                    <input value={Valor} onChange={(event) => setValor(event.target.value)} placeholder="Digite o valor" tipe='number' disabled={InserirAlterar == 'Alterar'}/>
                </div>
                <div className="entry-div-default" style={{
                    width: '20%',
                    }}>
                    <label>Tipo:</label>
                    <select value={Tipo} onChange={(event) => setTipo(event.target.value)} disabled={InserirAlterar == 'Alterar'}>
                        <option value='NM'>Normal</option>
                        <option value='DL'>Duplicata</option>
                        <option value='RN'>Renegociação</option>
                    </select>
                </div>
            </div>
            <div style={{
                    padding: '10px',
                    boxSizing: 'border-box',
                    border: 'rgb(216, 216, 216) 1px solid',
                    borderRadius: '7px',
                    width: '100%',
                    display: 'inline-block',
                    marginTop: '10px',
                    textAlign: 'center'
                }}>
                    <p style={{textAlign: 'center'}}>PAGAMENTO</p>
                    {
                        InserirAlterar == 'Alterar'?
                        <>
                            <div className="entry-div-default" style={{
                            width: '15%',
                            }}>
                                <label>Valor Original:</label>
                                <input placeholder="Valor" value={Valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} disabled/>
                            </div>
                            <div className="entry-div-default" style={{
                            width: '15%',
                            
                        
                            }}>
                                <label>Juros:</label>
                                <input type="number" value={Juros} onBlur={(event) => {if(!event.target.value){setJuros(0)}}}placeholder="Juros" onChange={(event) => setJuros(event.target.value)} disabled={Pago}/>
                            </div>
                            <div className="entry-div-default" style={{
                            width: '15%',
                            
                        
                            }}>
                                <label>Descontos:</label>
                                <input type="number" onBlur={(event) => {if(!event.target.value){setDesconto(0)}}} value={Desconto} onChange={(event) => setDesconto(event.target.value)} placeholder="Desconto" disabled={Pago}/>
                            </div>
                            <div  className="entry-div-default" style={{
                            width: '15%',
                            
                        
                            }}>
                                <label>Valor Pago:</label>
                                <input placeholder="Valor Pago" value={(parseFloat(Valor)-parseFloat(Desconto)+ parseFloat(Juros)).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} disabled/>
                            </div>
                            <div className="entry-div-default" style={{
                            width: '15%',
                            
                        
                            }}>
                                <label>Data Pagamento:</label>
                                <InputMask placeholder="Data Pagamento" value={DtPagamento} onChange={(event) => setDtPagamento(event.target.value)} mask='99/99/9999'  disabled={Pago}/>
                            </div>
                            <div  className="entry-div-default" style={{
                                width: '15%',
                                
                            
                                }}>
                                <label>Lucro:</label>
                                <input placeholder="Valor Pago" type='number' value={Lucro} onChange={(event) => setLucro(event.target.value)}  disabled={Pago}/>
                            </div>
                            <br/>
                            
                            <div className="entry-div-default" style={{
                            width: '30%',
                            
                        
                            }}>
                                <label>Conta de Pagamento:</label>
                                <select value={ContaPagamento} onChange={(event) => setContaPagamento(event.target.value)}  disabled={Pago}>
                                <option value=''>Selecione</option>
                                    {
                                        ListContas.map((item) => (
                                            <option value={item.id}>{item.banco} {`(${item.conta})`}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="entry-div-default" style={{
                            width: '30%',
                            
                        
                            }}>
                                <label>Plano de Contas:</label>
                                <select value={PlanoConta} onChange={(event) => setPlanoConta(event.target.value)}  disabled={Pago}>
                                <option value=''>Selecione</option>
                                    {
                                        ListPlanoDeContas.map((item) => (
                                            <option value={item.id}>{item.titulo}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div  className="entry-div-default" style={{
                                width: '15%',
                                
                            
                                }}>
                                <label>NSU:</label>
                                <input placeholder="Digite o NSU" type='number' value={NSU} onChange={(event) => setNSU(event.target.value)}  disabled={Pago}/>
                            </div>
                        </>
                        :
                        ''
                    }
                    
                </div>
                {
                    EmLote ?
                        <div style={{
                            padding: '10px',
                            boxSizing: 'border-box',
                            border: 'rgb(216, 216, 216) 1px solid',
                            borderRadius: '7px',
                            width: '100%',
                            display: 'inline-block',
                            marginTop: '10px',
                            textAlign: 'center',
                            height: '200px',
                            maxHeight: '200px',
                            overflowY: 'auto'
                        }}>
                            <p style={{textAlign: 'center'}}>LOTE</p>
                            {
                                Lote.map((item) => {
                                    return (
                                        <div style={{
                                            border: '1px solid rgb(216, 216, 216)',
                                            borderRadius: '7px',
                                            boxSizing: 'border-box',
                                            padding: '8px',
                                            textAlign: "center",
                                            background: 'rgba(185, 185, 185, 0.603)',
                                            marginBottom: '10px'
                                        }}>
                                            <div style={{
                                                borderRadius: '15px',
                                                boxSizing: 'border-box',
                                                padding: '8px',
                                                backgroundColor: 'blue',
                                                width: '70px', 
                                                color: 'white',
                                                fontWeight: '600',
                                                fontFamily: 'Montserrat',
                                                fontSize: '10pt',
                                                display: 'inline-block',
                                                verticalAlign: 'middle',
                                                marginRight: '10px',
                                                cursor: 'pointer'
                                                }} onClick={() => {window.location.href = `/intranet/admin/recebimentos/into/recb?cod=${item.id}`}}>
                                                <label>id: {item.id}</label>
                                            </div>
                                            <div style={{
                                                width: '12%',
                                                verticalAlign: 'middle',
                                                display: 'inline-block',
                                                fontFamily: 'Montserrat',
                                                textAlign: 'left'
                                            }}>
                                                <label style={{
                                                    display: 'block',
                                                    fontSize: '9pt',
                                                    marginBottom: '3px'
                                                    
                                                }}>Data Emissão:</label>
                                                <label>{item.data_emissao}</label>
                                            </div>

                                            <div style={{
                                                width: '12%',
                                                verticalAlign: 'middle',
                                                display: 'inline-block',
                                                fontFamily: 'Montserrat',
                                                textAlign: 'left'
                                            }}>
                                                <label style={{
                                                    display: 'block',
                                                    fontSize: '9pt',
                                                    marginBottom: '3px'
                                                    
                                                }}>Data Vencimento:</label>
                                                <label>{item.data_vencimento}</label>
                                            </div>

                                            <div style={{
                                                width: '12%',
                                                verticalAlign: 'middle',
                                                display: 'inline-block',
                                                fontFamily: 'Montserrat',
                                                textAlign: 'left'
                                            }}>
                                                <label style={{
                                                    display: 'block',
                                                    fontSize: '9pt',
                                                    marginBottom: '3px'
                                                    
                                                }}>Data Pagamento:</label>
                                                <label>{item.data_pagamento}</label>
                                            </div>

                                            <div style={{
                                                width: '30%',
                                                verticalAlign: 'middle',
                                                display: 'inline-block',
                                                fontFamily: 'Montserrat',
                                                textAlign: 'left'
                                            }}>
                                                <label style={{
                                                    display: 'block',
                                                    fontSize: '9pt',
                                                    marginBottom: '3px'
                                                    
                                                }}>Cliente:</label>
                                                <label>{item.cliente_nome}</label>
                                            </div>

                                            <div style={{
                                                width: '15%',
                                                verticalAlign: 'middle',
                                                display: 'inline-block',
                                                fontFamily: 'Montserrat',
                                                textAlign: 'left'
                                            }}>
                                                <label style={{
                                                    display: 'block',
                                                    fontSize: '9pt',
                                                    marginBottom: '3px'
                                                    
                                                }}>Valor:</label>
                                                <label><strong>{item.valor_original.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</strong></label>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    :
                    ''
                }
                <div style={{
                    display: 'inline-block',
                    textAlign: 'center',
                    width: '100%',
                    marginTop: '20px',
                    fontSize: '12pt'
                }}>
            { Ignorar != true ?
                InserirAlterar == 'Alterar'?
                <>
                
                    {
                        Pago?
                        <button style={{
                            width: '300px',
                            marginLeft: '10px'
                        }} className="button-adm-default" onClick={estornar}>
                            estornar
                        </button>
                        :
                        <button style={{
                            width: '300px'
                        }} className="button-adm-default" onClick={Pagar}>
                            pagar
                        </button>
                    }
                <button style={{
                    width: '300px',
                    marginLeft: '10px'
                }} className="button-adm-default" onClick={excluir}>
                    excluir
                </button>
                </>
                :
                <button style={{
                    width: '300px'
                }} className="button-adm-default" onClick={Inserir}>
                    salvar
                </button>

                :
                <p>OPERAÇÃO IGNORADA</p>
            }
            </div>
        </TelaADM>
        {
            Load ?
            <LoadDiv/>
            :
            ''
        }

        

        </>
    )
}