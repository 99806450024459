import React, { useEffect, useState } from "react"
import { Connection } from "../../../conn/connection";
import { Save } from "../../../component/save/save";
import { TelaADM } from "../../../component/TelaADM";
import { EntryInput } from "../../../component/input/input";
import { LoadDiv } from "../../../component/Load";
import './style.css'
import InputMask from 'react-input-mask';
import moment from 'moment';

export const InicialRecebimento = () => {
    const [Focus, setFocus] = useState('hoje')
    const [Filtros, setFiltros] = useState(false)
    const [Load, setLoad] = useState(false)
    const [ShowLote, setShowLote] = useState(false)

    const [ListClientes, setListClientes] = useState([])
    const [ListPlanoDeContas, setListPlanoDeContas] = useState([])
    const [ListContas, setListContas] = useState([])

    const [Cliente, setCliente] = useState('')
    const [ContaPagamento, setContaPagamento] = useState('')
    const [PlanoConta, setPlanoConta] = useState('')
    const [Pago, setPago] = useState('')
    const [DtEmissao, setDtEmissao] = useState('')
    const [PagoSistema, setPagoSistema] = useState('')
    

    const [DtInicial, setDtInicial] = useState()
    const [DtFinal, setDtFinal] = useState()

    const [ListRecebimetos, setListRecebimentos] = useState([])

    const [QTDItens, setQTDItens] = useState(0)
    const [TotalSelecionado, setTotalSelecionado] = useState(0)
    const [TotalSelecionadoPendente, setTotalSelecionadoPendente] = useState(0)
    const [PossivelLote, setPossivelLote] = useState(false)
    const [Lucro, setLucro] = useState(0)
    const [NSU, setNSU] = useState('')


    const [JurosLote, setJurosLote] = useState(0)
    const [DescontoLote, setDescontoLote] = useState(0)
    const [VlPagoLote, setVlPagoLote] = useState()
    const [DtPagamentoLote, setDtPagamentoLote] = useState('')
    const [ContaPagamentoLote, setContaPagamentoLote] = useState('')
    const [PlanoContaLote, setPlanoContaLote] = useState('')
    const [Id, setId] = useState()
    const [PagoLote, setPagoLote] = useState(false)

    const save = new Save()
    const Conn = new Connection()

    const Selecionado = (_item) => {
        let qtd = 0
        let qtd_pendente = 0
        let total = 0
        let total_pendente = 0
        let lucro = 0

        ListRecebimetos.map((item_) => {
            let item
            
            if (item_.id == _item.id){
                
                item = _item
                item.select = !item.select
            } else {
                item = item_
            }
            if (item.select){
                qtd += 1
                if (!item.pago){
                    qtd_pendente += 1
                    total_pendente += item.valor_original
                    if (item.lucro){
                        lucro += item.lucro
                    }
                    
                }
                total += item.valor_original
            }
        })
        setTotalSelecionado(total)
        setTotalSelecionadoPendente(total_pendente)
        setQTDItens(qtd)
        setPossivelLote(qtd_pendente > 1)
        setLucro(lucro)
    }

    const PagarEmLoteSave = async() => {
        if (PossivelLote){
            const token = save.getTokenAdmin()

            if (JurosLote == '' & JurosLote < 0){
                
                alert('Verique o campo Juros')
            }
            else if (DescontoLote == '' & DescontoLote < 0){
                
                alert('Verique o campo Desconto')
            }
            else if (DtPagamentoLote == ''){
                alert('Verique o campo Data Pagamento')
            }
            else if (ContaPagamentoLote == ''){
                alert('Verique o campo Conta de Pagamento')
            }
            else if (PlanoContaLote == ''){
                alert('Verique o campo Plano de Contas')
            }
            else {
                setLoad(true)
                setShowLote(false)
                let list = []
                ListRecebimetos.map((item) => {
                    if (item.select && !item.pago){
                        list.push(String(item.id))
                    }
                })

                let resp = await Conn.Ecommerc('intranet/financeiro/recebimentos/noticiar', {
                    'token': token,
                    'recebimentos': list,
                    'data_pagamento': DtPagamentoLote,
                    'juros': String(JurosLote),
                    'desconto': String(DescontoLote),
                    'conta': ContaPagamentoLote,
                    'plano': PlanoContaLote,
                    'id_sist': false,
                    'lucro': String(Lucro),
                    'nsu': NSU
                })
                
                if (resp){
                    
                    if (resp.status == '0'){
                        alert('Sucesso!')
                        window.location.href = '/intranet/admin/recebimentos'
                    }
                }
                setLoad(false)
            }
        } else {
            alert('Não é possível')
        }
    }

    const Hoje = async() => {
        setLoad(true)
        const token = save.getTokenAdmin()
        if (true){
            let resp = await Conn.Ecommerc('intranet/financeiro/recebimentos/pesquisar', {
                'token': token,
                'data_vencimento_inicial': moment().format('DD/MM/YYYY'),
                'data_vencimento_final': moment().format('DD/MM/YYYY'),
            })
            setDtInicial(moment().format('DD/MM/YYYY'))
            setDtFinal(moment().format('DD/MM/YYYY'))
            if (resp){
                if (resp.status == '0'){
                    setListRecebimentos(resp.dados.list)
                }
            }
        }
        setLoad(false)
    }

    const Vencidos = async() => {
        setLoad(true)
        const token = save.getTokenAdmin()
        if (true){
            let resp = await Conn.Ecommerc('intranet/financeiro/recebimentos/pesquisar', {
                'token': token,
                'pago': false,
                'data_vencimento_final': moment().format('DD/MM/YYYY')
            })
            if (resp){
                if (resp.status == '0'){
                    setListRecebimentos(resp.dados.list)
                }
            }
        }
        setLoad(false)
    }

    const PesquisarPeriodo = async() => {
        setLoad(true)
        const token = save.getTokenAdmin()
        if (true){
            let resp = await Conn.Ecommerc('intranet/financeiro/recebimentos/pesquisar', {
                'token': token,
                'data_vencimento_inicial': DtInicial,
                'data_vencimento_final': DtFinal
            })
      
            if (resp){
                if (resp.status == '0'){
                    setListRecebimentos(resp.dados.list)
                }
            }
        }
        setLoad(false)
    }

    const PesquisarFiltros = async() => {
        setLoad(true)
        const token = save.getTokenAdmin()
        if (true){
            let json = {
                'token': token,
            }
            if (DtEmissao != ''){
                json['data_emissao'] = DtEmissao
            }
            if (Cliente != ''){
                json['cliente'] = String(Cliente)
            }
            if (Pago != '') {
                json['pago'] = Pago == 'S'
            }
            if (PlanoConta != ''){
                json['plano'] = PlanoConta
            }
            if (ContaPagamento != ''){
                json['conta_pagamento'] = ContaPagamento
            }
            if (PagoSistema != ''){
                json['pago_sitema'] = PagoSistema
            }
            if (Focus == 'outro'){
                json['data_vencimento_inicial'] = DtInicial
                json['data_vencimento_final'] = DtFinal
            }
            let resp = await Conn.Ecommerc('intranet/financeiro/recebimentos/pesquisar', json)
            if (resp){
                if (resp.status == '0'){
                    setListRecebimentos(resp.dados.list)
                }
            }
        }
        setLoad(false)
    }

    const LoadInicial = async() => {
        

        const token = save.getTokenAdmin()


        let resp = await Conn.Ecommerc('intranet/financeiro/cliente/pesquisar', {
            'token': token,
        })
        if (resp){
            if (resp.status == '0'){
                setListClientes(resp.dados.lista_cliente)
            }
        }

        resp = await Conn.Ecommerc('intranet/financeiro/plano_contas/pesquisar', {
            'token': token,
            'pag': false
        })
        if (resp){
            if (resp.status == '0'){
                setListPlanoDeContas(resp.dados.lista_planos)
            }
        }

        resp = await Conn.Ecommerc('intranet/financeiro/conta/pesquisar', {
            'token': token,
        })
        if (resp){
            if (resp.status == '0'){
                setListContas(resp.dados.lista_bancos)
            }
        }

        if (Focus == 'hoje'){
            resp = await Conn.Ecommerc('intranet/financeiro/recebimentos/pesquisar', {
                'token': token,
                'data_vencimento_inicial': moment().format('DD/MM/YYYY'),
                'data_vencimento_final': moment().format('DD/MM/YYYY'),
            })
            if (resp){
                if (resp.status == '0'){
                    setListRecebimentos(resp.dados.list)
                }
            }
        }

        if (Focus == 'vencidos'){
            resp = await Conn.Ecommerc('intranet/financeiro/recebimentos/pesquisar', {
                'token': token,
                'pago': false,
                'data_vencimento_final': moment().format('DD/MM/YYYY'),
            })
            if (resp){
                if (resp.status == '0'){
                    setListRecebimentos(resp.dados.list)
                }
            }
        }
        
    }

    useEffect(() => {LoadInicial()}, [])

    return (
        <>
        <TelaADM title='RECEBIMENTOS'>
            <button className="button-adm-default" style={{width: '100%', marginBottom: '10px'}} onClick={() => window.location.href = '/intranet/admin/recebimentos/into/recb'}>
                +
            </button>
            <div style={{
                width: '100%',
                textAlign: 'center',
                fontFamily: '"Montserrat"'
            }}>
                <div style={{
                    width: '150px',
                    display: 'inline-block'
                }}>
                    <label style={
                        Focus == 'hoje' ?
                        {
                            fontWeight: '600',
                            color: 'blue',
                            textDecoration: 'underline',
                            cursor: 'pointer'
                        }
                        :
                        {
                            cursor: 'pointer'
                        }
                    } onClick={() => {setFocus('hoje'); Hoje()}}>Hoje</label>
                </div>

                <div style={{
                    width: '150px',
                    display: 'inline-block'
                }}>
                    <label style={
                        Focus == 'vencidos' ?
                        {
                            fontWeight: '600',
                            color: 'blue',
                            textDecoration: 'underline',
                            cursor: 'pointer'
                        }
                        :
                        {
                            cursor: 'pointer'
                        }
                    } onClick={() => {setFocus('vencidos'); Vencidos()}}>Vencidos</label>
                </div>

                <div style={{
                    width: '150px',
                    display: 'inline-block'
                }}>
                    <label style={
                        Focus == 'outro' ?
                        {
                            fontWeight: '600',
                            color: 'blue',
                            textDecoration: 'underline',
                            cursor: 'pointer'
                        }
                        :
                        {
                            cursor: 'pointer'
                        }
                    } onClick={() => setFocus('outro')}>Outro Período</label>
                </div>
            </div>
            {
                Focus == 'outro' ?
                    <div>
                            <div style={{
                                padding: '10px',
                                boxSizing: 'border-box',
                                border: 'rgb(216, 216, 216) 1px solid',
                                borderRadius: '7px',
                                width: '100%',
                                display: 'inline-block',
                                marginTop: '10px',
                                textAlign: 'center',
                                
                            }}>
                                <div className="entry-div-default" style={{
                                width: '15%',
                                }}>
                                    <label>Dt. Inicial Vencimento:</label>
                                    <InputMask value={DtInicial} onChange={(event) => setDtInicial(event.target.value)} mask='99/99/9999' placeholder="Data" />
                                </div>
                                <div className="entry-div-default" style={{
                                width: '15%',
                                }}>
                                    <label>Dt. Final Vencimento:</label>
                                    <InputMask value={DtFinal} onChange={(event) => setDtFinal(event.target.value)} mask='99/99/9999' placeholder="Data" />
                                </div>
                                <button className="button-adm-default" onClick={PesquisarPeriodo}>
                                    pesquisar
                                </button>
                            </div>
                        </div>
                        :
                    ''
            }
            <div>
                <p style={{textAlign: 'center', fontFamily: '"Montserrat"', fontSize: '10pt', cursor: 'pointer', marginBottom: '3px'}}
                onClick={() => setFiltros(!Filtros)}>{Filtros?'Ocultar Filtros': 'Ver Filtros'}</p>
            
                <div style={{
                    padding: '10px',
                    boxSizing: 'border-box',
                    border: 'rgb(216, 216, 216) 1px solid',
                    borderRadius: '7px',
                    width: '100%',
                    display: 'inline-block',
                    marginTop: '10px',
                    textAlign: 'center',
                    display: Filtros?'block': 'none'
                }}>
                    <div className="entry-div-default" style={{
                    width: '15%',
                    }}>
                        <label>Data de Emissão:</label>
                        <InputMask value={DtEmissao} onChange={(event) => setDtEmissao(event.target.value)} mask='99/99/9999' placeholder="Data de Emissão" />
                    </div>
                    <div className="entry-div-default" style={{
                    width: '25%',
                    }}>
                        <label>Cliente:</label>
                        <select value={Cliente} onChange={(event) => setCliente(event.target.value)}>
                            <option value=''>Selecione</option>
                            {
                            ListClientes.map((item) => (
                                <option value={item.id}>{item.razao_social}</option>
                            ))
                        }
                        </select>
                    </div>

                    <div className="entry-div-default" style={{
                    width: '15%',
                    }}>
                        <label>Pago:</label>
                        <select value={Pago} onChange={(event) => setPago(event.target.value)}>
                            <option value=''>Selecione</option>
                            <option value='S'>Pago</option>
                            <option value='N'>Pendente</option>
                        </select>
                    </div>

                    <div className="entry-div-default" style={{
                    width: '25%',
                    }}>
                        <label>Plano de Contas:</label>
                        <select value={PlanoConta} onChange={(event) => setPlanoConta(event.target.value)} >
                            <option value=''>Selecione</option>
                            {
                                ListPlanoDeContas.map((item) => (
                                    <option value={item.id}>{item.titulo}</option>
                                ))
                            }
                        </select>
                    </div>
                    <br/>
                    <div className="entry-div-default" style={{
                    width: '25%',
                    }}>
                        <label>Conta:</label>
                        <select value={ContaPagamento} onChange={(event) => setContaPagamento(event.target.value)}>
                            <option value=''>Selecione</option>
                            {
                                ListContas.map((item) => (
                                    <option value={item.id}>{item.banco} {`(${item.conta})`}</option>
                                ))
                            }
                        </select>
                    </div>

                    <div className="entry-div-default" style={{
                    width: '40%',
                    }}>
                        <label>Pago Sistema:</label>
                        <select value={PagoSistema} onChange={(event) => setPagoSistema(event.target.value)}>
                            <option value=''>Selecione</option>
                            <option value='S'>Pago</option>
                            <option value='N'>Pendente</option>
                        </select>
                    </div>
                    <br/>
                    <button className="button-adm-default" style={{width: '400px'}} onClick={PesquisarFiltros}>
                        aplicar filtros
                    </button>
                </div>

                
               
            </div>

            <div style={{
                padding: '10px',
                boxSizing: 'border-box',
                border: 'rgb(216, 216, 216) 1px solid',
                borderRadius: '7px',
                width: '100%',
                display: 'inline-block',
                marginTop: '10px',
                textAlign: 'center',
                
            }}>
                <div style={{
                    width: '12%',
                    verticalAlign: 'middle',
                    display: 'inline-block',
                    fontFamily: 'Montserrat',
                    textAlign: 'center'
                }}>
                    <label style={{
                        display: 'block',
                        fontSize: '9pt',
                        marginBottom: '3px'
                        
                    }}>SELECIONADOS</label>
                    <label><strong>{QTDItens} itens</strong></label>
                </div>
                <div style={{
                    width: '12%',
                    verticalAlign: 'middle',
                    display: 'inline-block',
                    fontFamily: 'Montserrat',
                    textAlign: 'center'
                }}>
                    <label style={{
                        display: 'block',
                        fontSize: '9pt',
                        marginBottom: '3px'
                        
                    }}>Total Selecionado</label>
                    <label><strong>{TotalSelecionado.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</strong></label>
                </div>

                <div style={{
                    width: '12%',
                    verticalAlign: 'middle',
                    display: 'inline-block',
                    fontFamily: 'Montserrat',
                    textAlign: 'center'
                }}>
                    <label style={{
                        display: 'block',
                        fontSize: '9pt',
                        marginBottom: '3px'
                        
                    }}>Total Selecionado Pendente</label>
                    <label><strong>{TotalSelecionadoPendente.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</strong></label>
                </div>
                <br/>
                <button style={{
                    fontFamily: 'Montserrat',
                    cursor: 'pointer',
                    margin: '5px'
                }} disabled={!PossivelLote} onClick={() => {
                    if (PossivelLote){
                        setShowLote(true)
                    }
                }}>
                    PAGAR EM LOTE
                </button>
                
            </div>
            <div style={{
                padding: '10px',
                boxSizing: 'border-box',
                
                borderRadius: '7px',
                width: '100%',
                display: 'inline-block',
                marginTop: '10px',
                textAlign: 'center',
                
            }}>
                {
                    ListRecebimetos.map((item, index) => {
                        return (
                            <>
                                <div style={{
                                border: '1px solid rgb(216, 216, 216)',
                                borderRadius: '7px',
                                boxSizing: 'border-box',
                                padding: '8px',
                                textAlign: "center",
                                background: item.pago? 'rgba(185, 185, 185, 0.603)' : 'rgba(70, 247, 70, 0.603)',
                                marginBottom: '10px'
                            }} className="item-mov-adm" >
                                <div style={{
                                        width: '5%',
                                        verticalAlign: 'middle',
                                        display: 'inline-block'
                                    }}>
                                    <input type="checkbox" value={item.select} onClick={(event) => {
                                        let _item = ListRecebimetos
                                        _item[index]["select"] = !event.target.checked
                                        setListRecebimentos([..._item])
                                        Selecionado(_item[index])
                                        }}/>
                                </div>
                                <div style={{
                                        width: '10%',
                                        verticalAlign: 'middle',
                                        display: 'inline-block'
                                    }}>
                                        <div style={{
                                        borderRadius: '15px',
                                        boxSizing: 'border-box',
                                        padding: '8px',
                                        backgroundColor: 'blue',
                                        width: '70px', 
                                        color: 'white',
                                        fontWeight: '600',
                                        fontFamily: 'Montserrat',
                                        fontSize: '10pt'
                                        }} onClick={() => {window.location.href = `/intranet/admin/recebimentos/into/recb?cod=${item.id}`}}>
                                            <label>id: {item.id}</label>
                                        </div>
                                    </div>
                                    <div style={{
                                        width: '12%',
                                        verticalAlign: 'middle',
                                        display: 'inline-block',
                                        fontFamily: 'Montserrat',
                                        textAlign: 'left'
                                    }}>
                                        <label style={{
                                            display: 'block',
                                            fontSize: '9pt',
                                            marginBottom: '3px'
                                            
                                        }}>Data Emissão:</label>
                                        <label>{item.data_emissao}</label>
                                    </div>

                                    <div style={{
                                        width: '12%',
                                        verticalAlign: 'middle',
                                        display: 'inline-block',
                                        fontFamily: 'Montserrat',
                                        textAlign: 'left'
                                    }}>
                                        <label style={{
                                            display: 'block',
                                            fontSize: '9pt',
                                            marginBottom: '3px'
                                            
                                        }}>Data Vencimento:</label>
                                        <label>{item.data_vencimento}</label>
                                    </div>

                                    <div style={{
                                        width: '12%',
                                        verticalAlign: 'middle',
                                        display: 'inline-block',
                                        fontFamily: 'Montserrat',
                                        textAlign: 'left'
                                    }}>
                                        <label style={{
                                            display: 'block',
                                            fontSize: '9pt',
                                            marginBottom: '3px'
                                            
                                        }}>Data Pagamento:</label>
                                        <label>{item.data_pagamento}</label>
                                    </div>

                                    <div style={{
                                        width: '30%',
                                        verticalAlign: 'middle',
                                        display: 'inline-block',
                                        fontFamily: 'Montserrat',
                                        textAlign: 'left'
                                    }}>
                                        <label style={{
                                            display: 'block',
                                            fontSize: '9pt',
                                            marginBottom: '3px'
                                            
                                        }}>Título:</label>
                                        <label>{item.titulo}</label>
                                    </div>

                                    <div style={{
                                        width: '15%',
                                        verticalAlign: 'middle',
                                        display: 'inline-block',
                                        fontFamily: 'Montserrat',
                                        textAlign: 'left'
                                    }}>
                                        <label style={{
                                            display: 'block',
                                            fontSize: '9pt',
                                            marginBottom: '3px'
                                            
                                        }}>Valor:</label>
                                        <label><strong>{item.valor_original.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</strong></label>
                                    </div>
                                    <br />
                                    <hr/>

                                    
                                    <div style={{
                                        width: '30%',
                                        verticalAlign: 'middle',
                                        display: 'inline-block',
                                        fontFamily: 'Montserrat',
                                        textAlign: 'left'
                                    }}>
                                        <label style={{
                                            display: 'block',
                                            fontSize: '9pt',
                                            marginBottom: '3px'
                                            
                                        }}>Descrição:</label>
                                        <label>{item.descricao}</label>
                                    </div>

                                    <div style={{
                                        width: '30%',
                                        verticalAlign: 'middle',
                                        display: 'inline-block',
                                        fontFamily: 'Montserrat',
                                        textAlign: 'left'
                                    }}>
                                        <label style={{
                                            display: 'block',
                                            fontSize: '9pt',
                                            marginBottom: '3px'
                                            
                                        }}>Cliente:</label>
                                        <label>{item.cliente_nome}</label>
                                    </div>

                                    <div style={{
                                        width: '10%',
                                        verticalAlign: 'middle',
                                        display: 'inline-block',
                                        fontFamily: 'Montserrat',
                                        textAlign: 'left'
                                    }}>
                                        <label style={{
                                            display: 'block',
                                            fontSize: '9pt',
                                            marginBottom: '3px'
                                            
                                        }}>Pago Mega:</label>
                                        <label>{item.pago_sitema ? 'SIM' : 'NÃO'}</label>
                                    </div>
                                    <div style={{
                                        width: '15%',
                                        verticalAlign: 'middle',
                                        display: 'inline-block',
                                        fontFamily: 'Montserrat',
                                        textAlign: 'left'
                                    }}>
                                        <label style={{
                                            display: 'block',
                                            fontSize: '9pt',
                                            marginBottom: '3px'
                                            
                                        }}>Lucro:</label>
                                        <label><strong>{item.lucro.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</strong></label>
                                    </div>

                            </div>
                            </>
                        )
                    })
                }
                
            </div>
        </TelaADM>
        {
            Load ?
            <LoadDiv/>
            :
            ''
        }

        {
            ShowLote ?

                <div className="popup">
                    <div className="popup-inner-load" style={{
                        textAlign: 'center'
                    }}>
                       <div style={{
                            backgroundColor: 'white',
                            borderRadius: '10px',
                            width: '800px',
                            fontFamily: 'Montserrat',
                            borderRadius: '7px',
                            boxSizing: 'border-box',
                            padding: '8px',
                       }}>
                            <div>
                                <label>PAGAMENTO EM LOTE</label>
                            </div>
                            <div style={{
                                border: '1px solid rgb(216, 216, 216)',
                                borderRadius: '7px',
                                boxSizing: 'border-box',
                                padding: '8px',
                                
                                height: '200px',
                                maxHeight: '200px',
                                overflowY: "auto",
                                margin: '10px'
                            }}  >
                                {
                                    ListRecebimetos.map((item) => {
                                        if (!item.pago & item.select){
                                            return (
                                                <div style={{
                                                    border: '1px solid rgb(216, 216, 216)',
                                                    borderRadius: '7px',
                                                    boxSizing: 'border-box',
                                                    padding: '8px',
                                                    textAlign: "center",
                                                    backgroundColor: "rgb(216, 216, 216)",
                                                    margin: '5px'
                                                }}>
                                                    <div style={{
                                                        width: '10%',
                                                        verticalAlign: 'middle',
                                                        display: 'inline-block',
                                                        fontFamily: 'Montserrat',
                                                        textAlign: 'left'
                                                    }}>
                                                        <label style={{
                                                            display: 'block',
                                                            fontSize: '9pt',
                                                            marginBottom: '3px'
                                                            
                                                        }}>ID:</label>
                                                        <label>{item.id}</label>
                                                    </div>
                                                    <div style={{
                                                        width: '30%',
                                                        verticalAlign: 'middle',
                                                        display: 'inline-block',
                                                        fontFamily: 'Montserrat',
                                                        textAlign: 'left'
                                                    }}>
                                                        <label style={{
                                                            display: 'block',
                                                            fontSize: '9pt',
                                                            marginBottom: '3px'
                                                            
                                                        }}>Título:</label>
                                                        <label>{item.titulo}</label>
                                                    </div>
                                                    <div style={{
                                                        width: '30%',
                                                        verticalAlign: 'middle',
                                                        display: 'inline-block',
                                                        fontFamily: 'Montserrat',
                                                        textAlign: 'left'
                                                    }}>
                                                        <label style={{
                                                            display: 'block',
                                                            fontSize: '9pt',
                                                            marginBottom: '3px'
                                                            
                                                        }}>Cliente:</label>
                                                        <label>{item.cliente_nome}</label>
                                                    </div>
                                                    <div style={{
                                                        width: '10%',
                                                        verticalAlign: 'middle',
                                                        display: 'inline-block',
                                                        fontFamily: 'Montserrat',
                                                        textAlign: 'left'
                                                    }}>
                                                        <label style={{
                                                            display: 'block',
                                                            fontSize: '9pt',
                                                            marginBottom: '3px'
                                                            
                                                        }}>Valor:</label>
                                                        <label>{item.valor_original.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</label>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        
                                    })
                                }
                            </div>
                            <div className="entry-div-default" style={{
                            width: '15%',
                            }}>
                                <label>Valor Total:</label>
                                <input placeholder="Valor" value={TotalSelecionadoPendente.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} disabled/>
                            </div>
                            <div className="entry-div-default" style={{
                            width: '15%',
                            
                        
                            }}>
                                <label>Juros:</label>
                                <input type="number" value={JurosLote} onBlur={(event) => {if(!event.target.value){setJurosLote(0)}}} placeholder="Juros" onChange={(event) => setJurosLote(event.target.value)}/>
                            </div>
                            <div className="entry-div-default" style={{
                            width: '15%',
                            
                        
                            }}>
                                <label>Descontos:</label>
                                <input type="number" onBlur={(event) => {if(!event.target.value){setDescontoLote(0)}}} value={DescontoLote} onChange={(event) => setDescontoLote(event.target.value)} placeholder="Desconto"/>
                            </div>
                            <div  className="entry-div-default" style={{
                            width: '15%',
                            
                        
                            }}>
                                <label>Valor Pago:</label>
                                <input placeholder="Valor Pago" value={(parseFloat(TotalSelecionadoPendente)-parseFloat(DescontoLote)+ parseFloat(JurosLote)).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} disabled/>
                            </div>
                            <div className="entry-div-default" style={{
                            width: '15%',
                            
                        
                            }}>
                                <label>Data Pagamento:</label>
                                <InputMask placeholder="Data Pagamento" value={DtPagamentoLote} onChange={(event) => setDtPagamentoLote(event.target.value)} mask='99/99/9999' />
                            </div>
                            <div  className="entry-div-default" style={{
                            width: '15%',
                            
                        
                            }}>
                                <label>Lucro:</label>
                                <input placeholder="Valor Pago" value={Lucro} onChange={(event) => setLucro(event.target.value)}  disabled={Pago}/>
                            </div>
                            <br/>
                            <div className="entry-div-default" style={{
                            width: '33%',
                            
                        
                            }}>
                                <label>Conta de Pagamento:</label>
                                <select value={ContaPagamentoLote} onChange={(event) => setContaPagamentoLote(event.target.value)} >
                                <option value=''>Selecione</option>
                                    {
                                        ListContas.map((item) => (
                                            <option value={item.id}>{item.banco} {`(${item.conta})`}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="entry-div-default" style={{
                            width: '33%',
                            
                        
                            }}>
                                <label>Plano de Contas:</label>
                                <select value={PlanoContaLote} onChange={(event) => setPlanoContaLote(event.target.value)} >
                                <option value=''>Selecione</option>
                                    {
                                        ListPlanoDeContas.map((item) => (
                                            <option value={item.id}>{item.titulo}</option>
                                        ))
                                    }
                                </select>
                            </div>

                            <div  className="entry-div-default" style={{
                                width: '15%',
                                
                            
                                }}>
                                <label>NSU:</label>
                                <input placeholder="Digite o NSU" type='number' value={NSU} onChange={(event) => setNSU(event.target.value)}  disabled={Pago}/>
                            </div>

                            <button style={{
                                width: '300px'
                            }} className="button-adm-default" onClick={PagarEmLoteSave}>
                                pagar
                            </button>
                            <button style={{
                                width: '300px'
                            }} className="button-adm-default" onClick={() => setShowLote(false)}>
                                cancelar
                            </button>
                       </div>
                    </div>
                </div>
            :
            ''
        }
        </>
    )
}