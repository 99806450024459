import React, { useEffect, useState } from "react";
import './style.css'
import LogoMercadoUnicao from '../../imgs/logo.png'
import { Connection } from "../../conn/connection";
import { Save } from "../../component/save/save";
import { useNavigate } from "react-router-dom";

export const MeusPedidos = () => {
    const [ Pedidos, setPedidos ] = useState([
        
    ])
    const conn = new Connection()

    let carregando = false
    const LoadInicial = async () => {
        if (!carregando){
            carregando = true
            const conn = new Connection()
            let save = new Save()
            let token = save.getToken()
            let resp = await conn.Ecommerc('pedido/pesquisar', {
                'token': token,
                'tipo': 'todos'
            })
            
            
            if (resp){
                if (resp.status == '0'){
                    setPedidos(resp.pedidos)
                }
            }
        }
    }
    
    useEffect( () => {LoadInicial()}, [])
    const Navegador = useNavigate()
    
    return (
        <>
        <div id='div-root-meus-pedidos'>
            <div id='div-barra-login'>
                <img  src={LogoMercadoUnicao} onClick={()=> {Navegador('/')}}/>
            </div>
            <div id='div-body-meus-pedidos'>
                <div id='div-body-meus-pedidos-children'>
                    <div>
                        <TituloSetor titulo='Meus Pedidos' id='id-titulo-setor-oferta-semana'/>
                    </div>
                    <div id='list-pedidos-meus'>
                        {
                            Pedidos.map((item, index) => (
                                <div className="item-pedido">
                                    <div className="div-dados-sup-pedidos">
                                        <div className="div-top-pedido-dados">
                                            <p className="div-top-pedido-dados-titulo">Pedido Nº</p>
                                            <p>{item.dados.id}</p>
                                        </div>
                                        <div className="div-top-pedido-dados">
                                            <p className="div-top-pedido-dados-titulo">Valor Pedido</p>
                                            <p>{item.dados.valor_produtos.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
                                        </div>
                                        <div className="div-top-pedido-dados">
                                            <p className="div-top-pedido-dados-titulo">Valor Frete</p>
                                            <p>{item.dados.frete.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
                                        </div>
                                        <div className="div-top-pedido-dados">
                                            <p className="div-top-pedido-dados-titulo">Valor Total</p>
                                            <p>{item.dados.valor_final.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
                                        </div>
                                    </div>
                                    <div className="status-pedido" style={{
                                        
                                    }}>
                                        <p>{item.dados.status}</p>
                                        <div style={{
                                            'backgroundColor': item.dados.status == 'Aguardando' ? 'yellow' : item.dados.status == 'Concluído, esperando entrega...' ? 'green' : item.dados.status == 'Entregue' ? 'black' : 'red'
                                        }}></div>
                                    </div>
                                    <div className="div-list-produtos">
                                        {
                                            item.produtos.map((produto, index_produto) => {
                                                return (
                                                    <div>
                                                        <div className='div-img-produto-list-pedido'>
                                                            <img className='img-produto-list-pedido' src={conn.URLImagemProduto(produto.codigo? produto.codigo : 0)}/>
                                                        </div>
                                                        <div className="div-dados-produto-list-pedido">
                                                            <p className="div-dados-produto-list-pedido-titulo">{produto.descricao}</p>
                                                            <div className="div-dados-produto-list-pedido-dados-valores">
                                                                <p className="div-dados-produto-list-pedido-dados-valores-sup">Pr. Unitário</p>
                                                                <p>{produto.preco.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
                                                            </div>
                                                            <div className="div-dados-produto-list-pedido-dados-valores">
                                                                <p className="div-dados-produto-list-pedido-dados-valores-sup">Quantidade</p>
                                                                <p>{produto.qtd_real}</p>
                                                            </div>
                                                            <div className="div-dados-produto-list-pedido-dados-valores">
                                                                <p className="div-dados-produto-list-pedido-dados-valores-sup">Pr. Total</p>
                                                                <p>{produto.preco_final.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className="div-contato-whats">
                                        <p>Qualquer dúvida fale conosco pelo WhatsApp <a href='www.teste.com'>clicando aqui.</a></p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
        
        </>
    )
}

const TituloSetor = (props) => {
    return (
        <>
            <div id={props.id} className="div-inicial-titulo-setor">
                <div className="div-inicial-titulo-barr">

                </div>
                <div className="div-inicial-titulo-texto">
                    <h1>{props.titulo}</h1>
                </div>
            </div>
        </>
    )
}