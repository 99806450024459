import React, { useEffect, useState } from "react";
import './style.css'

export const DivProdutos = (props) => {
    const [ NenhumClick, setNenhumClick ] = useState(true)
    const [ NenhumClickCat, setNenhumClickCat ] = useState(true)
    const [ NenhumClickSub, setNenhumClickSub ] = useState(true)
    const [ DivProdutosRef, setDivProdutosRef ] = useState()
    
    const onChangeSetores = () => {
        if (props.onChangeSetores){
            props.onChangeSetores()
        }
    }
    const ReturnListSetoresAtivos = (setores) => {
        let setores_lista = []
        let categorias_lista = []
        let subcategorias_lista = []
        
        setores.map((item) => {
            if (NenhumClick){
                setores_lista.push(item.id)
            }
            else if (item.select){
                setores_lista.push(item.id)
            }
            item.categorias.map((_item) => {
                if (NenhumClickCat){
                    categorias_lista.push(_item.id)
                }
                else if (_item.select){
                    categorias_lista.push(_item.id)
                }
                _item.subcategorias.map((__item) => {
                    if (NenhumClickSub){
                        subcategorias_lista.push(__item.id)
                    }else if (__item.select){
                        subcategorias_lista.push(__item.id)
                    }
                })
            })
        })
        
        return {'setores': setores_lista, 'categorias': categorias_lista, "subcategorias": subcategorias_lista}
    }
    const toList = (lista) => {
        if (lista.length == 1){
            return [...lista, -1]
        }
        else {
            return lista
        }
    }

    const onScroll = () => {
        if (DivProdutosRef.offsetHeight + DivProdutosRef.scrollTop >= DivProdutosRef.scrollHeight) {
            if (props.fimPage){
                props.fimPage()

            }
          }
    }

    const [FiltrosVer, setFiltrosVer ] = useState(false)
   
    return (
        <>
            <div className="div-produtos-component-root" onScroll={onScroll} ref={setDivProdutosRef}>
                <div className="filtros-mostrar-mobile">
                    <button onClick={() => setFiltrosVer(!FiltrosVer)}>{FiltrosVer ? 'Ocultar Filtros' : 'Ver Filtros'}</button>
                </div>
                <div className={`div-options-setor-categorias ${FiltrosVer ? '' : 'div-options-setor-categorias-non'}`}>
                    <div className="div-options-setor-categorias-setor div-options-setor-categorias-all">
                        <p>Setores</p>
                        <div>
                            {
                                props.setores.map((item, index) => {
                                    
                                    return (
                                        <div>
                                            <input type="checkbox" checked={item.select} onClick={() => {
                                                let last_list = props.setores
                                                let item_new = item
                                                item_new['select'] = !item.select
                                                last_list[index] = item_new
                                                props.setSetores([...last_list])
                                                setNenhumClick(true)
                                                props.setores.map((_item) => {
                                                    if (_item.select){
                                                        setNenhumClick(false)
                                                    }
                                                })
                                                onChangeSetores()
                                            }} id={`setor-${item.id}`}/>
                                            <label for={`setor-${item.id}`}>{item.titulo}</label>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="div-options-setor-categorias-categoria div-options-setor-categorias-all">
                        <p>Categorias</p>
                        <div>
                            {
                                props.setores.map((item, index) => {
                                    return (
                                        <>
                                            {
                                                item.categorias.map((item2, index2) => {
                                                    return(
                                                    NenhumClick | item.select ?
                                                    <div>
                                                        <input type="checkbox" checked={item2.select} onClick={() => {
                                                            let last_list = props.setores
                                                            let item_new = item2
                                                            item_new['select'] = !item2.select
                                                            last_list[index].categorias[index2] = item_new
                                                            props.setSetores([...last_list])
                                                            setNenhumClickCat(true)
                                                            last_list[index].categorias.map((_item) => {
                                                                if (_item.select){
                                                                    setNenhumClickCat(false)
                                                                }
                                                            })
                                                            onChangeSetores()
                                            
                                                        }} id={`categoria-${item2.id}`}/>
                                                        <label for={`categoria-${item2.id}`}>{item2.titulo}</label>
                                                    </div>
                                                    :
                                                    ''
                                                    )
                                                })
                                            }
                                        </>
                                        
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="div-options-setor-categorias-subcategoria div-options-setor-categorias-all">
                        <p>Subcategorias</p>
                        <div>
                            {
                                props.setores.map((item, index) => {
                                    return (
                                        <>
                                            {
                                                item.categorias.map((item2, index2) => {
                                                    return(
                                                    NenhumClick | item.select ?
                                                    
                                                        item2.subcategorias.map((item3, index3) => {
                                                           
                                                            return (
                                                                NenhumClickCat | item2.select ?
                                                                <div>
                                                                    <input type="checkbox" checked={item3.select} onClick={() => {
                                                                        let last_list = props.setores
                                                                        let item_new = item3
                                                                        item_new['select'] = !item3.select
                                                                        last_list[index].categorias[index2].subcategorias[index3] = item_new
                                                                        props.setSetores([...last_list])
                                                                        setNenhumClickSub(true)
                                                                        last_list[index].categorias[index2].subcategorias.map((_item) => {
                                                                            if (_item.select){
                                                                                setNenhumClickSub(false)
                                                                            }
                                                                        })
                                                                        onChangeSetores()
                                                        
                                                                    }} id={`subcategoria-${item3.id}`}/>
                                                                    <label for={`subcategoria-${item3.id}`}>{item3.titulo}</label>
                                                                </div>
                                                                : 
                                                                ''
                                                            )
                                                        })
                                                    
                                                    
                                                    :
                                                    ''
                                                    )
                                                })
                                            }
                                        </>
                                        
                                    )
                                })
                            }
                        </div>
                    </div>
                    
                </div>
                
                <div className="div-produtos-lista-divprodutos">
                    <div className="div-produtos-lista-divprodutos-div" onScroll={() => alert('a')}>
                        {
                            props.children.map((item, index) => {
                                let show = false
                                let retorno = ReturnListSetoresAtivos(props.setores)
                                
                                if (item.props){
                                    console.log(item.props.dados)
                                if ( toList(retorno.setores).indexOf(parseInt(item.props.dados.setor)) >= 0 & toList(retorno.categorias).indexOf(parseInt(item.props.dados.categoria)) >= 0 & toList(retorno.subcategorias).indexOf(parseInt(item.props.dados.subcategoria)) >= 0){
                                    return item
                                }  
                               
                                }
                                
                            })
                        }
                        {
                            props.children.length == 0 ?
                            <p>Nenhum produto encontrado!</p>
                            : 
                            ''
                        }
                    </div>
                   
                </div>
                
            </div>
            
        </>
    )
}