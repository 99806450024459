import React, { useState }from "react";
import './style.css'

export const Carrossel = (props) => {
    const [ RefCarrossel, setRefCarrossel ] = useState()
    const direita = () => {
        RefCarrossel.scroll({top: 0, left: RefCarrossel.scrollLeft + parseFloat(props.children[0].props.width.replace('px', '')) + props.marginItem, behavior: 'smooth' })
    }
    const esquerda = () => {
        RefCarrossel.scroll({top: 0, left: RefCarrossel.scrollLeft - parseFloat(props.children[0].props.width.replace('px', '')) - props.marginItem, behavior: 'smooth' })
    }
    return (
        <>
            <div className="div-carrossel-contem">
                <div className="div-carrossel-div-cont-setas div-carrossel-div-seta-left" onClick={esquerda}>
                    
                        <p>{'<'}</p>
          
                </div>
                <div className="div-carrossel-div-total" ref={setRefCarrossel}>
                    <div className="div-carrossel-div-total-item" style={{
                        width: `${(props.children[0] ? parseFloat(props.children[0].props.width.replace('px', '')) + props.marginItem : 0) * (props.children ? props.children.length : 0)}px`
                    }}>
                        {props.children}
                    </div>
                </div>
                <div className="div-carrossel-div-cont-setas div-carrossel-div-seta-right" onClick={direita}>

                        <p>{'>'}</p>
           
                </div>
            </div>
        </>
    )
}