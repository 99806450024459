import React, { useEffect, useState } from "react"
import { Connection } from "../../../conn/connection";
import { Save } from "../../../component/save/save";
import { TelaADM } from "../../../component/TelaADM";
import BancoPNG from '../../../imgs/banco.png'

const ListContas = async(Conn) => {
    const save = new Save()
    const token = save.getTokenAdmin()
    let resp
    
    if (token){
       
        resp = await Conn.Ecommerc('intranet/financeiro/conta/pesquisar', {
            'token': token
        })
        if (resp){
            if (resp.status == '0'){
                return resp.dados.lista_bancos
            }
        }
    }
    
    
}

export const InicialContas = (props) => {
    
    const [Contas, setContas] = useState([])


    const Conn = new Connection()


    const LoadInicial = async () => {
        
        let contas = await ListContas(Conn)
        if (contas){
            setContas(contas)
        }
    }

    useEffect(() => {LoadInicial()}, [])
    return (
        <>
        <TelaADM title='CONTAS BANCÁRIAS'>
            {
                Contas.map((item, index) => (
                    <div style={{
                        border: '1px solid rgb(216, 216, 216)',
                        boxSizing: 'border-box',
                        padding: '5px',
                        borderRadius: '7px',
                        width: '300px',
                        fontFamily: '"Montserrat"',
                        cursor: 'pointer',
                        display: 'inline-block',
                        verticalAlign: 'top',
                        margin: '10px'
                    }} onClick={() => window.location.href = `/intranet/admin/movimentacoes?conta=${item.id}`}>
                        <div style={{
                            verticalAlign: 'top', display: 'inline-block',
                            marginRight: '5px'
                        }}>
                            <img src={item.img} style={{width: '70px', height: '70px', objectFit: 'contain', borderRadius: '100px'}}/>
                        </div>
                        <div style={{
                            display: 'inline-block'
                        }}>
                            <p style={{
                                marginBottom: '2px'
                            }}><strong>{item.banco}</strong></p>
                            <label style={{
                                fontSize: '10pt'
                            }}>Conta: {item.conta}</label>
                        </div>
                    </div>
                ))
            }
            <div style={{
                        border: '1px solid rgb(216, 216, 216)',
                        boxSizing: 'border-box',
                        padding: '5px',
                        borderRadius: '7px',
                        width: '300px',
                        fontFamily: '"Montserrat"',
                        cursor: 'pointer',
                        display: 'inline-block',
                        margin: '10px'
                    }} onClick={() => window.location.href = '/intranet/admin/inicial_contas/into/add'}>
                        <div style={{
                            verticalAlign: 'middle', display: 'inline-block',
                            marginRight: '5px'
                        }}>
                            <div style={{width: '70px', height: '70px', objectFit: 'contain', borderRadius: '100px', textAlign: 'center'}}>
                                <label style={{fontSize: '45pt', color: 'green'}}>+</label>
                            </div>
                            
                        </div>
                        <div style={{
                            display: 'inline-block'
                        }}>
                            <p style={{
                                marginBottom: '2px'
                            }}><strong>Adicionar Nova Conta</strong></p>
                            
                        </div>
                    </div>
        </TelaADM>
        </>
    )
}