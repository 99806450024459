import React from "react";
import './style.css'

export const TelaADM = (props) => {
    return (
        <>
        <div style={{
            width: '100%'
        }}>
            <div style={{
                width: '100%',
                boxSizing: 'border-box',
                padding: '10px',
                fontFamily: '"Montserrat"',
                fontWeight: '600',
                
            }}>
                <p>{props.title}</p>
            </div>
            <div style={{
                boxSizing: 'border-box',
                padding: '10px',
                width: '100%',
            }}>
                {props.children}
            </div>
        </div>
        </>
    )
}