import React, { useEffect, useState } from "react"
import { Connection } from "../../../conn/connection";
import { Save } from "../../../component/save/save";
import { TelaADM } from "../../../component/TelaADM";
import { EntryInput } from "../../../component/input/input";
import { LoadDiv } from "../../../component/Load";
import { useSearchParams } from "react-router-dom";
import InputMask from 'react-input-mask';
import moment from 'moment';

export const Pagamentos = () => {
    const [Focus, setFocus] = useState('hoje')
    const [Filtros, setFiltros] = useState(false)
    const [Load, setLoad] = useState(false)

    const [ListFornecedores, setListFornecedores] = useState([])
    const [ListPlanoDeContas, setListPlanoDeContas] = useState([])
    const [ListContas, setListContas] = useState([])

    const [Fornecedor, setFornecedor] = useState('')
    const [ContaPagamento, setContaPagamento] = useState('')
    const [PlanoConta, setPlanoConta] = useState('')
    const [Pago, setPago] = useState('')
    const [DtEmissao, setDtEmissao] = useState('')
    const [CodigoBarras, setCodigoBarras] = useState('')

    const [DtInicial, setDtInicial] = useState()
    const [DtFinal, setDtFinal] = useState()

    const [ListPagamentos, setLisPagamentos] = useState([])

    const save = new Save()
    const Conn = new Connection()

    const Hoje = async() => {
        setLoad(true)
        const token = save.getTokenAdmin()
        if (true){
            let resp = await Conn.Ecommerc('intranet/financeiro/pagamentos/pesquisar', {
                'token': token,
                'data_vencimento_inicial': moment().format('DD/MM/YYYY'),
                'data_vencimento_final': moment().format('DD/MM/YYYY'),
            })
            setDtInicial(moment().format('DD/MM/YYYY'))
            setDtFinal(moment().format('DD/MM/YYYY'))
            if (resp){
                if (resp.status == '0'){
                    setLisPagamentos(resp.dados.list)
                }
            }
        }
        setLoad(false)
    }

    const Vencidos = async() => {
        setLoad(true)
        const token = save.getTokenAdmin()
        if (true){
            let resp = await Conn.Ecommerc('intranet/financeiro/pagamentos/pesquisar', {
                'token': token,
                'pago': false,
                'data_vencimento_final': moment().format('DD/MM/YYYY')
            })
            if (resp){
                if (resp.status == '0'){
                    setLisPagamentos(resp.dados.list)
                }
            }
        }
        setLoad(false)
    }

    const PesquisarPeriodo = async() => {
        setLoad(true)
        const token = save.getTokenAdmin()
        if (true){
            let resp = await Conn.Ecommerc('intranet/financeiro/pagamentos/pesquisar', {
                'token': token,
                'data_vencimento_inicial': DtInicial,
                'data_vencimento_final': DtFinal
            })
            if (resp){
                if (resp.status == '0'){
                    setLisPagamentos(resp.dados.list)
                }
            }
        }
        setLoad(false)
    }

    const PesquisarFiltros = async() => {
        setLoad(true)
        const token = save.getTokenAdmin()
        if (true){
            let json = {
                'token': token,
            }
            if (DtEmissao != ''){
                json['data_emissao'] = DtEmissao
            }
            if (Fornecedor != ''){
                json['fornecedor'] = String(Fornecedor)
            }
            if (Pago != '') {
                json['pago'] = Pago == 'S'
            }
            if (PlanoConta != ''){
                json['plano'] = PlanoConta
            }
            if (ContaPagamento != ''){
                json['conta_pagamento'] = ContaPagamento
            }
            if (CodigoBarras != ''){
                json['codigo_barras'] = CodigoBarras
            }
            if (Focus == 'outro'){
                json['data_vencimento_inicial'] = DtInicial
                json['data_vencimento_final'] = DtFinal
            }
            let resp = await Conn.Ecommerc('intranet/financeiro/pagamentos/pesquisar', json)
            if (resp){
                if (resp.status == '0'){
                    setLisPagamentos(resp.dados.list)
                }
            }
        }
        setLoad(false)
    }

    const LoadInicial = async() => {
        

        const token = save.getTokenAdmin()


        let resp = await Conn.Ecommerc('intranet/financeiro/fornecedor/pesquisar', {
            'token': token,
        })
        if (resp){
            if (resp.status == '0'){
                setListFornecedores(resp.dados.lista_fornecedores)
            }
        }

        resp = await Conn.Ecommerc('intranet/financeiro/plano_contas/pesquisar', {
            'token': token,
            'pag': true
        })
        if (resp){
            if (resp.status == '0'){
                setListPlanoDeContas(resp.dados.lista_planos)
            }
        }

        resp = await Conn.Ecommerc('intranet/financeiro/conta/pesquisar', {
            'token': token,
        })
        if (resp){
            if (resp.status == '0'){
                setListContas(resp.dados.lista_bancos)
            }
        }

        if (Focus == 'hoje'){
            resp = await Conn.Ecommerc('intranet/financeiro/pagamentos/pesquisar', {
                'token': token,
                'data_vencimento_inicial': moment().format('DD/MM/YYYY'),
                'data_vencimento_final': moment().format('DD/MM/YYYY'),
            })
            if (resp){
                if (resp.status == '0'){
                    setLisPagamentos(resp.dados.list)
                }
            }
        }

        if (Focus == 'vencidos'){
            resp = await Conn.Ecommerc('intranet/financeiro/pagamentos/pesquisar', {
                'token': token,
                'pago': false,
                'data_vencimento_final': moment().format('DD/MM/YYYY'),
            })
            if (resp){
                if (resp.status == '0'){
                    setLisPagamentos(resp.dados.list)
                }
            }
        }
        
    }

    useEffect(() => {LoadInicial()}, [])

    return (
        <>
        <TelaADM title='PAGAMENTOS'>
            <button className="button-adm-default" style={{width: '100%', marginBottom: '10px'}} onClick={() => window.location.href = '/intranet/admin/pagamentos/into/pagto'}>
                +
            </button>
            <div style={{
                width: '100%',
                textAlign: 'center',
                fontFamily: '"Montserrat"'
            }}>
                <div style={{
                    width: '150px',
                    display: 'inline-block'
                }}>
                    <label style={
                        Focus == 'hoje' ?
                        {
                            fontWeight: '600',
                            color: 'blue',
                            textDecoration: 'underline',
                            cursor: 'pointer'
                        }
                        :
                        {
                            cursor: 'pointer'
                        }
                    } onClick={() => {setFocus('hoje'); Hoje()}}>Hoje</label>
                </div>

                <div style={{
                    width: '150px',
                    display: 'inline-block'
                }}>
                    <label style={
                        Focus == 'vencidos' ?
                        {
                            fontWeight: '600',
                            color: 'blue',
                            textDecoration: 'underline',
                            cursor: 'pointer'
                        }
                        :
                        {
                            cursor: 'pointer'
                        }
                    } onClick={() => {setFocus('vencidos'); Vencidos()}}>Vencidos</label>
                </div>

                <div style={{
                    width: '150px',
                    display: 'inline-block'
                }}>
                    <label style={
                        Focus == 'outro' ?
                        {
                            fontWeight: '600',
                            color: 'blue',
                            textDecoration: 'underline',
                            cursor: 'pointer'
                        }
                        :
                        {
                            cursor: 'pointer'
                        }
                    } onClick={() => setFocus('outro')}>Outro Período</label>
                </div>
            </div>
            {
                Focus == 'outro' ?
                    <div>
                            <div style={{
                                padding: '10px',
                                boxSizing: 'border-box',
                                border: 'rgb(216, 216, 216) 1px solid',
                                borderRadius: '7px',
                                width: '100%',
                                display: 'inline-block',
                                marginTop: '10px',
                                textAlign: 'center',
                                
                            }}>
                                <div className="entry-div-default" style={{
                                width: '15%',
                                }}>
                                    <label>Dt. Inicial Vencimento:</label>
                                    <InputMask value={DtInicial} onChange={(event) => setDtInicial(event.target.value)} mask='99/99/9999' placeholder="Data" />
                                </div>
                                <div className="entry-div-default" style={{
                                width: '15%',
                                }}>
                                    <label>Dt. Final Vencimento:</label>
                                    <InputMask value={DtFinal} onChange={(event) => setDtFinal(event.target.value)} mask='99/99/9999' placeholder="Data" />
                                </div>
                                <button className="button-adm-default" onClick={PesquisarPeriodo}>
                                    pesquisar
                                </button>
                            </div>
                        </div>
                        :
                    ''
            }
            <div>
                <p style={{textAlign: 'center', fontFamily: '"Montserrat"', fontSize: '10pt', cursor: 'pointer', marginBottom: '3px'}}
                onClick={() => setFiltros(!Filtros)}>{Filtros?'Ocultar Filtros': 'Ver Filtros'}</p>
            
                <div style={{
                    padding: '10px',
                    boxSizing: 'border-box',
                    border: 'rgb(216, 216, 216) 1px solid',
                    borderRadius: '7px',
                    width: '100%',
                    display: 'inline-block',
                    marginTop: '10px',
                    textAlign: 'center',
                    display: Filtros?'block': 'none'
                }}>
                    <div className="entry-div-default" style={{
                    width: '15%',
                    }}>
                        <label>Data de Emissão:</label>
                        <InputMask value={DtEmissao} onChange={(event) => setDtEmissao(event.target.value)} mask='99/99/9999' placeholder="Data de Emissão" />
                    </div>
                    <div className="entry-div-default" style={{
                    width: '25%',
                    }}>
                        <label>Fornecedor:</label>
                        <select value={Fornecedor} onChange={(event) => setFornecedor(event.target.value)}>
                            <option value=''>Selecione</option>
                            {
                            ListFornecedores.map((item) => (
                                <option value={item.id}>{item.razao_social}</option>
                            ))
                        }
                        </select>
                    </div>

                    <div className="entry-div-default" style={{
                    width: '15%',
                    }}>
                        <label>Pago:</label>
                        <select value={Pago} onChange={(event) => setPago(event.target.value)}>
                            <option value=''>Selecione</option>
                            <option value='S'>Pago</option>
                            <option value='N'>Pendente</option>
                        </select>
                    </div>

                    <div className="entry-div-default" style={{
                    width: '25%',
                    }}>
                        <label>Plano de Contas:</label>
                        <select value={PlanoConta} onChange={(event) => setPlanoConta(event.target.value)} >
                            <option value=''>Selecione</option>
                            {
                                ListPlanoDeContas.map((item) => (
                                    <option value={item.id}>{item.titulo}</option>
                                ))
                            }
                        </select>
                    </div>
                    <br/>
                    <div className="entry-div-default" style={{
                    width: '25%',
                    }}>
                        <label>Conta:</label>
                        <select value={ContaPagamento} onChange={(event) => setContaPagamento(event.target.value)}>
                            <option value=''>Selecione</option>
                            {
                                ListContas.map((item) => (
                                    <option value={item.id}>{item.banco} {`(${item.conta})`}</option>
                                ))
                            }
                        </select>
                    </div>

                    <div className="entry-div-default" style={{
                    width: '40%',
                    }}>
                        <label>Código de Barras:</label>
                        <input value={CodigoBarras} onChange={(event) => setCodigoBarras(event.target.value)}/>
                    </div>
                    <br/>
                    <button className="button-adm-default" style={{width: '400px'}} onClick={PesquisarFiltros}>
                        aplicar filtros
                    </button>
                </div>

                
               
            </div>
            <div style={{
                padding: '10px',
                boxSizing: 'border-box',
                
                borderRadius: '7px',
                width: '100%',
                display: 'inline-block',
                marginTop: '10px',
                textAlign: 'center',
                
            }}>
                {
                    ListPagamentos.map((item) => {
                        return (
                            <>
                                <div style={{
                                border: '1px solid rgb(216, 216, 216)',
                                borderRadius: '7px',
                                boxSizing: 'border-box',
                                padding: '8px',
                                textAlign: "center",
                                background: item.pago? 'rgba(185, 185, 185, 0.603)' : 'rgba(70, 247, 70, 0.603)',
                                marginBottom: '10px'
                            }} className="item-mov-adm" onClick={() => {window.location.href = `/intranet/admin/pagamentos/into/pagto?cod=${item.id}`}}>
                                <div style={{
                                        width: '10%',
                                        verticalAlign: 'middle',
                                        display: 'inline-block'
                                    }}>
                                        <div style={{
                                        borderRadius: '15px',
                                        boxSizing: 'border-box',
                                        padding: '8px',
                                        backgroundColor: 'blue',
                                        width: '70px', 
                                        color: 'white',
                                        fontWeight: '600',
                                        fontFamily: 'Montserrat',
                                        fontSize: '10pt'
                                        }}>
                                            <label>id: {item.id}</label>
                                        </div>
                                    </div>
                                    <div style={{
                                        width: '12%',
                                        verticalAlign: 'middle',
                                        display: 'inline-block',
                                        fontFamily: 'Montserrat',
                                        textAlign: 'left'
                                    }}>
                                        <label style={{
                                            display: 'block',
                                            fontSize: '9pt',
                                            marginBottom: '3px'
                                            
                                        }}>Data Emissão:</label>
                                        <label>{item.data_emissao}</label>
                                    </div>

                                    <div style={{
                                        width: '12%',
                                        verticalAlign: 'middle',
                                        display: 'inline-block',
                                        fontFamily: 'Montserrat',
                                        textAlign: 'left'
                                    }}>
                                        <label style={{
                                            display: 'block',
                                            fontSize: '9pt',
                                            marginBottom: '3px'
                                            
                                        }}>Data Vencimento:</label>
                                        <label>{item.data_vencimento}</label>
                                    </div>

                                    <div style={{
                                        width: '12%',
                                        verticalAlign: 'middle',
                                        display: 'inline-block',
                                        fontFamily: 'Montserrat',
                                        textAlign: 'left'
                                    }}>
                                        <label style={{
                                            display: 'block',
                                            fontSize: '9pt',
                                            marginBottom: '3px'
                                            
                                        }}>Data Pagamento:</label>
                                        <label>{item.data_pagamento}</label>
                                    </div>

                                    <div style={{
                                        width: '30%',
                                        verticalAlign: 'middle',
                                        display: 'inline-block',
                                        fontFamily: 'Montserrat',
                                        textAlign: 'left'
                                    }}>
                                        <label style={{
                                            display: 'block',
                                            fontSize: '9pt',
                                            marginBottom: '3px'
                                            
                                        }}>Título:</label>
                                        <label>{item.titulo}</label>
                                    </div>

                                    <div style={{
                                        width: '15%',
                                        verticalAlign: 'middle',
                                        display: 'inline-block',
                                        fontFamily: 'Montserrat',
                                        textAlign: 'left'
                                    }}>
                                        <label style={{
                                            display: 'block',
                                            fontSize: '9pt',
                                            marginBottom: '3px'
                                            
                                        }}>Valor:</label>
                                        <label><strong>{item.valor_original.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</strong></label>
                                    </div>
                            </div>
                            </>
                        )
                    })
                }
                
            </div>
        </TelaADM>
        {
            Load ?
            <LoadDiv/>
            :
            ''
        }
        </>
    )
}