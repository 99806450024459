import React, { useEffect, useState } from "react"
import { Connection } from "../../../conn/connection";
import { Save } from "../../../component/save/save";
import { TelaADM } from "../../../component/TelaADM";
import { EntryInput } from "../../../component/input/input";
import { LoadDiv } from "../../../component/Load";
import './style.css'
import InputMask from 'react-input-mask';
import moment from 'moment';

export const VendasList = (props) => {
    const [DtInicial, setDtInicial] = useState('')
    const [DtFinal, setDtFinal] = useState('')
    const [ListVendas, setListVendas] = useState([])
    const [Load, setLoad] = useState(false)

    const [TotalAvista, setTotalAvista] = useState(0)
    const [TotalAPrazo, setTotalAPrazo] = useState(0)
    const [TotalCrtDebito, setTotalCrtDebito] = useState(0)
    const [TotalCrtCredito, setTotalCrtCredito] = useState(0)
    const [TotalPix, setTotalPix] = useState(0)
    const [TotalGeral, setTotalGeral] = useState(0)

    const save = new Save()
    const Conn = new Connection()

    const PesquisarPeriodo = async() => {
        setLoad(true)
        const token = save.getTokenAdmin()
        if (true){
            let resp = await Conn.Ecommerc('intranet/financeiro/vendas/pesquisar', {
                'token': token,
                'data_inicial': DtInicial,
                'data_final': DtFinal,
            })
            if (resp){
                if (resp.status == '0'){
                    setListVendas(resp.dados.vendas)
                    setTotalAvista(resp.dados.tl_avista)
                    setTotalAPrazo(resp.dados.tl_aprazo)
                    setTotalCrtCredito(resp.dados.ct_credito)
                    setTotalCrtDebito(resp.dados.ct_debito)
                    setTotalPix(resp.dados.tl_pix)
                    setTotalGeral(resp.dados.tl_pix + resp.dados.tl_aprazo + resp.dados.tl_avista + resp.dados.ct_credito + resp.dados.ct_debito)
                }
            }
        }
        setLoad(false)
    }
    return (
        <>
        <TelaADM title='VENDAS'>
            <div style={{
                padding: '10px',
                boxSizing: 'border-box',
                border: 'rgb(216, 216, 216) 1px solid',
                borderRadius: '7px',
                width: '100%',
                display: 'inline-block',
                marginTop: '10px',
                textAlign: 'center',
                
            }}>
                <div className="entry-div-default" style={{
                width: '15%',
                }}>
                    <label>Dt. Inicial Vencimento:</label>
                    <InputMask value={DtInicial} onChange={(event) => setDtInicial(event.target.value)} mask='99/99/9999' placeholder="Data" />
                </div>
                <div className="entry-div-default" style={{
                width: '15%',
                }}>
                    <label>Dt. Final Vencimento:</label>
                    <InputMask value={DtFinal} onChange={(event) => setDtFinal(event.target.value)} mask='99/99/9999' placeholder="Data" />
                </div>
                <button className="button-adm-default" onClick={PesquisarPeriodo}>
                    pesquisar
                </button>
            </div>
            <div style={{
                padding: '10px',
                boxSizing: 'border-box',
                
                borderRadius: '7px',
                width: '100%',
                display: 'inline-block',
                marginTop: '10px',
                textAlign: 'center',
                
            }}>
                <div style={{
                   width: '150px',
                    verticalAlign: 'middle',
                    display: 'inline-block',
                    fontFamily: 'Montserrat',
                    textAlign: 'left'
                }}>
                    <label style={{
                        display: 'block',
                        fontSize: '9pt',
                        marginBottom: '3px'
                        
                    }}>Total à vista:</label>
                    
                    <label style={{
                        fontWeight: '600'
                    }}>{TotalAvista.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</label>
                </div>
                <div style={{
                   width: '150px',
                    verticalAlign: 'middle',
                    display: 'inline-block',
                    fontFamily: 'Montserrat',
                    textAlign: 'left'
                }}>
                    <label style={{
                        display: 'block',
                        fontSize: '9pt',
                        marginBottom: '3px'
                        
                    }}>Total à prazo:</label>
                    
                    <label style={{
                        fontWeight: '600'
                    }}>{TotalAPrazo.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</label>
                </div>
                <div style={{
                   width: '150px',
                    verticalAlign: 'middle',
                    display: 'inline-block',
                    fontFamily: 'Montserrat',
                    textAlign: 'left'
                }}>
                    <label style={{
                        display: 'block',
                        fontSize: '9pt',
                        marginBottom: '3px'
                        
                    }}>Total Cartão Débito:</label>
                    
                    <label style={{
                        fontWeight: '600'
                    }}>{TotalCrtDebito.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</label>
                </div>
                <div style={{
                   width: '150px',
                    verticalAlign: 'middle',
                    display: 'inline-block',
                    fontFamily: 'Montserrat',
                    textAlign: 'left'
                }}>
                    <label style={{
                        display: 'block',
                        fontSize: '9pt',
                        marginBottom: '3px'
                        
                    }}>Total Cartão Débito:</label>
                    
                    <label style={{
                        fontWeight: '600'
                    }}>{TotalCrtDebito.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</label>
                </div>
                <div style={{
                   width: '150px',
                    verticalAlign: 'middle',
                    display: 'inline-block',
                    fontFamily: 'Montserrat',
                    textAlign: 'left'
                }}>
                    <label style={{
                        display: 'block',
                        fontSize: '9pt',
                        marginBottom: '3px'
                        
                    }}>Total PIX:</label>
                    
                    <label style={{
                        fontWeight: '600'
                    }}>{TotalPix.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</label>
                </div>
                <div style={{
                   width: '150px',
                    verticalAlign: 'middle',
                    display: 'inline-block',
                    fontFamily: 'Montserrat',
                    textAlign: 'left'
                }}>
                    <label style={{
                        display: 'block',
                        fontSize: '9pt',
                        marginBottom: '3px'
                        
                    }}>Total Geral:</label>
                    
                    <label style={{
                        fontWeight: '600'
                    }}>{TotalGeral.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</label>
                </div>
            </div>
            <div style={{
                marginTop: '10px'
            }}>
                {
                    ListVendas.map((item) => (
                        <div style={{
                            border: '1px solid rgb(216, 216, 216)',
                            borderRadius: '7px',
                            boxSizing: 'border-box',
                            padding: '8px',
                            textAlign: "center",
                            background: 'rgba(70, 247, 70, 0.603)',
                            marginBottom: '10px'
                        }} className="item-mov-adm" onClick={() => {window.location.href = `/intranet/admin/vendas/into/vnd?cod=${item.id}`}}>
                            <div style={{
                                width: '15%',
                                verticalAlign: 'middle',
                                display: 'inline-block',
                                fontFamily: 'Montserrat',
                                textAlign: 'left'
                            }}>
                                <label style={{
                                    display: 'block',
                                    fontSize: '9pt',
                                    marginBottom: '3px'
                                    
                                }}>Data:</label>
                                <label>{item.data}</label>
                            </div>
                            <div style={{
                                width: '15%',
                                verticalAlign: 'middle',
                                display: 'inline-block',
                                fontFamily: 'Montserrat',
                                textAlign: 'left'
                            }}>
                                <label style={{
                                    display: 'block',
                                    fontSize: '9pt',
                                    marginBottom: '3px'
                                    
                                }}>À Vistas:</label>
                                <label>{item.a_vista.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</label>
                            </div>
                            <div style={{
                                width: '15%',
                                verticalAlign: 'middle',
                                display: 'inline-block',
                                fontFamily: 'Montserrat',
                                textAlign: 'left'
                            }}>
                                <label style={{
                                    display: 'block',
                                    fontSize: '9pt',
                                    marginBottom: '3px'
                                    
                                }}>À Prazo:</label>
                                <label>{item.a_prazo.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</label>
                            </div>
                            <div style={{
                                width: '15%',
                                verticalAlign: 'middle',
                                display: 'inline-block',
                                fontFamily: 'Montserrat',
                                textAlign: 'left'
                            }}>
                                <label style={{
                                    display: 'block',
                                    fontSize: '9pt',
                                    marginBottom: '3px'
                                    
                                }}>Cartão Débito:</label>
                                <label>{item.cartao_debito.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</label>
                            </div>
                            <div style={{
                                width: '15%',
                                verticalAlign: 'middle',
                                display: 'inline-block',
                                fontFamily: 'Montserrat',
                                textAlign: 'left'
                            }}>
                                <label style={{
                                    display: 'block',
                                    fontSize: '9pt',
                                    marginBottom: '3px'
                                    
                                }}>Cartão Crédito:</label>
                                <label>{item.cartao_credito.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</label>
                            </div>
                            <div style={{
                                width: '15%',
                                verticalAlign: 'middle',
                                display: 'inline-block',
                                fontFamily: 'Montserrat',
                                textAlign: 'left'
                            }}>
                                <label style={{
                                    display: 'block',
                                    fontSize: '9pt',
                                    marginBottom: '3px'
                                    
                                }}>Pix:</label>
                                <label>{item.pix.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</label>
                            </div>
                            
                        </div>
                    ))
                }
            </div>
        </TelaADM>
        </>
    )
}  