import React, { useEffect, useState } from "react"
import { Connection } from "../../../conn/connection";
import { Save } from "../../../component/save/save";
import { TelaADM } from "../../../component/TelaADM";
import { EntryInput } from "../../../component/input/input";
import { LoadDiv } from "../../../component/Load";
import './style.css'
import InputMask from 'react-input-mask';
import moment from 'moment';

export const CartaoInicial = (props) => {
    const [DtInicial, setDtInicial] = useState('')
    const [DtFinal, setDtFinal] = useState('')
    const [ListCartao, setListCartao] = useState([])
    const [Load, setLoad] = useState(false)
    const [Emissao, setEmissao] = useState('E')
    const [Total, setTotal] = useState(0)
    const [Recebidos, setRecebidos] = useState(0)

    const save = new Save()
    const Conn = new Connection()

    const PesquisarPeriodo = async() => {
        setLoad(true)
        const token = save.getTokenAdmin()
        if (true){
            let json = {
                'token': token
            }
            if (Emissao == 'E'){
                json['data_emissao_inicial'] = DtInicial
                json['data_emissao_final'] = DtFinal
            } else {
                json['data_recebimento_inicial'] = DtInicial
                json['data_recebimento_final'] = DtFinal
            }
            let resp = await Conn.Ecommerc('intranet/financeiro/vendas/pesquisa_cartao', json)
            if (resp){
               
                if (resp.status == '0'){
                    setListCartao(resp.dados.lista)
                    setTotal(resp.dados.total)
                    setRecebidos(resp.dados.recebido)
                }
            }
        }
        setLoad(false)
    }
    const ChangeOperacoes = (event) => {
        let file = event.target.files[0]
        var reader = new FileReader();
        
        reader.readAsDataURL(file);
        reader.onload = async() => {
            let save = new Save()
            let token = save.getTokenAdmin()
            const conn = new Connection()
            if (token){
                setLoad(true)
                let resp = await conn.Ecommerc('intranet/financeiro/vendas/carregar_cartao', {
                    'token': token,
                    'dados': reader.result
                })
                if (resp){
                    if (resp.status == '0'){
                        window.location.reload()
                        
                    }
                }
                setLoad(false)
            }
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }
    const ChangePagos = (event) => {
        let file = event.target.files[0]
        var reader = new FileReader();
        
        reader.readAsDataURL(file);
        reader.onload = async() => {
            let save = new Save()
            let token = save.getTokenAdmin()
            const conn = new Connection()
            if (token){
                setLoad(true)
                let resp = await conn.Ecommerc('intranet/financeiro/vendas/carregar_cartao_pagos', {
                    'token': token,
                    'dados': reader.result
                })
                if (resp){
                    if (resp.status == '0'){
                        window.location.reload()
                        
                    }
                }
                setLoad(false)
            }
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    return (
        <>
        <TelaADM title='CARTÃO'>
            <div style={{
                width: '100%',
                textAlign: 'center'
            }}>
                <div style={{
                    margin: '10px',
                    display: 'inline-block'
                }}>
                <label 
                style={{
                    padding: '10px',
                    backgroundColor: 'gray',
                    borderRadius: '7px',
                    color: 'white',
                    cursor: 'pointer',
                    fontFamily: 'Montserrat'
                }}
                for='arquivo-cartao'>Enviar Arquivo de Operações</label>
                <input name='arquivo-cartao' id="arquivo-cartao" style={{
                    display: 'none'
                }} type="file" accept=".csv" onChange={ChangeOperacoes}/>
                </div>
                <div style={{
                    margin: '10px',
                    display: 'inline-block'
                }}>
                <label 
                style={{
                    padding: '10px',
                    backgroundColor: 'gray',
                    borderRadius: '7px',
                    color: 'white',
                    cursor: 'pointer',
                    fontFamily: 'Montserrat'
                    
                }}
                for='arquivo-cartao-pago'>Enviar Arquivo de Pagamentos</label>
                <input name='arquivo-cartao-pago' id="arquivo-cartao-pago" style={{
                    display: 'none'
                }} type="file"  accept=".csv" onChange={ChangePagos}/>
                </div>
            </div>
            <div style={{
                padding: '10px',
                boxSizing: 'border-box',
                border: 'rgb(216, 216, 216) 1px solid',
                borderRadius: '7px',
                width: '100%',
                display: 'inline-block',
                marginTop: '10px',
                textAlign: 'center',
                
            }}>
                <div className="entry-div-default" style={{
                width: '15%',
                }}>
                    <label>Dt. Inicial Vencimento:</label>
                    <select value={Emissao} onChange={(event) => setEmissao(event.target.value)}>
                        <option value='E'>Por Emissão</option>
                        <option value='9'>Por Pagamento</option>
                    </select>
                </div>
                <div className="entry-div-default" style={{
                width: '15%',
                }}>
                    <label>Dt. Inicial Vencimento:</label>
                    <InputMask value={DtInicial} onChange={(event) => setDtInicial(event.target.value)} mask='99/99/9999' placeholder="Data" />
                </div>
                <div className="entry-div-default" style={{
                width: '15%',
                }}>
                    <label>Dt. Final Vencimento:</label>
                    <InputMask value={DtFinal} onChange={(event) => setDtFinal(event.target.value)} mask='99/99/9999' placeholder="Data" />
                </div>
                <button className="button-adm-default" onClick={PesquisarPeriodo}>
                    pesquisar
                </button>
            </div>
            <div style={{
                width: '100%',
                textAlign: 'center',
                marginTop: '10px'
            }}>
                <div style={{
                   width: '150px',
                    verticalAlign: 'middle',
                    display: 'inline-block',
                    fontFamily: 'Montserrat',
                    textAlign: 'left'
                }}>
                    <label style={{
                        display: 'block',
                        fontSize: '9pt',
                        marginBottom: '3px'
                        
                    }}>Total:</label>
                    
                    <label style={{
                        fontWeight: '600'
                    }}>{Total.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</label>
                </div>
                <div style={{
                   width: '150px',
                    verticalAlign: 'middle',
                    display: 'inline-block',
                    fontFamily: 'Montserrat',
                    textAlign: 'left'
                }}>
                    <label style={{
                        display: 'block',
                        fontSize: '9pt',
                        marginBottom: '3px'
                        
                    }}>Recebido:</label>
                    
                    <label style={{
                        fontWeight: '600'
                    }}>{Recebidos.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</label>
                </div>
            </div>
            <div style={{
                padding: '10px',
                boxSizing: 'border-box',
                padding: '10px',
                border: 'rgb(216, 216, 216) 1px solid',
                borderRadius: '7px',
                width: '100%',
                display: 'inline-block',
                marginTop: '10px',
                textAlign: 'center',
                height: '320px',
                maxHeight: '320px',
                overflowY: 'scroll'
            }}>
                {
                    ListCartao.map((item) => (
                        <div style={{
                            border: '1px solid rgb(216, 216, 216)',
                            borderRadius: '7px',
                            boxSizing: 'border-box',
                            padding: '8px',
                            textAlign: "center",
                            background: item.recebido? 'rgba(185, 185, 185, 0.603)' : 'rgba(70, 247, 70, 0.603)',
                            marginBottom: '10px'
                        }} className="item-mov-adm">
                            <div style={{
                                width: '15%',
                                verticalAlign: 'middle',
                                display: 'inline-block',
                                fontFamily: 'Montserrat',
                                textAlign: 'left'
                            }}>
                                <label style={{
                                    display: 'block',
                                    fontSize: '9pt',
                                    marginBottom: '3px'
                                    
                                }}>Data Emissão:</label>
                                <label>{item.data_emissao}</label>
                            </div>
                            <div style={{
                                width: '15%',
                                verticalAlign: 'middle',
                                display: 'inline-block',
                                fontFamily: 'Montserrat',
                                textAlign: 'left'
                            }}>
                                <label style={{
                                    display: 'block',
                                    fontSize: '9pt',
                                    marginBottom: '3px'
                                    
                                }}>Data Pagamento:</label>
                                <label>{item.data_recebimento}</label>
                            </div>
                            <div style={{
                                width: '20%',
                                verticalAlign: 'middle',
                                display: 'inline-block',
                                fontFamily: 'Montserrat',
                                textAlign: 'left'
                            }}>
                                <label style={{
                                    display: 'block',
                                    fontSize: '9pt',
                                    marginBottom: '3px'
                                    
                                }}>NSU:</label>
                                <label>{item.nsu}</label>
                            </div>
                            <div style={{
                                width: '15%',
                                verticalAlign: 'middle',
                                display: 'inline-block',
                                fontFamily: 'Montserrat',
                                textAlign: 'left'
                            }}>
                                <label style={{
                                    display: 'block',
                                    fontSize: '9pt',
                                    marginBottom: '3px'
                                    
                                }}>Valor da Operação:</label>
                                <label>{item.valor_operacao.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</label>
                            </div>
                            <div style={{
                                width: '15%',
                                verticalAlign: 'middle',
                                display: 'inline-block',
                                fontFamily: 'Montserrat',
                                textAlign: 'left'
                            }}>
                                <label style={{
                                    display: 'block',
                                    fontSize: '9pt',
                                    marginBottom: '3px'
                                    
                                }}>Taxa:</label>
                                <label>{item.valor_taxa.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</label>
                            </div>
                            <div style={{
                                width: '15%',
                                verticalAlign: 'middle',
                                display: 'inline-block',
                                fontFamily: 'Montserrat',
                                textAlign: 'left'
                            }}>
                                <label style={{
                                    display: 'block',
                                    fontSize: '9pt',
                                    marginBottom: '3px'
                                    
                                }}>Valor Recebido:</label>
                                <label>{item.valor_recebido.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</label>
                            </div>
                            
                        </div>
                    ))
                }
            </div>
        </TelaADM>
        {
            Load ?
            <LoadDiv/>
            :
            ''
        }
        </>
    )
}