import axios from "axios"

export class Connection {
    constructor() {
        this.url = "http://uniao.fimerc.com" //dominio principal da api

        /*APIs referenciadas*/
        this.locais = {
            "ecommerc":"ecommerc/api"
        }
    }

    URLImagemProduto(codigo) {

        return `${this.url}/${this.locais['ecommerc']}/produto/pesquisa/imagem/${codigo}`
    }

    URLImagemProdutoTemp(codigo) {

        return `${this.url}/${this.locais['ecommerc']}/intranet/produtos/img/${codigo}`
    }

    async conn(local, api, dados){
        /*Funcao que faz a comunicacao com a API*/

        let resposta = await axios.post(`${this.url}/${this.locais[local]}/${api}`, dados, {headers:{
            'Content-Type': 'application/json'
        }})
        
        console.log(resposta)

        if (resposta.status != 200){
            resposta = false
        } else if (resposta.data.status == '1'){
            alert(`!!ERRO-DEV: ${resposta.data.mot}`)
        }

        return resposta.data
    }

    async Ecommerc(api, dados){
        /*FUncao que envia o pedido para intrenet*/

        var retorno = await this.conn(
            'ecommerc', 
            api,
            dados
        )

        return retorno
    }
}