import React, { useEffect, useState }from "react";
import './style.css'
import axios from "axios";
import { Link, useNavigate  } from "react-router-dom";
import { EntryInput } from "../../component/input/input";
import { Connection } from "../../conn/connection";
import { Save } from "../../component/save/save";
import LogoMercadoUnicao from '../../imgs/logo.png'
import LoadGif from '../../imgs/load_gif.gif'



export const CadastroAlterar = () => {
    const [ Nome, setNome ] = useState('')
    const [ Sobrenome, setSobrenome ] = useState('')
    const [ CPF, setCPF ] = useState('')
    const [ Telefone, setTelefone ] = useState('')
    const [ Rua, setRua ] = useState('')
    const [ Numero, setNumero ] = useState('')
    const [ Bairro, setBairro ] = useState('')
    const [ CEP, setCEP ] = useState('')
    const [ Cidade, setCidade ] = useState('')
    const [ Estado, setEstado ] = useState('')
    const [ Email, setEmail ] = useState('')


    const [ Mensagem, setMensagem ] = useState('O nome está vazio!')
    const [ ShowMensagem, setShowMensagem] = useState(false)
    const [ Alterado, setAlterado] = useState(false)

    const [ Load, setLoad ] = useState(false)

    const Navegador = useNavigate()

    const estados = [
        [ 'AC', 'Acre' ],
        [ 'AL', 'Alagoas' ],
        [ 'AP', 'Amapá' ],
        [ 'AM', 'Amazonas' ],
        [ 'BA', 'Bahia' ],
        [ 'CE', 'Ceará' ],
        [ 'DF', 'Distrito Federal' ],
        [ 'ES', 'Espírito Santo' ],
        [ 'GO', 'Goías' ],
        [ 'MA', 'Maranhão' ],
        [ 'MT', 'Mato Grosso' ],
        [ 'MS', 'Mato Grosso do Sul' ],
        [ 'MG', 'Minas Gerais' ],
        [ 'PA', 'Pará' ],
        [ 'PB', 'Paraíba' ],
        [ 'PR', 'Paraná' ],
        [ 'PE', 'Pernambuco' ],
        [ 'PI', 'Piauí' ],
        [ 'RJ', 'Rio de Janeiro' ],
        [ 'RN', 'Rio Grande do Norte' ],
        [ 'RS', 'Rio Grande do Sul' ],
        [ 'RO', 'Rondônia' ],
        [ 'RR', 'Roraíma' ],
        [ 'SC', 'Santa Catarina' ],
        [ 'SP', 'São Paulo' ],
        [ 'SE', 'Sergipe' ],
        [ 'TO', 'Tocantins' ],
      ]

    const PesquisaCEP = async () => {
        let cep = CEP
        cep = cep.replace('.', '')
        cep = cep.replace('-', '')
        console.log(cep)
        try {
            let resposta = await axios.get(`https://brasilapi.com.br/api/cep/v1/${CEP}`)
            console.log(resposta.status)
        

            if (resposta.status == 200){
                setEstado(resposta.data.state)
                setCidade(resposta.data.city)
            } 
        }
        finally {

        }
        
    }

    const save = new Save()
    const CadastrarButton = async() => {
        if (!Load){
            setLoad(true)
            let dict = [
                ["Nome", Nome],
                ["Sobrenome", Sobrenome],
                ["CPF", CPF],
                ["Telefone", Telefone],
                ["Rua", Rua],
                ["Numero", Numero],
                ["Bairro", Bairro],
                ["CEP", CEP],
                ["Cidade", Cidade],
                ["Estado", Estado],
               
                
            ]
            var i
            var prosseguir = true
            for (i=0; i< dict.length; i++){
                let item = dict[i]
                
                if (item[1] == '' | item[1] == undefined){
                    setMensagem(`O campo ${item[0]} não pode ser vazio!`)
                    setShowMensagem(true)
                    prosseguir = false
                }
            }
            if (prosseguir){
                if (true){
                    let conn = new Connection()
    
           
                    let resp = await conn.Ecommerc( 
                        'usuario/alterar', 
                        {
                            'nome': Nome,
                            'sobrenome': Sobrenome,
                            'cpf': CPF,
                            'token': save.getToken(),
                            'rua': Rua,
                            'numero': Numero,
                            'bairro': Bairro,
                            'cep': CEP,
                            'cidade': Cidade,
                            'estado': Estado,
                          
                            'telefone': Telefone
                        }
                        )
                    
                    if (resp){
    
                    
                        if (resp.status == 0){
                            
                            setAlterado(true)
                          
                            
                        }
                       
                        else if (resp.status == 3){
                            
                            setMensagem(`CPF inválido!`)
                            setShowMensagem(true)
                            
                        }
                    }
                }
               
            }
            setLoad(false)
        }
        
    }

    const onLoad = async() => {
        let save = new Save()
        let token = save.getToken()
        
        if (token){
            let conn = new Connection()
    
           
            let resp = await conn.Ecommerc( 
                'usuario/dados', 
                {
                    'token': token
                }
                )
            if (resp){
                if (resp.status == '0'){
                    setNome(resp.usuario.nome)
                    setSobrenome(resp.usuario.sobrenome)
                    setCPF(resp.usuario.cpf)
                    setTelefone(resp.usuario.telefone)
                    setRua(resp.usuario.rua)
                    setNumero(resp.usuario.numero)
                    setBairro(resp.usuario.bairro)
                    setCEP(resp.usuario.cep)
                    setCidade(resp.usuario.cidade)
                    setEstado(resp.usuario.estado)
                }
            }
        }else {
            Navegador('/login')
        }
        
    }

    useEffect(() => {onLoad()}, [])

    return (
        <>
        <div id='div-barra-login'>
            <img src={LogoMercadoUnicao} />
        </div>
        <div id='div-cont-login-cadastro'>
        <div id='div-login-cadastro'>
           
            <div id='div-texto-login'>
                <h1 style={{
                    fontSize: '18pt'
                }}>Quer alterar seu cadastro?</h1>
                <p style={{
                    fontSize: '11pt'
                }}>Informe seus novos dados</p>
                <p></p>
            </div>
            {
                Alterado?
                <div id='div-dados-cadastro'>
                    <p>Alterado com sucesso!</p>
                </div>
                :
                ''
            }

            <EntryInput type="text" id="nome" valor={Nome} setValor={setNome} placeholder='Nome' className='input_cadastro'/>
            <EntryInput type="text" id="sobrenome" valor={Sobrenome} setValor={setSobrenome} placeholder='Sobrenome' className='input_cadastro'/>
           
            <EntryInput type="mask" mask='999.999.999-99' id="cpf" valor={CPF} setValor={setCPF} placeholder='CPF' className='input_cadastro'/>
            <EntryInput type="mask" mask='(99)999999999'id="telefone" valor={Telefone} setValor={setTelefone} placeholder='Telefone' className='input_cadastro'/>
            
            <hr style={{
                marginTop: '30px',
                color: 'black'
            }}/>
            <div id='div-texto-login'>
                
                <p style={{
                    fontSize: '11pt'
                }}>Informe seu endereço</p>
                <p></p>
            </div>
            <EntryInput type="text" id="rua" valor={Rua} setValor={setRua} placeholder='Rua' className='input_cadastro'/>
            <EntryInput type="text" id="numero" valor={Numero} setValor={setNumero} placeholder='Número' className='input_cadastro'/>
            <EntryInput type="text" id="bairro" valor={Bairro} setValor={setBairro} placeholder='Bairro' className='input_cadastro'/>
            <EntryInput type="mask" mask='99.999-999' id="cep" valor={CEP} setValor={setCEP}  placeholder='CEP' className='input_cadastro' onBlur={PesquisaCEP}/>
            <EntryInput type="text" id="cidade" valor={Cidade} setValor={setCidade}  placeholder='Cidade' className='input_cadastro'/>
            <select className={"input-padrao-entry input_cadastro"} value={Estado} onChange={(event) => {setEstado(event.target.value)}}>
                {
                    estados.map((item) => (
                        <option value={item[0]}>{item[1]}</option>
                    ))
                }
            </select>
            
            <hr style={{
                marginTop: '30px',
                color: 'black'
            }}/>
            
               {
                ShowMensagem ? 
                <div id='div-senha-incorreta' style={{
                    marginBottom: '0px'
                }}>
                    <p style={{
                    marginBottom: '0px'
                }}>{Mensagem}</p>
                </div>
                : 
                ''
            }
            <button className='button-padrao' onClick={CadastrarButton}>
                {
                    Load ?
                        <img src={LoadGif}
                        style={{
                            width: '15px'
                        }}/>
                    :
                    'Salvar'
                }
            </button>
            
        </div>
        </div>
        </>
    )
}
