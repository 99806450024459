import React, { useEffect, useState }from "react";
import './style.css'
import Coca from '../../imgs/coca2.webp'
import SemFoto from '../../imgs/sem-foto.png'
import { useNavigate } from "react-router-dom";
import { Connection } from "../../conn/connection";

export const Produto = (props) => {
    const [ Adicionar, setAdicionar ] = useState(true)
    const [Input, setInput] = useState()
    const [ Time, setTime ] = useState()
    

    const RemoverZero = (newValue) => {
        var valor = parseFloat(newValue >= 0 ? newValue : 0)
        valor = parseInt(String(valor)) + (valor - Math.floor(valor))
        console.log(valor)
        return valor
    }

    const addCarrinhoEfetivo = (value) => {

        if (props.addCarrinho){
            props.addCarrinho({
                id: props.codigo,
                qtd: value
            })
        }
    }

    const addCarrinho = (value) => {
        try{
            clearTimeout(Time[0])
        }
        catch {

        }
        
        let a = setTimeout(() => {addCarrinhoEfetivo(value)}, 1000)
     
        setTime([...[a]])
    }

    const MudarValor = (newValue) => {
        var valor = RemoverZero(newValue)
       
        props.set(valor)
        if (props.onChange){
            props.onChange({
                id: props.id,
                qtd: newValue
            })
        }
    }

    const conn = new Connection()

    const MakeDefaultQtd = () => {
        if (props.Carrinho){
            props.Carrinho.map((item) => {
                if (item.id == props.codigo){
                    MudarValor(item.qtd)
                    
                }
            })
        }

        if (props.qtd > 0){
            setAdicionar(false)
        }
    }

    useEffect(() => {MakeDefaultQtd()}, [])
    return (
        <>
        <div className="div-produto-object" style={{
            width: props.width,
            
        }}>
           
            <div className="div-img-produto">
                <img className='img-produto' src={conn.URLImagemProduto(props.codigo? props.codigo : 0)}/>
            </div>
            <div className="div-descricao">
                <p>{props.descricao}</p>
            </div>
            <div className="div-preco-produto">
                <p>{parseFloat(props.preco_un).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
            </div>
            {
                Adicionar?
                <div className="div-adicionar-button">
                    <button onClick={() => {
                        setAdicionar(false)
                        props.set(1)
                        addCarrinho(1)
                    }}>Adicionar</button>
                </div>
                :
                <div style={{
                    width: '100%',
                    textAlign: 'center',
                    alignItems: 'center',
                    height: '50px'
                }}>
                    <div className="div-input-produto">
                        <p className="p-input-produto" 
                        onClick={
                            () => {
                                MudarValor(props.qtd - 1)
                                if (props.qtd - 1 == 0){
                                    setAdicionar(true)
                                }
                                addCarrinho(props.qtd - 1)
                            }
                        }
                        >-</p>
                        <input style={{display: 'inline-block'}} className="input-produto" type="number" value={props.qtd} onChange={(event) => MudarValor(event.target.value)}
                        onFocus={() => {
                            Input.select()
                        }}
                        onBlur={() => {
                            props.set(RemoverZero(props.qtd))
                            if (props.qtd == 0){
                                setAdicionar(true)
                            }
                            addCarrinho(props.qtd)
                        }}
                        ref={setInput}/>
                        <p className="p-input-produto"
                        onClick={
                            () => {
                                MudarValor(props.qtd + 1)
                                addCarrinho(props.qtd + 1)
                            }
                        }>+</p>
                    </div>
                    <p className="total-item">Item: {(props.preco_un * (props.qtd ? props.qtd : 0)).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
                </div>
            }
            
        </div>
        </>
    )
}

export const ProdutoPromocao = (props) => {
    const navigate = useNavigate()
    const conn = new Connection()
    return (
        <>
        <div className="div-produto-object " style={{
            width: props.width,
            background: '',
            padding: '10px',
            margin: '20px',
            cursor: 'pointer'
        }}
        onClick={(event) => {
            event.stopPropagation()
            navigate(props.link)
        }}
        >
            <div className="div-desconto-produto">
                    <p>{parseInt((1-(props.preco_atual/props.preco_ant))*100)}% off</p>
                </div>
            <div>
                <img className='img-produto' style={
                    {
                        backgroundColor: 'white',
                        marginTop: '-30px'
                    }
                }src={conn.URLImagemProduto(props.codigo? props.codigo : 0)}/>
            </div>

            <div className="div-preco-ante">
                <p className="p1-promocao-produto">de </p>
                <p className="p-promocao-produto">{parseFloat(props.preco_ant).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</p>
                
            </div>
            
            <div className="div-preco-atual">
                <p className="p1-preco-atual">R$</p>
                <p className="p-preco-atual">{parseInt(props.preco_atual)}<sup>,{parseInt((parseFloat(props.preco_atual.toFixed(2))-parseInt(props.preco_atual))*100)}</sup></p>
            </div>
        </div>
        </>
    )
}