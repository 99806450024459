import React from "react";
import './style.css'
import InputMask from 'react-input-mask';


//cria a funcao EntryInput e exporta ela
export const EntryInput = (props /*recebe como parametro os valor passados como propriedade dentro da tag <EntryInput/>*/) => {
    
    //cria a funcao MudarValor
    function MudarValor(event){

        //obtem o que esta no input + a ultima tecla digitada
        let retorno_do_input = event.target.value

        //pega setValor, que foi passado como propriedade da tag <EntryInput/> quando utilizada
            //esse propriedade deve, quando usar a tag <EntryInput/> receber o retorno da funcao useState que modifica a
            //variavel dinamina criada junto com ela
        props.setValor(retorno_do_input) //chama essa funcao passada como propriedade e passa como parametro o novo valor do input
    }

    return (
        <div>
            {/*cria um input html padrao*/}
            {
                props.type == 'mask'
                ?
                <InputMask 
                id={props.id} /*passa para a propriedade id o que foi passado como id quando a tag <EntryInput/> foi usada*/
                value={props.valor} /*passa para a propriedade value o que foi passado como value quando a tag <EntryInput/> foi usada*/
                onChange={MudarValor} /*chama a funcao MudarValor que fara a mudanca da variavel dinamica passada como value quando a tag <EntryInput/> foi usada*/
                className={"input-padrao-entry " + (props.className != null ? props.className : '')}
                placeholder={props.placeholder}
                mask={props.mask}
                maskChar=" " 
                onBlur={props.onBlur ? props.onBlur : () => {}}
                />
                :
                <input 
                type={props.type} /*passa para a propriedade type o que foi passado como type quando a tag <EntryInput/> foi usada*/
                id={props.id} /*passa para a propriedade id o que foi passado como id quando a tag <EntryInput/> foi usada*/
                value={props.valor} /*passa para a propriedade value o que foi passado como value quando a tag <EntryInput/> foi usada*/
                onChange={MudarValor} /*chama a funcao MudarValor que fara a mudanca da variavel dinamica passada como value quando a tag <EntryInput/> foi usada*/
                className={"input-padrao-entry " + (props.className != null ? props.className : '')}
                placeholder={props.placeholder}
                onBlur={props.onBlur ? props.onBlur : () => {}}
                
            />
            }
            
        </div>
    )
}