import React, { useEffect, useState } from "react";
import { Save } from "../../../component/save/save";
import { Connection } from "../../../conn/connection";
import './style.css'

export const PedidosAdm = (props) => {
    const [ Pedidos, setPedidos ] = useState([
        {
            dados: {
                "id": '1',
                "user": 'Willys Moreira Cunha',
                "finalizado": true,
                "cancelado": false,
                "valor_pedido": 15,
                "valor_produtos": 15,
                "desconto": 0,
                "valor_final": 15,
                "nfce": false,
                "status": 'em processamento',
                "frete": 0,
                "cupom": {
                    'chave': '',
                    'desconto': '',
                    'porcentagem': ''
                },
            },
            produtos: [
                {
                    "codigo": '1',
                    "codigo_barras": '1',
                    "descricao": 'Produto Teste',
                    "sub_descricao": 'Produto Teste',
                    "preco": 15,
                    "setor": '1',
                    "categoria": '1',
                    "subcategoria": '1',

                    "qtd_pedida": 1,
                    "qtd_real": 1,
                    "preco_final": 15
                }
            ]
        }
    ])

    const [ ShowPopUp, setShowPopUp ] = useState(false)
    const [ PedidoFoco, setPedidoFoco ] = useState(null)

    const ClickPedido = (pedido) => {
        setPedidoFoco(pedido)
        setShowPopUp(true)
    }

    const LoadInicial = async () => {
        let save = new Save()
        let token = save.getTokenAdmin()
        const conn = new Connection()
        if (token){
            let resp = await conn.Ecommerc('pedido/pesquisar', {
                'token': token,
                'tipo': 'todos'
            })
            if (resp){
                if (resp.status == '0'){
                    setPedidos(resp.pedidos)
                    
                }
            }
        }
    }

    useEffect(() => {LoadInicial()}, [])
    return (
        <>
        <div style={{width: '100%'}}>
            <div style={{
                width: '100%',
                boxSizing: 'border-box',
                padding: '10px',
                fontFamily: '"Montserrat"',
                fontWeight: '600',
                
            }}>
                <p>PEDIDOS</p>
            </div>
            <div style={{
                width: '100%',
                padding: '10px',
                boxSizing: 'border-box',
            }}>
                <div style={{
                    padding: '10px',
                    boxSizing: 'border-box',
                    border: 'rgb(216, 216, 216) 1px solid',
                    borderRadius: '7px'
                }}>
                    <ShowPedidos list={Pedidos} ClickPedido={ClickPedido}/>
                </div>
            </div>
        </div>
        {
            ShowPopUp ? 
            <PopUp pedido={PedidoFoco} setShow={setShowPopUp} load={LoadInicial}/>
            :
            ''
        }
        
        </>
    )
}

const PopUp = (props) => {
    const [ Mega, setMega ] = useState(props.pedido.dados.mega)
    const MakeStatus = async(evet) => {
        if (evet.target.value != props.pedido.dados.status){
            let save = new Save()
            let token = save.getTokenAdmin()
            const conn = new Connection()
            if (token){
                let resp = await conn.Ecommerc('intranet/alterar_pedido', {
                    'token': token,
                    'alterar': 'status',
                    'new': evet.target.value,
                    'id': String(props.pedido.dados.id)
                })
                if (resp){
                    if (resp.status == '0'){
                        props.load()
                        alert('Feito!')
                        window.location.reload()
                    }
                }
            }
        }
    }

    const MakeMega = async(evet) => {
        
        if (Mega != props.pedido.dados.mega){
            
            let save = new Save()
            let token = save.getTokenAdmin()
            const conn = new Connection()
            
            if (token){
                let resp = await conn.Ecommerc('intranet/alterar_pedido', {
                    'token': token,
                    'alterar': 'mega',
                    'new': Mega,
                    'id': String(props.pedido.dados.id)
                })
                if (resp){
                    if (resp.status == '0'){
                        alert('Feito')
                        props.load()
                        window.location.reload()
                    }
                }
            }
        }
    }
    return (
        <div className="popup">
            <div className="popup-inner">
                <div style={{
                    width: '1000px'
                }}>
                    <div style={{
                        display: 'inline-block',
                        width: '80%'
                    }}>
                        <h2>Pedido #{props.pedido.dados.id}</h2>
                    </div>
                    <div style={{
                        display: 'inline-block',
                        width: '18%',
                        textAlign: 'right',
                        cursor: 'pointer'
                    }} onClick={() => props.setShow(false)}>
                        <p>X</p>
                    </div>
                </div>
                <div>
                     <InputShow valor={props.pedido.dados.user} width='300px' titulo='Cliente:'/>   
                     <br/>
                     <InputShow valor={props.pedido.dados.valor_pedido.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} width='100px' titulo='Vl. Pedido:'/>   
                     <InputShow valor={props.pedido.dados.valor_produtos.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} width='100px' titulo='Vl. Produtos:'/>   
                     <InputShow valor={props.pedido.dados.desconto.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} width='100px' titulo='Desconto:'/>   
                     <InputShow valor={props.pedido.dados.valor_final.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} width='100px' titulo='Vl. Final:'/>   
                     <br/>
                     <div style={{
                            fontFamily: 'Montserrat',
                            display: 'inline-block',
                            margin: '5px'
                        }}>
                            <p style={{
                                fontSize: '10pt',
                                margin: '0px',
                                marginBottom: '5px'
                            }}>Status</p>
                            <select style={{
                                width: '300px',
                                boxSizing: 'border-box',
                                padding: '3px',
                                borderRadius: '5px',
                                fontSize: '15pt',
                                fontFamily: 'Montserrat',
                                border: '0.5px rgb(180, 180, 180) solid'
                                
                            }} value={props.pedido.dados.status} onChange={MakeStatus}>
                                <option value="Em Processamento">Em Processamento</option>
                                <option value="Aguardando">Aguardando</option>
                                <option value="Concluído, esperando entrega...">Concluído, esperando entrega...</option>
                                <option value="Concluído, esperando retirada...">Concluído, esperando retirada...</option>
                                <option value="Entregue">Entregue</option>
                            </select>
                            
                        </div>
                        <div style={{
                                fontFamily: 'Montserrat',
                                display: 'inline-block',
                                margin: '5px'
                            }}>
                                <p style={{
                                    fontSize: '10pt',
                                    margin: '0px',
                                    marginBottom: '5px'
                                }}>Mega Cupom:</p>
                                <input  style={{
                                    width: '150px',
                                    boxSizing: 'border-box',
                                    padding: '3px',
                                    borderRadius: '5px',
                                    fontSize: '15pt',
                                    fontFamily: 'Montserrat',
                                    border: '0.5px rgb(180, 180, 180) solid'
                                }} onBlur={MakeMega}
                                value={Mega} onChange={(event) => setMega(event.target.value)}/>
                            </div>
                </div>
                <div style={{
                    padding: '10px',
                    boxSizing: 'border-box',
                    border: 'rgb(216, 216, 216) 1px solid',
                    borderRadius: '7px',
                    height: '200px',
                    maxHeight: '200px',
                    overflowY: 'auto'
                }}>
                    <table style={{width: '100%', fontFamily: 'Montserrat', textAlign: 'left'}} border="0" cellspacing="0" cellpadding="5">
                        <tr>
                            <th style={{width: '10%'}}>Código</th>
                            <th style={{width: '40%'}}>Descrição</th>
                            <th style={{width: '15%'}}>Pr. Unidade</th>
                            <th style={{width: '15%'}}>Quantidade</th>
                            <th style={{width: '15%'}}>Pr. Total</th>
                        </tr>
                        {
                            props.pedido.produtos.map((produto) => (
                                <tr>
                                    <td style={{widtd: '10%'}}>{produto.codigo}</td>
                                    <td style={{widtd: '40%'}}>{produto.descricao}</td>
                                    <td style={{widtd: '15%'}}>{produto.preco.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</td>
                                    <td style={{widtd: '15%'}}>{produto.qtd_real}</td>
                                    <td style={{widtd: '15%'}}>{produto.preco_final.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</td>
                                </tr>
                            ))
                        }
                    </table>
                </div>
            </div>
        </div>
    )
}

const InputShow = (props) => {
    return (
        <div style={{
            fontFamily: 'Montserrat',
            display: 'inline-block',
            margin: '5px'
        }}>
            <p style={{
                fontSize: '10pt',
                margin: '0px',
                marginBottom: '5px'
            }}>{props.titulo}</p>
            <input value={props.valor} disabled={true} style={{
                width: props.width,
                boxSizing: 'border-box',
                padding: '3px',
                borderRadius: '5px',
                fontSize: '15pt',
                fontFamily: 'Montserrat',
                border: '0.5px rgb(180, 180, 180) solid'
            }}/>
        </div>
    )
}

const ShowPedidos = (props) => {
    const styleShow = {
        'id': {
            'width': '10%'
        },
        'cliente': {
            'width': '30%'
        },
        'data': {
            'width': '15%'
        },
        'valor': {
            'width': '10%'
        },
        'status': {
            'width': '15%'
        },
        'entregar': {
            'width': '10%'
        },
        'mega': {
            'width': '10%'
        },
    }
    return (
        <>
            <table style={{width: '100%', fontFamily: 'Montserrat', textAlign: 'left'}} border="0" cellspacing="0" cellpadding="5">
                <tr>
                    <th style={styleShow['id']}>ID</th>
                    <th style={styleShow['cliente']}>Cliente</th>
                    <th style={styleShow['data']}>Data e Hora</th>
                    <th style={styleShow['valor']}>Valor</th>
                    <th style={styleShow['status']}>Status</th>
                    <th style={styleShow['entregar']}>Entregar</th>
                    <th style={styleShow['mega']}>Mega</th>
                </tr>
                <tr>
                    <th colSpan='7'>
                    <hr/>
                    </th>
                </tr>
                {
                    props.list.map((item) => (
                        <tr className="item-pedido-admin" onClick={() => props.ClickPedido(item)}>
                            <td style={styleShow['id']}>{item.dados.id}</td>
                            <td style={styleShow['cliente']}>{item.dados.user}</td>
                            <td style={styleShow['data']}>{item.dados.hora}</td>
                            <td style={styleShow['valor']}>{item.dados.valor_produtos.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</td>
                            <td style={styleShow['status']}>{item.dados.status}</td>
                            <td style={styleShow['entregar']}>{item.dados.frete > 0 ? 'Sim' : 'Não'}</td>
                            <td style={styleShow['mega']}>{item.dados.mega}</td>
                        </tr>
                    ))
                }
            </table>
        </>
    )
}